export const loadPointsEarned = (activeProgram, incentiveRules, incentiveButtonLinks, memberIncentiveScores) => {
  const incentiveProgramOrgId = activeProgram.incentiveProgramOrganizationId;
  const incentiveProgramId = activeProgram.incentiveProgramId;
  const IN_TobAfft = 'IN_TobAfft';
  const IN_Tobacco = 'IN_Tobacco';
  const IN_Cotinin = 'IN_Cotinin';
  const IN_NoSpous = 'IN_NoSpous';
  const buttonLinkLookUpCodes = ['IT_BioMYoY', 'IT_HRA', 'IT_Biometr'];
  const viewDetailsLookUpCodes = ['IT_Chaleng', 'IT_PerChal', 'IT_Commit', 'IT_ECommit', 'IT_OnlineW'];

  const incentiveRuleByProgramId = incentiveRules.byProgramId[incentiveProgramId];

  let incentiveRuleDetails = incentiveRuleByProgramId.reduce((incentiveRuleDetails, rule) => {
    incentiveRuleDetails[rule.incentiveRuleId] = rule;
    return incentiveRuleDetails;
  }, {});

  const buttonLinksMap = incentiveButtonLinks.byIpoid[incentiveProgramOrgId].reduce((buttonLinksMap, link) => {
    buttonLinksMap[link.incentiveRuleId] = {
      url: link.url,
      buttonName: link.buttonName
    };
    return buttonLinksMap;
  }, {});

  let scoreDetails = [];
  memberIncentiveScores.byIpoid[incentiveProgramOrgId].forEach((score) => {
    var outcome;
    var hideIncentiveRule = false;
    switch (score.incentiveCode) {
      case IN_TobAfft:
        outcome = '';
        break;
      case IN_Tobacco:
        if (score.value >= 1) outcome = score.valueText;
        else hideIncentiveRule = true;
        break;
      case IN_Cotinin:
        if (score.value == 0) outcome = score.valueText;
        else hideIncentiveRule = true;
        break;
      case IN_NoSpous:
        hideIncentiveRule = true;
      default:
        outcome = score.value;
    }
    if (!hideIncentiveRule) {
      let incentiveScore = {
        incentiveRuleId: score.incentiveRuleId,
        points: score.rollupPoints,
        value: outcome,
        ruleName: '',
        description: '',
        buttonUrl: '',
        buttonName: '',
        lastActivityDate: score.eventDate,
        count: score.count,
        maxPoints: 0,
        incentiveTypeLookupCode: '',
        showViewDetails: score.count > 1
      };
      let incentiveRule = incentiveRuleDetails[score.incentiveRuleId];
      if (incentiveRule) {
        incentiveScore.maxPoints = incentiveRule.maxPoints;
        incentiveScore.ruleName = incentiveRule.name;
        incentiveScore.description = incentiveRule.description;
        incentiveScore.incentiveTypeLookupCode = incentiveRule.incentiveTypeLookupCode;
        if (!incentiveScore.showViewDetails) {
          incentiveScore.showViewDetails = viewDetailsLookUpCodes.includes(incentiveRule.incentiveTypeLookupCode);
        }
      }
      let buttonLink = buttonLinksMap[score.incentiveRuleId];

      if (buttonLink && buttonLinkLookUpCodes.includes(incentiveRule.incentiveTypeLookupCode)) {
        (incentiveScore.buttonUrl = buttonLink.url), (incentiveScore.buttonName = buttonLink.buttonName);
      }

      if (incentiveRule.incentiveTypeLookupCode == 'IT_ChalTrk' && score.rollupPoints == 0 && !incentiveRule.required)
        hideIncentiveRule = true;

      if (!hideIncentiveRule) scoreDetails.push(incentiveScore);
    }
  });
  return scoreDetails;
};
