import React from 'react';
import ReactGa from 'react-ga';
import { PropTypes } from 'prop-types';
import { Row, Column } from 'react-foundation';
import './topicMenu.scss';

export const TopicMenu = props => {
  if (!props.loading && (!props.topics || !props.topics.length)) {
    return (
      <p className="no-topics-error">
        Something has gone wrong, no chat topics are available for your
        organization right now. Please contact your administrator.
      </p>
    );
  }

  return (
    <div className="topic-menu">
      <Row>
        <Column>
          <div className="list-container">
            <div className="list-group">
              {props.topics.map(x => getTopic(props, x))}
            </div>
          </div>
        </Column>
      </Row>
    </div>
  );
};

const getTopic = (props, topic) => {
  return (
    <button
      className="list-group-item list-group-item-action"
      key={topic.id}
      queue={topic.queue}
      onClick={e => {
        e.preventDefault();
        trackEvent(topic);
        props.topicSelected(topic);
      }}>
      <div>{topic.text}</div>
      <i className="material-icons">keyboard_arrow_right</i>
    </button>
  );
};

const trackEvent = topic => {
  ReactGa.event({
    category: 'Support',
    action: `Chat - ${topic.queueType}`
  });
};

TopicMenu.propTypes = {
  topics: PropTypes.array.isRequired,
  topicSelected: PropTypes.func.isRequired
};
