import React from 'react';

export const NoAttestationHistory = () => {
  return (
    <div className="columns medium-12 noprint">
      <div className="cdcCard1">
        <p style={{ margin: '0px' }} className="row22">
          No Return Pass certificates have been added.
        </p>
      </div>
    </div>
  );
};
