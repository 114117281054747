import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppConfiguration } from '../../configuration';
import { FaChevronLeft, FaRegAddressCard, FaAngleRight } from 'react-icons/fa';
import { Modal, Spinner } from 'react-bootstrap';
import './networksModal.scss';

export const NetworksModal = ({ handleChange, selectedNetwork }) => {
  const [show, setShow] = useState(true);
  const closeHandler = () => setShow(false);
  const [providerLink, setProviderLink] = useState(true);
  const { loadingNetworks, loadNetworksError, networks } = useSelector((state) => state.healthNavigator);
  if (loadNetworksError) {
    return (
      <div className="network-error">
        An error occurred while loading the newtworks, technical support has been notified.
      </div>
    );
  }

  const networkSpinner = (
    <div className="col network-loading">
      <Spinner as="span" animation="grow" className="network-spinner" variant="primary" size="sm" />
      <Spinner as="span" animation="grow" className="network-spinner" variant="primary" size="sm" />
      <Spinner as="span" animation="grow" className="network-spinner" variant="primary" size="sm" />
      <Spinner as="span" animation="grow" className="network-spinner" variant="primary" size="sm" />
    </div>
  );
  const selectedNetworkName = networks.map((network, index) => {
    let convertNetworkId = Number(selectedNetwork);
    if (index === 0 && network.id === convertNetworkId) {
      return 'State Preferred';
    }
    if (index === 1 && network.id === convertNetworkId) {
      return 'State BlueCare';
    }
    if (index === 2 && network.id === convertNetworkId) {
      return 'State BlueCare Prime';
    }
    return '';
  });

  return (
    <div className="networks-popup">
      {loadingNetworks ? (
        networkSpinner
      ) : (
        <p
          className="network-label"
          onClick={(e) => {
            e.preventDefault();
            setShow(!show);
          }}>
          <strong>
            <FaRegAddressCard className="fa-reg-card" size={16} />
            Your Network:
          </strong>{' '}
          <span>
            {selectedNetworkName} {selectedNetwork && <FaAngleRight className="fa-right" size={16} />}
          </span>
        </p>
      )}

      <Modal
        show={show && !loadingNetworks}
        backdrop={selectedNetwork ? true : 'static'}
        onHide={selectedNetwork ? closeHandler : null}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Connecticut State Health Networks</Modal.Title>
        </Modal.Header>

        {providerLink ? (
          <Modal.Body>
            <p>
              Find the first 3 digits of the Member Identification Number on your insurance card and click the
              appropriate network below.
            </p>
            <p
              className="modal-link"
              onClick={() => {
                setProviderLink(false);
              }}>
              Help me locate my Member Identification Number
            </p>
            <br></br>
            <h4>Choose your network to get started</h4>

            <form className="networks-form">
              {networks.map((network, index) => {
                let convertNetworkId = Number(selectedNetwork);

                return (
                  <div className="inputGroup" key={network.id} onClick={closeHandler}>
                    <input
                      id={`radio${network.id}`}
                      value={network.id}
                      name="radio"
                      type="radio"
                      checked={network.id === convertNetworkId}
                      onChange={handleChange}
                    />
                    <label htmlFor={`radio${network.id}`}>
                      {index === 0 ? (
                        <div className="card-text">
                          <span className="digits">XGS -</span> <span className="network-text"> State Preferred</span>
                        </div>
                      ) : index === 1 ? (
                        <div className="card-text">
                          <span className="digits">XGR, SHP, XGT, XGL, X5B -</span>
                          <span className="network-text"> State BlueCare</span>
                        </div>
                      ) : index === 2 ? (
                        <div className="card-text">
                          <span className="digits">X6G, X5P -</span>{' '}
                          <span className="network-text"> State BlueCare Prime</span>
                        </div>
                      ) : null}
                    </label>
                  </div>
                );
              })}
            </form>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <p
              className="modal-link"
              onClick={() => {
                setProviderLink(true);
              }}>
              <FaChevronLeft className="back-icon" /> Back to Networks
            </p>

            <div className="member-card"></div>
          </Modal.Body>
        )}

        <Modal.Footer>
          <a href={AppConfiguration.getSiteRootUrl()} className="back-to-home" alt="back to home button">
            <FaChevronLeft className="back-icon" /> Cancel and Return to Homepage
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
