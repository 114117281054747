import React from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { ErrorBoundry } from '../../shared';
import SanitizedHTML from 'react-sanitized-html';

export const ProgramSummary = (props) => {
  let programSummary = props.activeProgram.incentiveSummaryOverride;
  let linkData = props.documentLinks;

  const docLinkGenerator = (
    <>
      <div className="smallTitle">
        <h4>Important Links</h4>
      </div>
      <Row className="linkHolders">
        {linkData.map((eachLink, index) => (
          <Col key={eachLink.urlOverlayText}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={eachLink.url}
              className={'pdfIconPS ' + eachLink.url.split('.').pop()}>
              {eachLink.urlOverlayText}
            </a>
          </Col>
        ))}
      </Row>
    </>
  );
  //Check to see if any links exist....hide link section if they do not
  let howManyLinks = linkData.length;
  const linksExist = howManyLinks > 0;

  return (
    <ErrorBoundry>
      <div className="programSummary">
        {linksExist ? (
          <div className="linksBar">
            <Container>{docLinkGenerator}</Container>
          </div>
        ) : null}
        <Container className="programSummaryArea">
          <SanitizedHTML
            html={programSummary}
            allowedAttributes={{ '*': ['style'], a: ['href', 'target'] }}
            allowedTags={[
              'address',
              'article',
              'aside',
              'footer',
              'header',
              'h1',
              'h2',
              'h3',
              'h4',
              'h5',
              'h6',
              'hgroup',
              'main',
              'nav',
              'section',
              'blockquote',
              'dd',
              'div',
              'dl',
              'dt',
              'figcaption',
              'figure',
              'hr',
              'li',
              'main',
              'ol',
              'p',
              'pre',
              'ul',
              'a',
              'abbr',
              'b',
              'bdi',
              'bdo',
              'br',
              'cite',
              'code',
              'data',
              'dfn',
              'em',
              'i',
              'kbd',
              'mark',
              'q',
              'rb',
              'rp',
              'rt',
              'rtc',
              'ruby',
              's',
              'samp',
              'small',
              'span',
              'strong',
              'sub',
              'sup',
              'time',
              'u',
              'var',
              'wbr',
              'caption',
              'col',
              'colgroup',
              'table',
              'tbody',
              'td',
              'tfoot',
              'th',
              'thead',
              'tr'
            ]}
          />
        </Container>
      </div>
    </ErrorBoundry>
  );
};
