import React, { useState, useEffect } from 'react';
import { Collapse, Button } from 'react-bootstrap';
import { MdExpandMore, MdExpandLess, MdAdd } from 'react-icons/md';
import { ProviderLists } from './providerLists';
import './fullDetails.scss';

export const FullDetails = ({ providerFullDetails, providerList, children }) => {
  const [open, setOpen] = useState(false);
  const [isMobile, setMobile] = useState(window.innerWidth < 990);
  const notAcceptingPatientsStyle = { bottom: '45px' };
  const proceduresStyle = { bottom: '95px' };
  const collapseStyle = { position: 'relative', bottom: '35px' };
  const updateSreenSize = () => {
    setMobile(window.innerWidth < 990);
  };
  useEffect(() => {
    window.addEventListener('resize', updateSreenSize);
    return () => window.removeEventListener('resize', updateSreenSize);
  });

  return (
    <div className="full-details">
      {!isMobile ? (
        <div className="row procedures1">
          <div className="col-md-4">
            <div className="table-header">
              <div className="col table-cell incentive-list-icon">
                <strong></strong>
              </div>
              <div className="col table-cell incentive-header">
                <strong>Procedure</strong>
              </div>
              <div className="col table-cell">
                <strong>Incentive</strong>
              </div>
            </div>
            <div className="table-header">
              <div className="col table-cell incentive-list-icon">{providerFullDetails.firstIncentiveRating}</div>
              <div className="col table-cell incentive-name">{providerFullDetails.firstProcedureName}</div>
              <div className="col table-cell">${providerFullDetails.firstIncentiveAmount}</div>
            </div>

            {!open && providerFullDetails.proceduresCount > 1 ? (
              <div className="col more-procedures">
                <strong>
                  <MdAdd />
                </strong>{' '}
                {providerFullDetails.proceduresCount - 1} more procedures & conditions
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {isMobile && !open ? (
        <div className="row procedures1">
          <div className="col-md-4">
            <div className="table-header">
              <div className="col table-cell incentive-list-icon">
                <strong></strong>
              </div>
              <div className="col table-cell incentive-header">
                <strong>Procedure</strong>
              </div>
              <div className="col table-cell">
                <strong>Incentive</strong>
              </div>
            </div>
            <div className="table-header">
              <div className="col table-cell incentive-list-icon">{providerFullDetails.firstIncentiveRating}</div>
              <div className="col table-cell incentive-name">{providerFullDetails.firstProcedureName}</div>
              <div className="col table-cell">${providerFullDetails.firstIncentiveAmount}</div>
            </div>

            {!open && providerFullDetails.proceduresCount > 1 ? (
              <div className="col more-procedures">
                <strong>
                  <MdAdd />
                </strong>{' '}
                {providerFullDetails.proceduresCount - 1} more procedures & conditions
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      <Collapse in={open}>
        <div className="row" style={open && !isMobile ? collapseStyle : null}>
          {providerFullDetails.acceptingPatients ? (
            <div className="col-md-12">
              {providerFullDetails.providerListCount > 0 ? (
                ''
              ) : (
                <p className="accepting-patients">{providerFullDetails.acceptingPatients}</p>
              )}
            </div>
          ) : null}

          {providerFullDetails.providerListCount > 0 ? (
            ''
          ) : (
            <div className="col-md-4">
              <div className="col">
                {providerFullDetails.resultType && (
                  <div className="col cm6">
                    <div className="col">
                      <p className="heading">Specialties</p>
                      <p>{providerFullDetails.providerSpecialties}</p>
                    </div>
                    <div className="col">
                      <p className="heading">Gender</p>
                      <p>{providerFullDetails.providerGenderDisplay}</p>
                    </div>
                  </div>
                )}

                <div className="col cm6">
                  <div className="col">
                    <p className="heading">Language</p>
                    <p>{providerFullDetails.languageDisplay}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* procedures start */}
          <div
            className="col-md-4 procedures0"
            style={
              !providerFullDetails.acceptingPatients
                ? notAcceptingPatientsStyle
                : open && !isMobile
                ? proceduresStyle
                : null
            }>
            {isMobile ? (
              <>
                <div className="table-header">
                  <div className="col table-cell incentive-list-icon">
                    <strong></strong>
                  </div>
                  <div className="col table-cell incentive-header">
                    <strong>Procedure</strong>
                  </div>
                  <div className="col table-cell">
                    <strong>Incentive</strong>
                  </div>
                </div>
                <div className="table-header">
                  <div className="col table-cell incentive-list-icon">{providerFullDetails.firstIncentiveRating}</div>
                  <div className="col table-cell incentive-name">{providerFullDetails.firstProcedureName}</div>
                  <div className="col table-cell">${providerFullDetails.firstIncentiveAmount}</div>
                </div>
              </>
            ) : null}

            {open ? <div className="col procedures2 no-padding">{children}</div> : null}

            {!open && providerFullDetails.proceduresCount > 1 ? (
              <p className="more-procedures">
                <strong>
                  {' '}
                  <MdAdd />
                </strong>{' '}
                {providerFullDetails.proceduresCount - 1} more procedures & conditions
              </p>
            ) : null}
          </div>
          {providerFullDetails.providerListCount > 0 && <ProviderLists providerList={providerList} />}
          {/*procedures end*/}
        </div>
      </Collapse>

      <Button
        onClick={() => {
          setOpen(!open);
        }}
        className="btn btn-light"
        aria-controls="collapse-text"
        aria-expanded={open}>
        {open ? (
          <div>
            {providerFullDetails.providerListCount > 0 ? 'Hide providers' : 'Hide full details'} <MdExpandLess />
          </div>
        ) : (
          <div>
            Show full details <MdExpandMore />
          </div>
        )}
      </Button>
    </div>
  );
};
