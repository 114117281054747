import React from 'react';
import './infoMessage.scss';

export const InfoMessage = props => {
  return (
    <div className="info-message">
      <span>{`${props.message.text} ${props.message.time}`}</span>
    </div>
  );
};
