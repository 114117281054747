import * as types from './actionTypes.js';
import { updateSessionToken } from '../../session';
import { ApiClient } from '../../api';
import { AppConfiguration } from '../../configuration';
import { getSessionToken } from '../../session';

export const loadFaxHistory = logError => {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return dispatch => {
    return new Promise(async resolve => {
      const apiClient = new ApiClient(apiRootUrl, sessionToken);
      try {
        const apiResult = await apiClient.get('member/fax');
        if (apiResult.data.SessionToken) {
          updateSessionToken(apiResult.data.SessionToken);
        }
        if (apiResult.data && apiResult.data.Result) {
          const result = convertResult(apiResult.data.Result);
          resolve(
            dispatch(loadFaxHistorySuccess(result, faxesPending(result)))
          );
        } else {
          resolve(dispatch(loadFaxHistoryFailed()));
        }
      } catch (error) {
        logError('faxes/history/actions', 'loadFaxHistory', error);
        resolve(dispatch(loadFaxHistoryFailed()));
      }
    });
  };
};

let faxHistoryIntervalId = null;

export const startHistoryRefresh = async dispatch => {
  const refreshTimeout = 60000;
  const result = await dispatch(loadFaxHistory());
  if (result.faxesPending) {
    faxHistoryIntervalId = setInterval(async () => {
      const result = await dispatch(loadFaxHistory());
      if (!result.faxesPending) {
        clearInterval(faxHistoryIntervalId);
      }
    }, refreshTimeout);
  }
};

export const faxesPending = faxes => {
  const sent = 'sent';
  const failed = 'failed';
  const cancelled = 'cancelled';
  let pending = false;
  if (faxes) {
    for (let i = 0; i < faxes.length; i++) {
      const faxStatus = faxes[i].faxStatus.toLowerCase();
      const externalFaxId = faxes[i].externalFaxId;
      pending =
        !!externalFaxId &&
        faxStatus !== sent &&
        faxStatus !== failed &&
        faxes !== cancelled;
      if (pending) {
        break;
      }
    }
  }
  return pending;
};

export const loadFaxHistorySuccess = (faxHistory, faxesPending) => {
  return {
    type: types.LOAD_FAX_HISTORY_SUCCESS,
    faxHistory: faxHistory,
    faxesPending: faxesPending
  };
};

const convertResult = results => {
  return !results
    ? []
    : results.map(x => {
        return {
          memberFaxId: x.MemberFaxId,
          memberId: x.MemberId,
          memberDocumentId: x.MemberDocumentId,
          externalFaxId: x.ExternalFaxId,
          firstName: x.FirstName,
          lastName: x.LastName,
          faxNumber: x.FaxNumber,
          lastStatusDate: x.LastStatusDate,
          faxStatus: x.FaxStatus,
          memberSignOffDate: x.MemberSignOffDate,
          documentTitle: x.DocumentTitle
        };
      });
};

export const loadFaxHistoryFailed = () => {
  return { type: types.LOAD_FAX_HISTORY_FAILED };
};
