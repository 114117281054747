import React from 'react';
import Modal from 'react-modal';
import ReactGa from 'react-ga';
import { useDispatch } from 'react-redux';
import { sendReport } from './actions';
import { startHistoryRefresh } from '../history';
import { logError } from '../../logging';
import './authorizationModal.scss';

export const AuthorizationModal = props => {
  const dispatch = useDispatch();
  return (
    <Modal
      overlayClassName="authorization-modal-overlay"
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false} //opting out of screen reader support. (for now)
      className="authorization-modal"
      isOpen={props.authorizationModalOpen}
      onRequestClose={e => {
        props.setAuthorizationModalOpen(false);
      }}>
      <div className="linked card shadow">
        <div className="card-divider background-health">
          <h3 className="cardtitle">Authorization</h3>
        </div>
        <div className="card-section acknowledgement">
          By clicking I Accept, I acknowledge that Health Advocate is releasing
          my personal health information at my request and at my specific
          direction. Health Advocate will fax the information to the fax number
          I have provided and it is my sole responsibility to verify and confirm
          the accuracy of the fax number I have provided. Health Advocate is not
          responsible to verify the accuracy or confidentiality of a provided
          fax number.
        </div>
        <div className="card-buttons">
          <input
            type="button"
            className="button hollow  cancel-btn"
            defaultValue="Cancel"
            onClick={e => {
              e.preventDefault();
              props.setAuthorizationModalOpen(false);
            }}
          />
          <input
            className="button accept-btn"
            type="submit"
            value="I Accept"
            onClick={e => {
              e.preventDefault();
              const selectedDates = Array.from(props.measurementDates.values());
              dispatch(
                sendReport(props.recipientId, selectedDates, true, logError)
              ).then(() => {
                startHistoryRefresh(dispatch);
              });
              ReactGa.event({
                category: 'Faxing',
                action: 'Send Biometrics Report to Provider'
              });
              props.setMeasurementDates(new Map());
              props.setAuthorizationModalOpen(false);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
