import { ProgramStatusType } from './programStatusType';

export class ProgramDetails {
  constructor() {
    this.currentTier = 0;
    this.earnedPoints = 0;
    this.pointsToNextTier = 0;
    this.status = ProgramStatusType.NotStarted;
    this.tierPoints = []; // (note MUST be cummulative, eg. 25, 50, 100 correct, 25, 25, 50 incorrect)
    this.isRewardProgram = false;
    this.rewardBalance = 0;
    this.rewardLevel = 0;
    this.canBrowseRewards = false;
    this.canRedeemRewards = false;
    this.rewardsUrl = '';
    this.incentiveProgramOrganizationId = 0;
  }

  static create(apiStatusResult) {
    let details = new ProgramDetails();
    if (apiStatusResult.data && apiStatusResult.data.Result) {
      var result = apiStatusResult.data.Result;
      details.currentTier = result.CurrentTier;
      details.earnedPoints = result.EarnedPoints;
      details.pointsToNextTier = result.PointsToNextTier;
      details.status = result.Status;
      details.tierPoints = result.TierPoints;
      details.isRewardProgram = result.IsRewardProgram;
      details.rewardBalance = result.RewardBalance;
      details.rewardLevel = result.RewardLevel;
      details.canBrowseRewards = result.CanBrowseRewards;
      details.canRedeemRewards = result.CanRedeemRewards;
      details.rewardsUrl = result.RewardsUrl;
      details.incentiveProgramOrganizationId =
        result.IncentiveProgramOrganizationId;
    }
    return details;
  }
}
