import React from 'react';
import ReactGa from 'react-ga';
import { AppConfiguration } from '../../configuration';
import { useDispatch, useSelector } from 'react-redux';
import { showWellnessChat, hideChat } from '../store';
import './wellnessChatButton.scss';

export const WellnessChatButton = () => {
  const dispatch = useDispatch();
  const { wellnessChatVisible, menuChatVisible } = useSelector((state) => state.chat);
  const chatVisible = wellnessChatVisible || menuChatVisible;
  const imageUrl = AppConfiguration.getImagePath('icon_chatNav2.svg');
  return (
    <div className="chat-button-wellness">
      <button onClick={(e) => handleShowChatClick(e, chatVisible, dispatch)}>
        <img src={imageUrl} alt="Live Chat" />
        <div className="title">Live Chat</div>
      </button>
    </div>
  );
};

const handleShowChatClick = (event, chatVisible, dispatch) => {
  event.preventDefault();
  if (chatVisible) {
    dispatch(hideChat());
  } else {
    trackEvent();
    dispatch(showWellnessChat());
  }
};

const trackEvent = () => {
  ReactGa.event({
    category: 'Support',
    action: `Chat - Wellness`
  });
};
