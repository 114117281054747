import React from 'react';
import { getAntiForgeryToken } from '../antiforgery';
import { AppConfiguration } from '../configuration';
import { getRedirectRoute } from './routeRedirectMap';

// TODO: pun intended - re-write this, ported directly from old MVC code and its overly complex
export const TodoItem = (props) => {
  const redirectRoute = getRedirectRoute(props.todo.ToDoType);
  // These To Do items require a healthy path redirect to take special actions    // such as setting up an iFrame route
  if (redirectRoute) {
    return getTodoForm(props.todo, redirectRoute);
  }
  // These are plan hyper link to do items
  if (props.todo.Href && props.todo.Href.length > 0) {
    return getTodoLink(props.todo);
  }
  return getPlainTodo(props.todo);
};

// To Do items that need an iFrame redirect into wellness post-back to healthy path
// home/index/[action] in order to do so
const getTodoForm = (todo, redirectRoute) => {
  const postUrl = `${AppConfiguration.getSiteRootUrl()}${redirectRoute.controller}/${redirectRoute.action}`;
  const token = getAntiForgeryToken();
  const verificationTokenName = '__RequestVerificationToken';
  let form = null;
  return (
    <form
      action={postUrl}
      id={todo.ToDoGuid}
      method="post"
      ref={(ref) => {
        form = ref;
      }}>
      <input id="href" name="Href" type="hidden" value={todo.Href} />
      <input name={verificationTokenName} type="hidden" value={token} />
      {/*eslint-disable */}
      <a
        href=""
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          form.submit();
        }}>
        <p>{todo.Title}</p>
        {todo.ShortInfo ? <div>{todo.ShortInfo}</div> : null}
        {todo.Points ? <span className="points label">+{todo.Points} POINTS</span> : null}
        {todo.Required ? <span className="small label">REQUIRED</span> : null}
      </a>
      {/*eslint-enable */}
    </form>
  );
};

// Render a todo item that is a hyperlink
const getTodoLink = (todo) => {
  return (
    <a href={todo.Href}>
      <p>{todo.Title}</p>
      {todo.ShortInfo ? <div>{todo.ShortInfo}</div> : null}
      {todo.Points ? <span className="points label">+{todo.Points} POINTS</span> : null}
      {todo.Required ? <span className="small label">REQUIRED</span> : null}
    </a>
  );
};

// Render a todo item that doens't link anywhere
const getPlainTodo = (todo) => {
  return (
    <div>
      <p>{todo.Title}</p>
      {todo.ShortInfo ? <div>{todo.ShortInfo}</div> : null}
      {todo.Points ? <span className="points label">+{todo.Points} POINTS</span> : null}
      {todo.Required ? <span className="small label">REQUIRED</span> : null}
    </div>
  );
};
