import { ApiClient } from '../../api';
import { AppConfiguration } from '../../configuration';
import { getSessionToken, updateSessionToken } from '../../session';
import * as types from './actionTypes.js';

export const checkIfAgentsAvailable = (queueName, logError, agentsAvailableCallback) => {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return async (dispatch) => {
    const client = new ApiClient(apiRootUrl, sessionToken);
    try {
      const apiResult = await client.get(`avaya/queuestatus/${queueName}`);
      if (apiResult.data.sessionToken) {
        updateSessionToken(apiResult.data.sessionToken);
      }
      const result = apiResult.data.result;
      dispatch(updateAgentsAvailable(result.availableAgentsInQueue > 0, result.inChatTimeWindow));
      const agentsAvailable = result.availableAgentsInQueue > 0 && result.inChatTimeWindow;
      if (agentsAvailable && agentsAvailableCallback) {
        agentsAvailableCallback(result.availableAgentsInQueue > 0 && result.inChatTimeWindow);
      }
    } catch (error) {
      console.error(`An error occured checking chat agent status`);
      console.error(error);
    }
  };
};

export const updateAgentsAvailable = (agentsAvailable, inChatTimeWindow) => {
  return {
    type: types.UPDATE_AGENTS_AVAILABLE,
    data: { agentsAvailable: agentsAvailable, inChatTimeWindow: inChatTimeWindow }
  };
};

export const updateEstimatedWaitTime = (estimatedWaitTime) => {
  return {
    type: types.UPDATE_ESTIMATED_WAIT_TIME,
    estimatedWaitTime: estimatedWaitTime
  };
};

export const updateParticipants = (participants, chatInProgress) => {
  return {
    type: types.UPDATE_PARTICIPANTS,
    participants: participants,
    chatInProgress: chatInProgress
  };
};
