import React from 'react';

export const TodoLoadingAnimation = () => {
  return (
    <div className="card shadow background-blur with-icon loadingFiller">
      <svg
        version="1.1"
        id="L4"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 415 350"
        enableBackground="new 0 0 0 0">
        <rect x="0" y="0" fill="#ddd" width="415" height="35">
          <animate attributeName="opacity" dur="2s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
        </rect>
        <rect x="0" y="40" fill="#ddd" width="120" height="20">
          <animate attributeName="opacity" dur="2s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
        </rect>
        <rect x="0" y="80" fill="#ddd" width="415" height="35">
          <animate attributeName="opacity" dur="2s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
        </rect>
        <rect x="0" y="135" fill="#ddd" width="415" height="35">
          <animate attributeName="opacity" dur="2s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
        </rect>
        <rect x="0" y="190" fill="#ddd" width="415" height="35">
          <animate attributeName="opacity" dur="2s" values="0;1;0" repeatCount="indefinite" begin="0.4" />
        </rect>
        <rect x="0" y="245" fill="#ddd" width="415" height="35">
          <animate attributeName="opacity" dur="2s" values="0;1;0" repeatCount="indefinite" begin="0.5" />
        </rect>
        <rect x="0" y="285" fill="#ddd" width="120" height="20">
          <animate attributeName="opacity" dur="2s" values="0;1;0" repeatCount="indefinite" begin="0.5" />
        </rect>
      </svg>
    </div>
  );
};
