import { AppConfiguration } from '../configuration';
import { ApiClient } from '../api';
import { getSessionToken } from '../session';

export const updateSelectedProvider = (searchResultId, providerId) => {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  const client = new ApiClient(apiRootUrl, sessionToken);
  const data = {
    selectedProviderId: providerId
  };
  client.put(`healthnavigator/searchresult/${searchResultId}`, data);
};
