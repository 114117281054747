import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { AppConfiguration } from '../configuration';
import { links } from './links';
import { QuickLinksItem } from './quickLinksItem';
import './quickLinksItems.scss';
import { ErrorCard } from '../shared/error';

export const QuickLinksItems = () => {
  const [itemClass, setItemClass] = useState('col-lg-2 col-md-2 col-sm-6 col-6 quick-links-item ');
  const loading = useSelector(
    (state) =>
      state.clientServices.loading ||
      state.contentExclusions.loading ||
      state.clients.loading ||
      state.organizationConfigurations.loading
  );
  const error = useSelector(
    (state) => state.clientServices.error || state.contentExclusions.error || state.clients.error
  );
  const clientServices = useSelector((state) => state.clientServices.list);
  const client = useSelector((state) => state.clients.client);
  const contentExclusions = useSelector((state) => state.contentExclusions.list);
  const organizationConfigurations = useSelector((state) => state.organizationConfigurations.list);
  let quickLinks = [];

  useEffect(() => {
    handleItemClass(quickLinks, setItemClass);
  }, [quickLinks]);

  // show error card
  if (error) {
    return (
      <ErrorCard
        message="An error occurred while loading quick links, technical support has been
      notified."
      />
    );
  }

  quickLinks = quickLinkItems(
    links,
    loading,
    client,
    clientServices,
    contentExclusions,
    itemClass,
    organizationConfigurations
  );
  return <>{quickLinks}</>;
};

// Update class based on number of items
const handleItemClass = (quickLinks, setItemClass) => {
  if (quickLinks && quickLinks.length < 13) {
    return setItemClass('col-md-2 col-sm-6 col-6 quick-links-item ');
  }
  if (quickLinks && quickLinks.length > 12 && quickLinks.length < 15) {
    return setItemClass('col-lg-13 col-md-2 col-sm-6 col-6 quick-links-item ');
  }
};

// Iterate all link components defined in links.js & determine which to show
const quickLinkItems = (
  links,
  loading,
  client,
  clientServices,
  clientExclusions,
  itemClass,
  organizationConfigurations
) => {
  let linkComponents = links.map((link, index) => {
    const refIdToken = '<#REFID#>';
    const { title, image, openNewWindow } = link;
    let url = link.url;
    if (loading) return getLoadingIndicator(index);
    const match = servicesMatch(link, clientServices);
    const excluded = itemExcluded(link, clientExclusions);
    if (!match || excluded) {
      return null;
    }
    // Replace workref token if in url w/ real ID
    url = url.replace(refIdToken, client.workLifeRefId);

    // If memd - but NOT sso, use custom url instead
    if (
      link.requiredServices.includes('Telemedicine') ||
      link.requiredServices.includes('ExternalTelemedicineProvider')
    ) {
      url = client.meMdSsoEnabled ? url : client.meMdUrl;
    }
    if (link.requiredServices.includes('Telemedicine') && client.meMdSsoEnabled) {
      url = `${AppConfiguration.getSiteRootUrl()}${url}`;
    }

    if (link.requiredServices.includes('PicWellSelectionTool')) {
      let picwellUrl = organizationConfigurations.find((config) => {
        return config.LookupCode === 'PWELL_URL';
      }).Value;
      if (picwellUrl) url = picwellUrl;
    }
    // TODO: same URL logic as above for 'external telemed service' - get URL from client.meMdUrl
    return (
      <QuickLinksItem
        image={AppConfiguration.getImagePath(image)}
        text={title}
        url={url}
        key={title}
        openNewWindow={openNewWindow}
        itemClass={itemClass}
      />
    );
  });
  // Remove null items (ie. not to be shown)
  linkComponents = linkComponents.filter((x) => !!x);
  return linkComponents;
};

const getLoadingIndicator = (index) => {
  return (
    <div className="col-lg-2 col-md-2 col-sm-6 col-6" key={index}>
      <Spinner animation="border" size="sm" variant="light" />
    </div>
  );
};

const servicesMatch = (link, clientServices) => {
  let required = link.requiredServices.length === 0;
  if (link.requiredServices && link.requiredServices.length > 0 && clientServices && clientServices.length > 0) {
    required =
      clientServices.filter((value) => link.requiredServices.includes(value)).length === link.requiredServices.length;
  }

  let match = link.services.length === 0;
  if (link.services && link.services.length > 0 && clientServices && clientServices.length > 0) {
    match = clientServices.filter((value) => link.services.includes(value)).length > 0;
  }
  return required && match;
};

const itemExcluded = (link, clientExclusions) => {
  let excluded = false;
  if (link.exclusionTypes && link.exclusionTypes.length > 0 && clientExclusions && clientExclusions.length > 0) {
    excluded = clientExclusions.filter((value) => link.exclusionTypes.includes(value)).length > 0;
  }
  return excluded;
};
