import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EventEmitter } from 'fbemitter';
import { AppConfiguration } from '../../configuration';
import { hideChat } from '../store';
import { ChatPopUp } from '../popup';
import { loadingSelector } from './loadingSelector';
import { errorSelector } from './errorSelector';
import { wellnessQueueSelector } from './wellnessQueueSelector';
import './chatMount.scss';

// 'Mount' element for chat.  Needed as the chat component itself cannot be in the main menu in healthypath, but the
// button that invokes it does have to be.  If the chat component itself was in the menu it would be dismissed on scroll
// action which closes the menu.  This component also shows/hides the chat dialog from any button on the page its launched
// by and uses redux state to toggle visibility

let eventEmitter = null;

export const ChatMount = () => {
  const dispatch = useDispatch();
  const chatState = useSelector((state) => state.chat);
  const member = useSelector((state) => state.members.member);
  const client = useSelector((state) => state.clients.client);
  const organization = useSelector((state) => state.organizations.organization);
  const chatVisible = chatState.menuChatVisible || chatState.wellnessChatVisible;
  const loading = useSelector((state) => loadingSelector(state));
  const error = useSelector((state) => errorSelector(state));
  const contactUrl = `${AppConfiguration.getSiteRootUrl()}Home/Message`;
  const description =
    "Please fill out the information in the form below then click 'Start Live Chat' and an agent will assist you as soon as possible.";
  const title = 'Live Chat';
  // If it's 'wellness' specific chat being displayed set queue directly on chat popup (else topic dialog will handle)
  let wellnessQueue = null;
  if (chatState.wellnessChatVisible) {
    wellnessQueue = wellnessQueueSelector(chatState);
  }
  useEffect(() => {
    eventEmitter = new EventEmitter();
    eventEmitter.addListener('ChatPopUp.Close', () => {
      dispatch(hideChat());
    });
  }, [dispatch]);
  return chatVisible ? (
    <div className={chatVisible ? 'mount-container show' : 'mount-container'}>
      <ChatPopUp
        eventEmitter={eventEmitter}
        loading={loading}
        error={error}
        topics={chatState.topics}
        queue={wellnessQueue}
        member={member}
        client={client}
        organization={organization}
        contactUrl={contactUrl}
        title={title}
        description={description}
      />
    </div>
  ) : null;
};
