import * as types from './actionTypes';
import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';

export const loadIncentivePrograms = (logError) => {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get('member/incentiveprogram');
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        resolve(dispatch(loadIncentiveProgramsSuccess(apiResult.data.result)));
      } catch (error) {
        logError('incentives/programs/actions', 'loadIncentivePrograms', error);
        resolve(dispatch(loadIncentiveProgramsFailed()));
      }
    });
  };
};

const loadIncentiveProgramsSuccess = (list) => {
  return { type: types.LOAD_INCENTIVEPROGRAMS_SUCCESS, list };
};

const loadIncentiveProgramsFailed = () => {
  return { type: types.LOAD_INCENTIVEPROGRAMS_FAILED };
};
