import React, { useState } from 'react';
import { Row, OverlayTrigger, Popover } from 'react-bootstrap';
import { getAppUrl, getAppTarget } from '../../configuration';
import { FaTrophy } from 'react-icons/fa';
import { MdStar, MdOpenInNew } from 'react-icons/md';
import { useSelector } from 'react-redux';

const XGL = 'https://www.anthem.com/health-insurance/provider-directory/searchcriteria?alphaprefix=XGL';
const XGS = 'https://www.anthem.com/health-insurance/provider-directory/searchcriteria?alphaprefix=XGS';
const X6G = 'https://www.anthem.com/health-insurance/provider-directory/searchcriteria?alphaprefix=X6G';
export const FilterResults = (props) => {
  const searchResults = useSelector((state) => state.healthNavigator.searchResults);
  const providerNetwork = useSelector((state) => state.healthNavigator.providerNetwork);
  const networks = useSelector((state) => state.healthNavigator.networks);

  const selectedNetwork = networks.map((network, index) => {
    let convertNetworkId = Number(providerNetwork);
    if (index === 0 && network.id === convertNetworkId) {
      return XGS;
    }
    if (index === 1 && network.id === convertNetworkId) {
      return XGL;
    }
    if (index === 2 && network.id === convertNetworkId) {
      return X6G;
    }
    return '';
  });

  return (
    <>
      <Row md={12} className="filter">
        <div className="col-md-auto result-count">
          <p>
            {searchResults && searchResults.providers !== null ? props.providerResultCount : 0} results found with{' '}
            {searchResults.providers !== null && props.providerResultCount === 1 ? 'incentive' : 'incentives'}{' '}
            <span className="divider0" style={{ paddingLeft: '15px' }}>
              {' '}
              |
            </span>
          </p>
        </div>
        <div className="col-md-5 rs0">
          <a
            className="external-link"
            target={getAppTarget('_blank')}
            rel="noopener noreferrer"
            href={getAppUrl(selectedNetwork[0] || selectedNetwork[1] || selectedNetwork[2])}>
            See all Connecticut healthcare providers within your network <MdOpenInNew />
          </a>
        </div>

        <div className="col sort">
          <div className="legend-icons">
            <OverlayTrigger
              placement="top"
              overlay={
                <Popover id="incentive-tooltip">
                  The doctors, hospitals and medical groups meeting the highest quality standards are designated
                  “Centers of Excellence.” Using a Center of Excellence, when available, may earn you a larger cash
                  reward. They are marked with a gold star in the Providers of Distinction Search.
                </Popover>
              }>
              <p>
                <MdStar className="star" /> Center of Excellence
              </p>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={
                <Popover id="incentive-tooltip">
                  The State of Connecticut has identified doctors, hospitals and medical care groups in the state that
                  have verified high-quality outcomes for some of the most common medical conditions and procedures.
                  Doctors and locations proven to meet these high standards are known as “Providers of Distinction.”
                  These providers will appear first in the Providers of Distinction Search, clearly marked by a silver
                  trophy. When you choose a Provider of Distinction for a listed procedure, you will automatically
                  receive a cash incentive in the mail.
                </Popover>
              }>
              <p>
                <FaTrophy className="trophy" title="Providers of Distinction" /> Providers of Distinction
              </p>
            </OverlayTrigger>
          </div>
        </div>
      </Row>
      <div className="row justify-content-end"></div>
    </>
  );
};
