import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addAttestation } from '../members/attestations';
import * as attestationBody from './attestationBody';
import { CoronaVirusSelfCheckerType } from '../members/attestations';
import { SelfCheckerHelpModal } from './selfCheckerHelpModal';
import { SelfCheckerTemperatureModal } from './selfCheckerTemperatureModal';
import { FaQuestionCircle } from 'react-icons/fa';
import * as checkerStatusTypes from './checkerStatusTypes';
import './attestationForm.scss';
export const AttestationForm = (props) => {
  const attestationHistory = useSelector(
    (state) => state.memberAttestations.byType[CoronaVirusSelfCheckerType] || null
  );
  const showTemperatureField = attestationHistory ? attestationHistory.showTemperature : true;
  const reAttestHours = attestationHistory ? attestationHistory.reAttestHours : 0;
  const [signedOff, setSignedOff] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [temperatureModalIsOpen, setTemperatureIsOpen] = useState(false);
  const dispatch = useDispatch();
  let reAttestText = `You can only submit this form once every ${reAttestHours} hours.`;
  if (reAttestHours === 1) reAttestText = 'You can only submit this form once every hour.';
  const attestationText = props.showCdcIframe
    ? attestationBody.attestationText
    : attestationBody.shortenedAttestationText;

  return (
    <div className="columns medium-12 attestation-form px-0">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(
            addAttestation(CoronaVirusSelfCheckerType, attestationText, props.cdcResult, props.submittedTemperature)
          );
          props.handleCert();
        }}>
        {/* Temperature field */}
        {showTemperatureField ? (
          <div className="columns small-12 large-12 temp0 cdc-no-pad noprint">
            <p className="p-11">Please enter your temperature</p>

            <fieldset id="cdcTemperatureRadio">
              <label htmlFor="fahrenheit" className="temp-label">
                <input
                  type="radio"
                  name="cdcTemperatureRadio"
                  id="fahrenheit"
                  value="fahrenheit"
                  onClick={props.fahrenheitClicked}
                  defaultChecked></input>
                Fahrenheit
              </label>
              <label htmlFor="celsius" className="temp-label">
                <input
                  type="radio"
                  name="cdcTemperatureRadio"
                  value="celsius"
                  id="celsius"
                  onClick={props.celciusClicked}></input>
                Celsius
              </label>
            </fieldset>

            <div className="temperature-value row mx-0">
              <div
                className={
                  'columns small-7 medium-3 large-3 range-value0 ' + (props.showQuestions ? 'fsborder' : null)
                }>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <button
                      className="cdc-btn btn-outline-cdc"
                      onMouseDown={props.stepDownClicked}
                      onClick={(e) => {
                        e.preventDefault();
                      }}>
                      <strong>-</strong>
                    </button>
                  </div>

                  <input
                    type="text"
                    id="rangevalue"
                    ref={props.rangeValueRef}
                    value={props.temperature}
                    onChange={props.rangeValueOnChange}
                  />
                  <div className="input-group-append">
                    <span className="temperature-unit-text">
                      {props.temperatureUnit === checkerStatusTypes.celsius ? <span>&deg;C</span> : <span>&deg;F</span>}
                    </span>
                    <button
                      className="cdc-btn btn-outline-cdc"
                      onMouseDown={props.stepUpClicked}
                      onClick={(e) => {
                        e.preventDefault();
                      }}>
                      <strong>+</strong>
                    </button>
                  </div>
                </div>
              </div>
              <div className="columns small-5 large-6 help1">
                <p
                  onClick={() => {
                    setTemperatureIsOpen(true);
                  }}>
                  <span>
                    <FaQuestionCircle />
                  </span>
                  How to take my temperature
                </p>
              </div>
            </div>

            <div className="columns temp0">
              <div className="alert alert-info">
                {props.temperatureUnit === checkerStatusTypes.celsius ? (
                  <i>38.0°C or greater is considered a fever.</i>
                ) : (
                  <i>100.4 or greater is considered a fever. </i>
                )}
              </div>
            </div>
          </div>
        ) : null}
        {/* end of temperature field */}

        <div className="columns small-12 large-12 cdc-no-pad form-no-padding noprint">
          <fieldset className="fs0">
            <label className="covid-checkbox" htmlFor="covidCheckbox">
              <input type="checkbox" onChange={(e) => setSignedOff(e.target.checked)} />
              {attestationText}
            </label>
          </fieldset>
        </div>
        <div className="columns small-12 large-12 fs1 form-no-padding cdc-no-pad noprint">
          {reAttestHours ? (
            <p className="note">
              <strong>Note: </strong> {reAttestText}
            </p>
          ) : null}
          <fieldset>
            <input
              type="submit"
              className="button"
              value="Submit"
              disabled={props.loading || !props.cdcResult || !signedOff}
            />
          </fieldset>
        </div>
      </form>

      <SelfCheckerHelpModal
        modalIsOpen={modalIsOpen}
        certClicked={() => {
          setIsOpen(false);
        }}
      />

      <SelfCheckerTemperatureModal
        modalIsOpen={temperatureModalIsOpen}
        certClicked={() => {
          setTemperatureIsOpen(false);
        }}
      />
    </div>
  );
};
const getButtonClass = (currentButton, cdcResult) => {
  if (currentButton === cdcResult) {
    return 'cdc-button cdc-result-selection';
  }
  return 'cdc-button';
};
