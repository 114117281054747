import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { loadingSelector } from './loadingSelector';
import { Section } from './section';
import { getCurrentProgram } from '../programs';
import { loadSectionData } from './loadSectionData';
import { ErrorBoundry } from '../../shared';
import { PointsCompleted } from './pointsCompleted';
import './earnPoints.scss';

export const EarnPoints = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const ipoid = urlParams.get('ipoid');
  const loading = useSelector((state) => loadingSelector(state));
  const {
    sections,
    incentivePrograms,
    incentiveRules,
    incentiveButtonLinks,
    incentiveLinkedRules,
    memberIncentiveScores
  } = useSelector((state) => state);

  if (loading) {
    return <Spinner animation="border" size="lg" variant="primary" />;
  }

  if (!loading) {
    const activeProgram = getCurrentProgram(incentivePrograms.list, ipoid);
    const incentiveProgramId = activeProgram.incentiveProgramId;
    const incentiveProgramOrgId = activeProgram.incentiveProgramOrganizationId;
    const sectionData = loadSectionData(
      sections,
      memberIncentiveScores,
      incentiveProgramId,
      incentiveRules,
      incentiveButtonLinks,
      incentiveLinkedRules,
      incentiveProgramOrgId
    );

    const sectionsList = sectionData.sectionsList;

    if (sectionsList[0] && sectionsList[0].rules.length == 0) {
      return <PointsCompleted noProgramAvailable={false} />;
    }

    return (
      <ErrorBoundry>
        <div className="tabContent">
          {Object.keys(sectionsList).map((key) => (
            <div key={key}>
              {sectionsList[key].rules.length > 0 && (
                <Container>
                  <h4>{sectionsList[key].sectionName}</h4>
                  <Container className="pointsGrid earnPointsGrid">
                    <Row className="tableHeadRow">
                      <Col md={6}>Activity</Col>
                      <Col md={3}>Points</Col>
                      <Col md={3}></Col>
                    </Row>
                    <Section rules={sectionsList[key].rules} />
                  </Container>
                </Container>
              )}
            </div>
          ))}
        </div>
      </ErrorBoundry>
    );
  }
  return <Spinner animation="border" size="lg" variant="primary" />;
};
