import * as types from './actionTypes';

const initialState = {
  list: [],
  loading: true,
  error: false
};

export const organizationConfigurationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ORGANIZATION_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        list: action.organizationConfigurations,
        loading: false,
        error: false
      };

    case types.LOAD_ORGANIZATION_CONFIGURATIONS_FAILED:
      return {
        ...state,
        list: [],
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
