import React, { Component } from 'react';

export class SectionErrorCardComponent extends Component {
  render() {
    return (
      <div className="small-12 columns large-4 columns">
        <div className="card shadow" data-equalizer-watch>
          <div className="card-section">
            <p className="cardtext">
              {`An error occurred loading the ${this.props.title} section,
              technical support has been notified.`}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
