import { ApiClient } from '../api';
import { updateSessionToken, getSessionToken } from '../session';
import { ServiceTypeSettingsArray } from './serviceTypeSettingsArray';
import { AppConfiguration } from '../configuration';

export const loadServiceSettings = async (hacClientServiceCode) => {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  const apiClient = new ApiClient(apiRootUrl, sessionToken);
  const apiResult = await apiClient.get(`serviceconfiguration/${hacClientServiceCode}`);
  if (apiResult.data.SessionToken) {
    updateSessionToken(apiResult.data.sessionToken);
  }
  return new ServiceTypeSettingsArray(apiResult.data.result);
};
