import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { HeaderTest } from './headerTest.jsx';
// import { EarnPointsTest } from './earnPointsTest.jsx';
import { CombinedTabTest } from './combinedTab.jsx';

//import { PointsEarnedTest } from './pointsEarnedTest.jsx';
import { ProgramSummaryTest } from './programSummaryTest';

export const MyPointsUtView = () => {
  const [currentScenario, setScenario] = useState('ScenarioOne');
  return (
    <>
      <div className="incentivesContainer">
        <HeaderTest currentScenario={currentScenario} setScenario={setScenario} />
        <Tabs defaultActiveKey="activities" id="uncontrolled-tab-example" transition={false}>
          <Tab eventKey="activities" title="Activities">
            {/* <EarnPointsTest currentScenario={currentScenario} /> */}
            <CombinedTabTest currentScenario={currentScenario} />
          </Tab>
          {/* <Tab eventKey="pointsEarned" title="Completed Activities">
            <PointsEarnedTest currentScenario={currentScenario} />
          </Tab> */}
          <Tab eventKey="programSummary" title="Program Summary">
            <ProgramSummaryTest currentScenario={currentScenario} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
