import { ApiVersion } from './apiVersion';
import { MessageTypes } from './messageTypes';
export class LoginMessage {
  constructor(guid, authenticationKey, requestTranscript, intrinsics) {
    this.apiVersion = ApiVersion;
    this.type = MessageTypes.request;
    this.body = {
      method: 'requestChat',
      guid: guid,
      authenticationKey: authenticationKey,
      requestTranscript: requestTranscript,
      intrinsics: intrinsics
    };
  }
}
