import * as types from './actionTypes';

const initialState = {
  member: null,
  loading: true,
  error: false
};

export const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_MEMBER_SUCCESS:
      return {
        ...state,
        member: action.member,
        loading: false,
        error: false
      };
    case types.LOAD_MEMBER_FAILED:
      return {
        ...state,
        member: null,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
