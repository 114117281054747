import React, { useState } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { getAntiForgeryToken } from '../../antiforgery';
import { getAppUrl, AppConfiguration } from '../../configuration';
import { ToolTip } from './tooltip';
import moment from 'moment';
import './section.scss';

export const Section = (props) => {
  const [show, setShow] = useState(false);
  const [url, SetUrl] = useState('');
  var sectionBlock = props.rules.map((item) => {
    const postUrl = `${AppConfiguration.getSiteRootUrl()}${'Home'}/${'TobaccoAffidavit'}`;
    const tobaccoAffidavitUrl = `${AppConfiguration.getSiteRootUrl()}${'Wellness'}/${'Secure/TobaccoOath/Default.aspx'}`;
    const verificationTokenName = '__RequestVerificationToken';
    const token = getAntiForgeryToken();

    const sectionButton =
      item.buttonName != '' ? (
        <a href={getAppUrl(item.buttonUrl)} className="btn btn-primary">
          {item.buttonName}
        </a>
      ) : (
        ''
      );
    let form = null;

    return (
      <Row key={item.incentiveruleId}>
        <Col md={6}>
          {item.description != '' ? (
            <span className={item.required ? 'requiredActivity' : ''}>
              <ToolTip tooltipContent={item.description} tooltipTriggerContent={item.name} />
            </span>
          ) : (
            <span className={item.required ? 'requiredActivity' : ''}>{item.name}</span>
          )}
          <br />
          {item.dueDate != null ? 'Deadline: ' + moment(item.dueDate).format('MM/DD/YYYY') : null}
        </Col>
        <Col md={3} className="pointsColumn">
          {item.pointValue < 1 && item.maxPoints < 1 ? 'Incomplete' : item.pointValue}
          {item.pointValue < 1 && item.maxPoints < 1 ? '' : <span className="mobileHelper">Points</span>}
          {item.pointValue < 1 && item.maxPoints < 1 ? '' : <span className="maxPts">Max of {item.maxPoints}</span>}
        </Col>
        <Col>
          {item.buttonUrl.toLowerCase().includes('tobaccoaffidavit') ? (
            <form
              action={postUrl}
              id={item.incentiveruleId}
              method="post"
              ref={(ref) => {
                form = ref;
              }}>
              <input id="href" name="Href" type="hidden" value={tobaccoAffidavitUrl} />
              <input name={verificationTokenName} type="hidden" value={token || ''} />
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  form.submit();
                }}
                href=""
                className="btn btn-primary">
                {item.buttonName}
              </a>
            </form>
          ) : item.buttonType === 'timeTrade' ? (
            <>
              <Button
                className="btn btn-primary"
                onClick={() => {
                  setShow(true);
                  SetUrl(item.buttonUrl);
                }}>
                {item.buttonName.toLowerCase().includes('upcoming appointment')
                  ? 'Upcoming Appointment: ' + moment(item.buttonName).format('l LT')
                  : item.buttonName}
              </Button>
            </>
          ) : (
            sectionButton
          )}
        </Col>
      </Row>
    );
  });
  return (
    <>
      {sectionBlock}
      {/* Modal */}

      {!url ? (
        ''
      ) : (
        <Modal
          show={show}
          animation={true}
          dialogClassName="mypoints-modal-dialog"
          onHide={() => {
            setShow(false);
            location.reload();
          }}>
          <Modal.Header className="mypoints-modal-header rounded-circle" closeButton></Modal.Header>
          <Modal.Body className="mypoints-modal-body">
            <iframe
              src={url}
              width="762"
              height="583"
              className="timetrade-iframe"
              title="Click to Schedule Workflow by TimeTrade"
              allow="geolocation"></iframe>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
