import * as types from './actionTypes';

const initialState = {
  agentsAvailable: false,
  inChatTimeWindow: false,
  agentTyping: false,
  chatInProgress: false,
  showWaiting: true,
  serviceSettings: null,
  topics: [],
  queues: [],
  loading: true,
  chatVisible: false,
  error: false,
  messages: [],
  participants: [],
  estimatedWaitTime: 0
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_CHAT_SUCCESS:
      return {
        ...state,
        serviceSettings: action.serviceSettings,
        topics: action.topics,
        queues: action.queues,
        loading: false,
        error: false
      };
    case types.LOAD_CHAT_FAILED:
      return {
        ...state,
        serviceSettings: null,
        topics: [],
        queues: [],
        loading: false,
        error: true
      };
    case types.SHOW_MENU_CHAT:
      return { ...state, menuChatVisible: action.visible };
    case types.SHOW_WELLNESS_CHAT:
      return { ...state, wellnessChatVisible: action.visible };
    case types.HIDE_CHAT:
      return {
        ...state,
        wellnessChatVisible: action.visible,
        menuChatVisible: action.visible
      };
    case types.LOAD_HOLD_MESSAGES:
      return {
        ...state,
        holdMessages: action.holdMessages,
        holdMessageDelay: action.holdMessageDelay
      };
    case types.APPEND_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.message]
      };
    case types.UPDATE_PARTICIPANTS:
      return {
        ...state,
        participants: action.participants,
        chatInProgress: action.chatInProgress,
        showWaiting: false
      };
    case types.UPDATE_AGENT_IS_TYPING:
      return {
        ...state,
        agentTyping: action.agentTyping
      };
    case types.UPDATE_ESTIMATED_WAIT_TIME:
      return {
        ...state,
        estimatedWaitTime: action.estimatedWaitTime
      };
    case types.UPDATE_AGENTS_AVAILABLE:
      return {
        ...state,
        agentsAvailable: action.data.agentsAvailable,
        inChatTimeWindow: action.data.inChatTimeWindow
      };
    case types.RESET_CHAT_SESSION:
      return {
        ...state,
        messages: [],
        showWaiting: true
      };
    default:
      return state;
  }
};
