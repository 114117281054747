import { combineReducers } from 'redux';
import { todoReducer as todos } from '../todos';
import { sectionCardReducer as sectionCards } from '../sectioncards';
import { chatReducer as chat } from '../chat';
import { memberReducer as members } from '../members';
import { organizationReducer as organizations } from '../organizations';
import { clientReducer as clients } from '../clients';
import { analyticsReducer as analytics } from '../analytics';
import { faxHistoryReducer as faxHistory } from '../faxes';
import { faxRecipientsReducer as faxRecipients } from '../faxes';
import { biometricsReducer as biometrics } from '../biometrics';
import { biometricsReportReducer as biometricsReport } from '../faxes';
import { healthNavigatorReducer as healthNavigator } from '../healthnavigator';
import { clientServicesReducer as clientServices } from '../services';
import { organizationConfigurationsReducer as organizationConfigurations } from '../organizationConfigurations';
import { contentExclusionsReducer as contentExclusions } from '../contentExclusions';
import {
  incentivesReducer as incentives,
  incentiveRulesReducer as incentiveRules,
  incentiveProgramsReducer as incentivePrograms,
  buttonLinksReducer as incentiveButtonLinks,
  linkedRulesReducer as incentiveLinkedRules,
  sectionsReducer as sections,
  tierDetailsReducer as tierDetails,
  memberScoresReducer as memberIncentiveScores,
  memberScoreDetailsReducer as memberScoreDetails,
  memberTierDetailsReducer as memberTierDetails,
  documentLinksReducer as documentLinks
} from '../incentives';
import { geocodeReducer as geocode } from '../shared/geocoding';
import { memberAttestationReducer as memberAttestations } from '../members';

const rootReducer = combineReducers({
  incentives,
  sections,
  incentivePrograms,
  incentiveRules,
  tierDetails,
  incentiveButtonLinks,
  incentiveLinkedRules,
  memberIncentiveScores,
  memberScoreDetails,
  memberTierDetails,
  documentLinks,
  sectionCards,
  todos,
  chat,
  members,
  organizations,
  organizationConfigurations,
  clients,
  analytics,
  faxHistory,
  biometrics,
  faxRecipients,
  biometricsReport,
  healthNavigator,
  clientServices,
  contentExclusions,
  geocode,
  memberAttestations
});

export default rootReducer;
