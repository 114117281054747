import { ApiClient } from '../api';
import { AppConfiguration } from '../configuration';
import { getSessionToken } from '../session';
import * as types from './actionTypes.js';

export function loadOrganization(logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return dispatch => {
    return new Promise(async resolve => {
      try {
        const client = new ApiClient(apiRootUrl, sessionToken);
        const result = await client.get('organization');
        const organization = convertResult(result.data.Result);
        resolve(dispatch(loadOrganizationSuccess(organization)));
      } catch (error) {
        logError('organization/actions', 'loadOrganization', error);
        resolve(dispatch(loadOrganizationFailed()));
      }
    });
  };
}

const convertResult = result => {
  const organization = {
    active: result.Active,
    billingGroupName: result.BillingGroupName,
    crmReportGroupCode: result.CRMReportGroupCode,
    campaignId: result.CampaignID,
    documentOrganizationId: result.DocumentOrganizationId,
    effectiveDate: result.EffectiveDate,
    memberPhoneNumber: result.MemberPhoneNumber,
    organizationId: result.OrganizationId,
    organizationName: result.OrganizationName,
    organizationTypeCode: result.OrganizationTypeCode,
    parentOrganizationId: result.ParentOrganizationId,
    searchNotes: result.SearchNotes,
    webSite: result.WebSite
  };
  return organization;
};

export const loadOrganizationSuccess = organization => {
  return {
    type: types.LOAD_ORGANIZATION_SUCCESS,
    organization: organization
  };
};

export const loadOrganizationFailed = () => {
  return { type: types.LOAD_ORGANIZATION_FAILED };
};
