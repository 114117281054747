import React from 'react';
import { MdHome, MdBusinessCenter } from 'react-icons/md';
import { FaAmbulance } from 'react-icons/fa';

export const attestationText =
  'I attest that I completed the CDC Self-Checker to the best of my knowledge based on my current health status.';
export const shortenedAttestationText =
  'I attest that I have answered the questionnaire to the best of my knowledge based on my current health status.';
export const introText = (
  <>
    <p style={{ marginBottom: '10px' }}>
      Complete the below questionnaire to determine if you are Cleared to Return to the workplace.
    </p>
  </>
);
export const hideTemperatureIntroText = (
  <>
    <p style={{ marginBottom: '10px' }}>
      Complete the below questionnaire to determine if you are Cleared to Return to the workplace.
    </p>
  </>
);
export const shortenedQuestionnaireIntroText = (
  <>
    <p style={{ marginBottom: '20px' }}>
      The CDC's Coronavirus Self-Checker is experiencing technical difficulties. Complete the shortened questionnaire
      below to determine if you are cleared to return to the workplace.
    </p>
    <p style={{ marginBottom: '20px' }}>
      <i>If you think you are experiencing a medical emergency, please call 911 immediately.</i>
    </p>
  </>
);
export const btnText =
  'Almost done! Select the status below that describes your result from the questionnaire. If you’re not sure what to choose, click “help me decide” below.';

export const shortenedBtnText =
  'Almost done! If you answered no to all the questions, select “Cleared”. If you answered yes to any of the questions, and/or have a fever, you are “Not cleared” to return to work';

export const shortenedBtnTextWithoutTemperature =
  'Almost done! If you answered no to all the questions, select “Cleared”. If you answered yes to any of the questions, you are “Not cleared” to return to work';

export const clearedHeader = (
  <>
    <div className="h-status">
      <span className="h-work">
        <MdBusinessCenter className="cdc-icon" />
      </span>
    </div>
  </>
);

export const notClearedHeader = (
  <>
    <div className="h-status h-home-bg">
      <span className="h-home">
        <MdHome className="cdc-icon" />
      </span>
    </div>
  </>
);
export const seekCareHeader = (
  <>
    <div className="h-status h-er-bg">
      <span className="h-er">
        <FaAmbulance className="cdc-icon" />
      </span>
    </div>
  </>
);
export const clearedBodyText = (
  <>
    <h4 className="certificateHeader">Cleared to Return to Work</h4>
    <p>
      You are cleared to return to work today. Each time you badge into or otherwise enter the company’s facilities, you
      will be confirming that you received a Cleared to Return to Work Pass for that day.
    </p>
    <p>Your employer will automatically be sent your results.</p>
    <p>
      <strong>
        If you have any questions, contact your Human Resources department or Health Advocate by selecting the telephone
        icon at the bottom of the screen.
      </strong>
    </p>
  </>
);

export const notClearedBodyText = (
  <>
    <h4 className="certificateHeader">NOT cleared to Return to Work</h4>
    <p>
      If you receive a NOT cleared to Return to Work Pass either now or at any time in the future, you must notify your
      manager, company contact, HR Partner, or follow your company’s Coronavirus process as soon as possible. Depending
      on your specific responses, you may be required to remain out of the company’s facilities and work from home, if
      applicable, for a time period.
    </p>
    <p>
      Please note: Each time you badge into or otherwise enter the company’s facilities, you will be confirming that you
      received a Cleared to Return to Work Pass for that day.
    </p>
    <p>Your employer will automatically be sent your results. </p>
    <p>
      <strong>
        If you have any questions, contact your Human Resources department or Health Advocate by selecting the telephone
        icon at the bottom of the screen.
      </strong>
    </p>
  </>
);

export const seekCareBodyText = (
  <>
    <h4 className="certificateHeader">Seek Emergency Care</h4>
    <p>
      <strong>Urgent medical attention may be needed. Please call 911 or go to the Emergency Department.</strong> Based
      on your symptoms, urgent medical attention may be needed. Please call 911 or go to the nearest emergency
      department. Tell the 911 operator or emergency staff if you have had contact with someone with COVID-19 or if you
      have recently been to an area where COVID-19 is spreading.
    </p>
  </>
);
