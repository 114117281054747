import React, { Component } from 'react';
import './materialSpinner.scss';

export class MaterialSpinner extends Component {
  static defaultProps = {
    width: '65px',
    height: '65px'
  };
  render() {
    return this.props.show ? (
      <svg
        className="spinner"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          className="path"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    ) : null;
  }
}
