import React from 'react';
//import  { useState, useRef } from 'react';
import { Tooltip, Button, OverlayTrigger } from 'react-bootstrap';

export const ToolTip = props => {
  //   const [show, setShow] = useState(false);
  //   const target = useRef(null);
  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{props.tooltipContent}</Tooltip>}>
        <Button variant="link" className="tooltipTrigger">
          {props.tooltipTriggerContent}
        </Button>
      </OverlayTrigger>
    </>
  );
};
