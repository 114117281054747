import React from 'react';

export const ContactUsMessage = (props) => {
  if (props.contactUrl) {
    return (
      <p>
        If you do not want to wait,
        <br />
        please <a href={props.contactUrl}>click here</a> to leave us a message.
      </p>
    );
  }
  return null;
};
