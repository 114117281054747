export const loadingSelector = (state) => {
  return (
    state.incentivePrograms.loading ||
    state.incentiveRules.loading ||
    state.incentiveButtonLinks.loading ||
    state.incentiveLinkedRules.loading ||
    state.sections.loading ||
    state.memberIncentiveScores.loading
  );
};
