import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppConfiguration } from '../../configuration';
import { getTopRequiredTodos } from '../../todos/selectors';
import { PointsMeter } from './pointsMeter';
import { RequiredToDoList } from '../../todos/requiredToDoList';
import { ProgramDetails } from './programDetails';
import './summaryPanel.scss';
import { MaterialSpinner, reinitEqualizer } from '../../shared';
import { StatusPane } from './statusPane';
import { RewardsProgramSummary } from '../../rewards/rewardsProgramSummary';
import { ProgramStatusType } from './programStatusType';

class SummaryPanel extends Component {
  static defaultProps = {
    loading: true,
    error: false,
    details: new ProgramDetails(),
    todo: {
      todos: [],
      error: false
    }
  };

  componentDidMount() {
    reinitEqualizer();
  }

  componentDidUpdate() {
    reinitEqualizer();
  }

  render() {
    if (this.props.loading) {
      return this.getLoading();
    }
    if (this.props.error) {
      return this.getError();
    }
    if (this.props.details.isRewardProgram) {
      return this.getRewardsView();
    }
    return this.getMeterView();
  }

  getMeterView() {
    return (
      <>
        <div className="linked card shadow" data-equalizer-watch>
          <div className="card-divider wellbeing incentiveWidg">
            <h3 className="my-points-title">My Points</h3>
          </div>
          <div className="summary-panel">
            <PointsMeter
              tierPoints={this.props.details.tierPoints}
              currentPoints={this.props.details.earnedPoints}
              currentTier={this.props.details.currentTier}
              status={this.props.details.status}
            />
            <StatusPane
              loading={this.props.loading}
              status={this.props.details.status}
              currentTier={this.props.details.currentTier}
              pointsToNextTier={this.props.details.pointsToNextTier}
              hasRequiredTodos={this.props.todo.todos.length > 0}
              oneTierProgram={this.props.details.tierPoints.length <= 1}
            />
            <p className="disclaimer-text">Scores may take up to 24 hours to reflect recent activity</p>
            <div className="required-todos">
              <RequiredToDoList
                todos={this.props.todo.todos}
                loading={this.props.todo.loading}
                error={this.props.todo.error}
              />
            </div>
            <a className="button hollow" href={this.getMyPointsLink()}>
              {this.getPointsButtonText()}
            </a>
          </div>
        </div>
      </>
    );
  }

  getRewardsView() {
    return (
      <RewardsProgramSummary
        rewardBalance={this.props.details.rewardBalance}
        earnedPoints={this.props.details.earnedPoints}
        rewardLevel={this.props.details.rewardLevel}
        rewardsUrl={this.props.details.rewardsUrl}
        canBrowseRewards={this.props.details.canBrowseRewards}
        canRedeemRewards={this.props.details.canRedeemRewards}
        incentiveProgramOrganizationId={this.props.details.incentiveProgramOrganizationId}
      />
    );
  }

  getError() {
    return (
      <div className="error-container">
        <span>
          Sorry, but an error occurred.
          <br />
          Technical support has been notified.
        </span>
      </div>
    );
  }

  getLoading() {
    return (
      <div className="spin-container">
        <MaterialSpinner show={true} width={'100px'} height={'100px'} />
      </div>
    );
  }

  getMyPointsLink() {
    return `${AppConfiguration.getSiteRootUrl()}Wellbeing/MyPoints`;
  }

  getPointsButtonText() {
    const pointsDetail = 'Points Detail';
    const getStarted = 'Get Started';
    const earnMore = 'Earn More';
    if (this.props.details.status === ProgramStatusType.Complete) {
      return earnMore;
    } else {
      return this.props.details.earnedPoints > 0 ? pointsDetail : getStarted;
    }
  }
}

const topToDoCount = 2;

let mapDispatchToProps = (state) => {
  return {
    ...state.incentives,
    ...state.organizations,
    todo: {
      ...state.todos,
      todos: getTopRequiredTodos(state.todos, topToDoCount)
    }
  };
};

export default connect(mapDispatchToProps)(SummaryPanel);
