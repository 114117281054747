import React from 'react';
import { getAppUrl, getAppTarget } from '../../configuration';
import { Col, Container, Row, ListGroup, Spinner } from 'react-bootstrap';
import { MdChevronRight } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { loadingSelector } from './loadingSelector';
import TrackerIcon from './images/trackerIconNew.png';
import WorkshopIcon from './images/workshopIconNew.png';
import { AppConfiguration } from '../../configuration';
import CustomChallengeIcon from './images/customChalGeneric.png';
import PointsCompletedBkg from './images/pointsCompletedBkg.jpg';
import './pointsCompleted.scss';

const trackYourProgressUrl = `${AppConfiguration.getSiteRootUrl()}Wellbeing/ChallengeTrackers`;
const participateInWorkshopUrl = `${AppConfiguration.getSiteRootUrl()}Wellbeing/Workshops`;
const challengeYourselfUrl = `${AppConfiguration.getSiteRootUrl()}Wellbeing/Challenges`;

export const PointsCompleted = (props) => {
  return (
    <div className="points-completed">
      <Container>
        <Container>
          <Row>
            <Col md={5}>
              {props.noProgramAvailable ? (
                <p>
                  You don't have an active program right now, but don't let that stop you from taking control of your
                  health. Check out these well-being activities:
                </p>
              ) : (
                <p>
                  You’ve earned all the points available in your program. Great job! Click “Points Earned” to see what
                  you’ve completed. You can continue to meet your health goals by exploring other areas of our website:
                </p>
              )}
              <ListGroup>
                <ListGroup.Item>
                  <div className="list-image">
                    <img src={TrackerIcon} />
                  </div>
                  <div className="list-text">
                    <h4>Track Your Progress</h4>
                    <p>Add trackers to your dashboard and watch your trends as you work towards your goals.</p>
                    <a href={getAppUrl(trackYourProgressUrl)} alt="Track your progress">
                      Learn More
                      <span>
                        <MdChevronRight size="1em" />
                      </span>
                    </a>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="list-image">
                    <img src={WorkshopIcon} />
                  </div>
                  <div className="list-text">
                    <h4>Participate in a Workshop</h4>
                    <p>Learn about stress management, mindfulness, fitness, and more!</p>
                    <a href={getAppUrl(participateInWorkshopUrl)} alt="Participate in a Workshop">
                      Learn More
                      <span>
                        <MdChevronRight size="1em" />
                      </span>
                    </a>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="list-image">
                    <img src={CustomChallengeIcon} />
                  </div>
                  <div className="list-text">
                    <h4>Challenge Yourself</h4>
                    <p>Think you can get more steps then your colleagues? Invite them to a challenge!</p>
                    <a href={getAppUrl(challengeYourselfUrl)} alt="Challenge Yourself">
                      Learn More
                      <span>
                        <MdChevronRight size="1em" />
                      </span>
                    </a>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={7}>
              <div className="image-right">
                <div className="points-completed-bg">
                  <img src={PointsCompletedBkg} alt="points completed background" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};
