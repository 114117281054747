import { ApiClient } from '../api';
import uaparser from 'ua-parser-js';

const parseServerName = url => {
  let serverName = '';
  if (url && url.length > 0) {
    url = url.replace('http://', '');
    url = url.replace('https://', '');
    var parts = url.split('/');
    serverName = parts[0];
  }
  return serverName;
};

const postLogEntry = (
  apiRootUrl,
  sessionToken,
  browser,
  applicationName,
  logLevel,
  className,
  functionName,
  message
) => {
  var client = new ApiClient(apiRootUrl, sessionToken);
  var serverName = parseServerName(apiRootUrl);
  var logEntry = {
    LogLevel: logLevel,
    ServerName: serverName,
    BrowerVersion: browser,
    ApplicationName: applicationName,
    Class: className,
    Function: functionName,
    ErrorMessage: message
  };
  client.post('log', logEntry);
  return logEntry;
};

export class LogService {
  constructor(apiRootUrl, sessionToken, applicationName) {
    this.apiRootUrl = apiRootUrl;
    this.sessionToken = sessionToken;
    this.applicationName = applicationName;
    this.browser = '';
    if (navigator) {
      var parser = new uaparser.UAParser();
      var browserType = parser.getResult();
      this.browser = `${browserType.browser.name} ${browserType.browser.version}`;
    }
  }

  error(className, functionName, error) {
    const logLevel = 'LOG_Error';
    postLogEntry(
      this.apiRootUrl,
      this.sessionToken,
      this.browser,
      this.applicationName,
      logLevel,
      className,
      functionName,
      error
    );
  }

  warn(className, functionName, message) {
    const logLevel = 'LOG_Warn';
    postLogEntry(
      this.apiRootUrl,
      this.sessionToken,
      this.browser,
      this.applicationName,
      logLevel,
      className,
      functionName,
      message
    );
  }

  debug(className, functionName, message) {
    const logLevel = 'LOG_Debug';
    postLogEntry(
      this.apiRootUrl,
      this.sessionToken,
      this.browser,
      this.applicationName,
      logLevel,
      className,
      functionName,
      message
    );
  }

  info(className, functionName, message) {
    const logLevel = 'LOG_Info';
    postLogEntry(
      this.apiRootUrl,
      this.sessionToken,
      this.browser,
      this.applicationName,
      logLevel,
      className,
      functionName,
      message
    );
  }
}
