import React from 'react';
import Modal from 'react-modal';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-47%',
    width: 'auto',
    maxWidth: '33rem',
    transform: 'translate(-50%, -50%)',
    borderRadius: '2px',
    border: '1px solid #948E94'
  }
};

export const SelfCheckerTemperatureModal = (props) => {
  let subtitle;
  return (
    <Modal
      isOpen={props.modalIsOpen}
      overlayClassName="cert-overlay"
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      onAfterOpen={(e) => {}}>
      <div ref={(_subtitle) => (subtitle = _subtitle)} className="modal-content">
        <div className="modal-header noprint" style={{ paddingBottom: '0px' }}>
          <div className="columns small-12 large-12 modal-title noprint" style={{ paddingLeft: '0px' }}>
            <div className="columns small-9 large-9 no-padding">
              <p style={{ fontSize: '18px', fontWeight: '500' }}>Before taking your temperature</p>
              {subtitle}
            </div>
            <div className="columns small-3 large-3 no-padding">
              <button onClick={props.certClicked} type="button" className="close">
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
          </div>
        </div>
        <div className="modal-body" style={{ display: 'block', color: '#000 !important' }}>
          <p style={{ fontSize: '16px' }}>
            Wait 30 minutes after eating, drinking, or exercising, and Wait at least 6 hours after taking medicines that
            can lower your temperature, like: Acetaminophen, Ibuprofen, or aspirin.
          </p>
          <p style={{ fontSize: '18px', fontWeight: '500' }}>How to Take Your Temperature</p>
          <div className="temperature-steps">
            <ol>
              <li>Turn on the thermometer.</li>
              <li>Hold the top of the thermometer under your tongue until it beeps. Do not bite the thermometer. </li>
              <li>
                Read your temperature on the screen. If your temperature is 100.4°F/38°C or higher, you have a fever.
              </li>
              <li>Record your temperature. </li>
              <li>Clean your thermometer with soap and water and dry it well.</li>
            </ol>
          </div>
        </div>
      </div>
    </Modal>
  );
};
