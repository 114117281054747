import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { logError } from '../../logging';
import { Header } from './header';
import { SignInForm } from '../signin';
import { ErrorComponent } from '../error';
import { TopicMenu } from '../topics';
import { LoadingComponent } from './loading';
import ChatComponent from '../chatComponent';
import { ChatUser } from '../chatUser';
import './chatPopUp.scss';

export class ChatPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatUser: null,
      selectedTopic: null,
      error: false,
      connectivityError: false
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  // Note: modern browsers will not display this message at all, rather just the default as its been considered
  // as a security risk to allow for custom text
  handleBeforeUnload = (e) => {
    const message = `If you leave this page your chat session will be closed. Do you want to end the session?`;
    if (e) {
      // For very old browsers
      e.returnValue = message;
    }
    return message;
  };

  componentDidCatch(error) {
    logError('chatPopUp', 'componentDidCatch', error);
    this.setState({ error: true });
  }

  closeButtonClicked = (e) => {
    e.preventDefault();
    if (this.props.eventEmitter) {
      this.props.eventEmitter.emit('ChatPopUp.Close');
    }
  };

  topicSelected = (topic) => {
    this.setState({ selectedTopic: topic });
  };

  // If a member is provided from props transform to chat user, else one was provided by login form
  getUser() {
    let user = null;
    if (this.props.client.requiresLogin) {
      user = ChatUser.create(this.props.member, this.props.client.name, this.props.organization.organizationId);
    } else {
      user = this.state.chatUser;
      user.company = this.props.client.name;
      user.userName = ChatUser.getUserName(user.firstName, user.lastName);
    }
    return user;
  }

  setUserFromLoginScreen(userForm) {
    const user = new ChatUser();
    user.firstName = userForm.firstName;
    user.lastName = userForm.lastName;
    user.userName = '';
    user.company = userForm.company;
    user.email = userForm.email;
    user.phone = userForm.phone;
    this.setState({ chatUser: user });
  }

  setConnectivityError(error) {
    this.setState({ connectivityError: error });
  }

  render() {
    let content = null;
    if (this.props.error || this.state.error) {
      content = <ErrorComponent />;
    } else if (this.props.loading) {
      content = <LoadingComponent />;
    } else if (!this.state.selectedTopic && !this.props.queue) {
      content = (
        <TopicMenu loading={this.props.loading} topics={this.props.topics} topicSelected={this.topicSelected} />
      );
    } else if (!this.props.client.requiresLogin && !this.state.chatUser) {
      content = (
        <SignInForm
          description={this.props.description}
          company={this.props.client.name}
          setUser={this.setUserFromLoginScreen.bind(this)}
        />
      );
    } else {
      const user = this.getUser();
      const queue = this.getQueue();
      const topicText = this.state.selectedTopic ? this.state.selectedTopic.text : '';
      content = (
        <ChatComponent
          user={user}
          eventEmitter={this.props.eventEmitter}
          queue={queue}
          topicText={topicText}
          contactUrl={this.props.contactUrl}
          contactPhoneNumber={this.props.client.phoneNumber}
          setConnectivityError={this.setConnectivityError.bind(this)}
        />
      );
    }
    return (
      <div className="chat-pop-up">
        <Header
          title={this.props.title}
          closeButtonClicked={this.closeButtonClicked}
          connectivityError={this.state.connectivityError}
        />
        {content}
      </div>
    );
  }

  // Queue is either passed in or determine from topic selection
  getQueue() {
    let queue = '';
    if (this.props.queue) {
      queue = this.props.queue;
    } else if (this.state.selectedTopic) {
      queue = this.state.selectedTopic.queue;
    }
    return queue;
  }
}

ChatPopUp.propTypes = {
  loading: PropTypes.bool.isRequired,
  topics: PropTypes.array.isRequired
};
