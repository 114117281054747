import * as types from './actionTypes';

const initialState = {
  cards: [],
  eapWorklifeRootUrl: '',
  eapBrandingName: '',
  eapSectionCardDescription: '',
  loading: true,
  error: false
};

export function sectionCardReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_SECTION_CARDS_SUCCESS:
      return {
        ...state,
        cards: action.cards,
        eapWorklifeRootUrl: action.eapWorklifeRootUrl,
        eapBrandingName: action.eapBrandingName,
        eapSectionCardDescription: action.eapSectionCardDescription,
        loading: false,
        error: false
      };
    case types.LOAD_SECTION_CARDS_FAILED:
      return {
        ...state,
        cards: null,
        eapWorklifeRootUrl: null,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
