import React from 'react';
import { Row, Column } from 'react-foundation';
import { AppConfiguration } from '../../configuration';
import './errorComponent.scss';

export const ErrorComponent = () => {
  const imageUrl = AppConfiguration.getImagePath('img_errorScreen.png');
  return (
    <div className="error-screen">
      <Row>
        <Column>
          <p>Oops! Something's not right...</p>
          <img src={imageUrl} alt="error" />
          <div>
            We are currently experiencing technical difficulties.
            <br />
            If this continues, please contact support.
          </div>
        </Column>
      </Row>
    </div>
  );
};
