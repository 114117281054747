import * as types from './actionTypes';

export const providerSelectedNetwork = (networkID) => {
  return (dispatch) => {
    dispatch(providerSelectedNetworkSuccess(networkID));
  };
};

const providerSelectedNetworkSuccess = (networkID) => {
  return {
    type: types.PROVIDER_NETWORK_SUCCESS,
    providerNetwork: networkID
  };
};
