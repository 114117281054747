import React from 'react';
import { MaterialSpinner } from './materialSpinner';
import './materialSpinnerPanel.scss';

export const MaterialSpinnerPanel = props => {
  return (
    <div className="material-spinner-panel">
      <MaterialSpinner
        show={props.show}
        width={props.width}
        height={props.height}
      />
    </div>
  );
};
