import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { MyPoints } from './myPoints';
import { loadCurrentProgram } from './actions';

export const loadMyPointsComponent = (store, logError) => {
  const myPointsElement = document.getElementById('myPointsComponent');
  if (myPointsElement) {
    store.dispatch(loadCurrentProgram(logError));
    ReactDOM.render(
      <Provider store={store}>
        <MyPoints />
      </Provider>,
      myPointsElement
    );
  }
};
