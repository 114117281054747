import React from 'react';
import { CardGroup } from 'react-bootstrap';
import WellnessLogo from './../images/wellnessLogo.png';
import { IncentiveWidgetTest } from './incentiveWidgetTest.jsx';
import { LevelCardTest } from './levelCardTest';
import { ProgramInfoCardTest } from './programInfoCardTest';

export const HeaderTest = (props) => {
  return (
    <div className="headerContainer">
      {props.currentScenario === 'ScenarioOne' ? (
        <h1>
          <img src={WellnessLogo} alt="logo" className="wellnessLogo" />
          Wellness Matters 2020
        </h1>
      ) : null}
      {props.currentScenario === 'ScenarioTwo' ? (
        <h1>
          <img src={WellnessLogo} alt="logo" className="wellnessLogo" />
          Wellness Matters 2020
        </h1>
      ) : null}
      {props.currentScenario === 'ScenarioThree' ? (
        <h1>
          <img src={WellnessLogo} alt="logo" className="wellnessLogo" />
          Wellness Matters 2019
        </h1>
      ) : null}
      <div className="cardGroupContainer">
        <div className="circleBack"></div>
        {props.currentScenario === 'ScenarioOne' ? <IncentiveWidgetTest pointsEarnedTest="270" /> : null}
        {props.currentScenario === 'ScenarioTwo' ? <IncentiveWidgetTest pointsEarnedTest="200" /> : null}
        {props.currentScenario === 'ScenarioThree' ? <IncentiveWidgetTest pointsEarnedTest="300" /> : null}
        <CardGroup>
          {props.currentScenario === 'ScenarioOne' ? <LevelCardTest currLevel="Level 2" ptsToNextLevel="170" /> : null}
          {/* {props.currentScenario === 'ScenarioTwo' ? (
            <LevelCardTest currLevel="Level 2" ptsToNextLevel="100" />
          ) : null}
          {props.currentScenario === 'ScenarioThree' ? (
            <LevelCardTest currLevel="Level 3" ptsToNextLevel="150" />
          ) : null} */}
          {/* Program Info Card */}
          {props.currentScenario === 'ScenarioOne' ? (
            <ProgramInfoCardTest
              programDates="January 1, 2020 - December 31, 2020"
              setScenario={props.setScenario}
              programDropdownText="Wellness Matters 2019"
              whichScenario="ScenarioThree"
            />
          ) : null}
          {props.currentScenario === 'ScenarioTwo' ? (
            <ProgramInfoCardTest
              programDates="January 1, 2020 - December 31, 2020"
              setScenario={props.setScenario}
              programDropdownText="Wellness Matters 2019"
              whichScenario="ScenarioThree"
            />
          ) : null}
          {props.currentScenario === 'ScenarioThree' ? (
            <ProgramInfoCardTest
              programDates="January 1, 2019 - December 31, 2019"
              setScenario={props.setScenario}
              programDropdownText="Wellness Matters 2020"
              whichScenario="ScenarioOne"
            />
          ) : null}
        </CardGroup>
        {/* <div>It is: {props.currentScenario}</div>
        {props.currentScenario === 'ScenarioOne' ? <div>ONE HEAD</div> : null}
        {props.currentScenario === 'ScenarioTwo' ? <div>TWO HEAD</div> : null}
        {props.currentScenario === 'ScenarioThree' ? (
          <div>THREE HEAD</div>
        ) : null} */}
      </div>
    </div>
  );
};
