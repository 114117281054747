import moment from 'moment';
import * as types from './actionTypes.js';
import { AppConfiguration } from '../configuration';
import { updateSessionToken, getSessionToken } from '../session';
import { ApiClient } from '../api';

export function loadBiometrics(logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const apiClient = new ApiClient(apiRootUrl, sessionToken);
      try {
        const apiResult = await apiClient.get('wellness/getmemberbiometrics');
        if (apiResult.data.SessionToken) {
          updateSessionToken(apiResult.data.SessionToken);
        }
        if (apiResult.data && apiResult.data.Result) {
          const result = convertResult(apiResult.data.Result);
          resolve(dispatch(loadBiometricsSuccess(result)));
        } else {
          resolve(dispatch(loadBiometricsFailed()));
        }
      } catch (error) {
        logError('faxes/biometricsreport/actions', 'loadBiometrics', error);
        resolve(dispatch(loadBiometricsFailed()));
      }
    });
  };
}

export const loadBiometricsSuccess = (biometrics) => {
  return {
    type: types.LOAD_BIOMETRICS_SUCCESS,
    biometrics: biometrics
  };
};

const convertResult = (results) => {
  return !results
    ? []
    : results.map((x) => {
        return {
          id: x.ItemID,
          // Note formatting will transform to local tz, so 'dateKey' used as generation 'id'
          measurementDate: moment(x.MeasurementDate).format('MM/DD/YYYY'),
          dateKey: x.MeasurementDateKey,
          bmi: x.bmi,
          bloodGlucoseFasting: x.BloodGlucoseFasting,
          bloodGlucoseRandom: x.BloodGlucoseRandom,
          bodyFatPercentage: x.BodyFatPercentage,
          diastolic: x.Diastolic,
          hdl: x.HDL,
          hba1c: x.HbA1c,
          height: x.Height,
          ldl: x.Ldl,
          labReportOfRecord: x.LabReportOfRecord,
          labReportOfRecordId: x.LabReportOfRecordId,
          memberBiometricDataIdMostRecent: x.MemberBiometricDataIDMostRecent,
          nicotineMetaboliteSerumResult: x.NicotineMetaboliteSerumResult,
          psa: x.PSA,
          systolic: x.Systolic,
          tsh: x.TSH,
          totalCholesterol: x.TotalCholesterol,
          waist: x.Waist,
          weight: x.Weight,
          measurementSource: x.Source ? x.Source.join(', ') : [],
          sarsCov2NaaDiagnostic: x.SARSCoV2NAADiagnostic,
          sarsCov2Antibody: x.SARSCoV2Antibody
        };
      });
};

export const loadBiometricsFailed = () => {
  return { type: types.LOAD_BIOMETRICS_FAILED };
};
