import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';
import * as types from './actionTypes';

const loadSectionsSuccess = (programId, sections) => {
  return {
    type: types.LOAD_SECTIONS_SUCCESS,
    data: { programId, sections }
  };
};

const loadSectionsFailed = () => {
  return {
    type: types.LOAD_SECTIONS_FAILED
  };
};

export const loadSections = (programId, logError) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get(`incentiveprogram/${programId}/sections`);
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        resolve(dispatch(loadSectionsSuccess(programId, apiResult.data.result)));
      } catch (error) {
        logError('incentives/sections/actions', 'loadSections', error);
        resolve(dispatch(loadSectionsFailed()));
      }
    });
  };
};
