export const loadHeaderDetails = (tierDetails, memberTierDetails, activeProgram) => {
  const tiersByProgramId = tierDetails.byProgramId[activeProgram.incentiveProgramId];
  const memberTiersByIpoid = memberTierDetails.byIpoid[activeProgram.incentiveProgramOrganizationId];
  memberTiersByIpoid.sort((a, b) => (a.precedenceOrder > b.precedenceOrder ? 1 : -1));
  let highestTierAchieved = null;
  let numberOfLevels = memberTiersByIpoid.length;
  // Assign the last tier as current member tier (default)
  let memberTier = numberOfLevels >= 1 ? memberTiersByIpoid[numberOfLevels - 1] : null;
  let totalAchievedTiers = 0;

  for (var i = 0; i < numberOfLevels; i++) {
    if (memberTiersByIpoid[i].achieved) {
      highestTierAchieved = memberTiersByIpoid[i];
      totalAchievedTiers = totalAchievedTiers + 1;
    } else {
      memberTier = memberTiersByIpoid[i];
      break;
    }
  }
  let currentTier =
    memberTier !== null
      ? tiersByProgramId.filter((tier) => tier.incentiveTierId === memberTier.incentiveTierId)[0]
      : null;

  let highestTierAchievedMessage =
    highestTierAchieved !== null
      ? tiersByProgramId.filter((tier) => tier.incentiveTierId === highestTierAchieved.incentiveTierId)[0].achievedMsg
      : null;

  return {
    numberOfLevels: numberOfLevels,
    currentLevel: currentTier !== null ? currentTier.precedenceOrder : null,
    tierName: currentTier !== null ? currentTier.name : null,
    pointsToNextLevel: currentTier !== null ? currentTier.pointsForGoalMin : null,
    pointsEarned: memberTier !== null ? memberTier.earnedPoints : null,
    currentTierMessage: currentTier !== null ? currentTier.notAchievedMsg : null,
    highestTierAchievedMessage: highestTierAchievedMessage,
    totalAchievedTiers: totalAchievedTiers,
    allLevelsComplete: totalAchievedTiers === numberOfLevels
  };
};
