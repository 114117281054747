import * as types from './actionTypes';

const initialState = {
  loading: true,
  error: false,
  byProgramId: new Map()
};

export function sectionsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_SECTIONS_SUCCESS:
      return {
        ...state,
        byProgramId: { [action.data.programId]: action.data.sections },
        loading: false,
        error: false
      };
    case types.LOAD_SECTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
