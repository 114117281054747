export class NotificationMessageType {
  static requestChat = 'requestChat';
  static callBack = 'callBack';
  static newParticipant = 'newParticipant';
  static isTyping = 'isTyping';
  static newMessage = 'newMessage';
  static closeConversation = 'closeConversation';
  static participantLeave = 'participantLeave';
  static newPushPageMessage = 'newPushPageMessage';
  static requestTranscript = 'requestTranscript';
  static queueStatus = 'queueStatus';
  static ping = 'ping';
}
