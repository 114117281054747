import { LogService } from './logService';
import { AppConfiguration } from '../configuration';
import { getSessionToken } from '../session';

export function logError(componentPath, functionName, error) {
  console.error(`Component ${componentPath}, function ${functionName}`);
  console.error(error);
  const sessionToken = getSessionToken();
  const appName = AppConfiguration.getAppName();
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const errorString = error ? error.toString() : '';
  if (errorString.length > 0) {
    var logService = new LogService(apiRootUrl, sessionToken, appName);
    logService.error(componentPath, functionName, errorString);
  }
}
