import * as types from './actionTypes';

const initialState = {
  organization: null,
  loading: true,
  error: false
};

export const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.organization,
        loading: false,
        error: false
      };
    case types.LOAD_ORGANIZATION_FAILED:
      return {
        ...state,
        organization: null,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
