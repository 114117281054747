import React from 'react';
import { MaterialSpinner } from '../../shared';

export const LoadingComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}>
      <MaterialSpinner show={true} width={'100px'} height={'100px'} />
    </div>
  );
};
