export const getHoldMessages = webOnHoldGroups => {
  const holdGroupName = 'Hold Messages';
  let holdMessages = [];
  let delay = 30;
  if (webOnHoldGroups && webOnHoldGroups.length > 0) {
    const holdGroup = webOnHoldGroups.filter(
      group => group.groupName === holdGroupName
    )[0];
    if (holdGroup) {
      const messages = holdGroup.messages;
      if (messages && messages.length > 0) {
        const sortedMessages = messages.sort((a, b) =>
          a.sequence > b.sequence ? 1 : b.sequence > a.sequence ? -1 : 0
        );
        holdMessages = sortedMessages.map(x => x.message);
      }
      delay = holdGroup.delay;
    }
  }
  const holdMessageResult = {
    delay: delay,
    messages: holdMessages
  };
  return holdMessageResult;
};
