import React, { useState } from 'react';
import { AsyncTypeahead, ClearButton } from 'react-bootstrap-typeahead';
import _ from 'lodash';
import './search.scss';

export const EpisodeTypeahead = (props) => {
  const { values, episodes, handleBlur, handleFocus } = props;

  const ref = React.createRef();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const onSearch = (inputValue) => {
    setIsLoading(true);
    const options = episodes.filter((i) => {
      let k = 0;
      for (; k < i.searchTerms.length; k++) {
        if (i.searchTerms[k].toLowerCase().includes(inputValue.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
    const sortOptions = _.orderBy(options, ['name'], ['asc']);
    setOptions(sortOptions);
    setIsLoading(false);
  };

  return (
    <AsyncTypeahead
      id="searchText"
      isLoading={isLoading}
      labelKey="name"
      inputProps={{
        name: 'searchText'
      }}
      useCache={false}
      minLength={3}
      onSearch={onSearch}
      options={options}
      placeholder="Search Term"
      filterBy={(option, props) => {
        return option.name;
      }}
      ref={ref}
      onBlur={(e) => {
        handleBlur(e);
        if (!selected.length) {
          ref.current.clear();
        }
      }}
      selected={selected}
      onChange={(selected) => {
        if (selected && selected.length) {
          values.searchText = selected[0].name;
          values.episodeId = selected[0].episodeId;
          setSelected(selected);
          handleFocus();
        } else {
          values.searchText = '';
          values.episodeId = null;
          setSelected([]);
        }
      }}>
      {({ onClear, selected }) =>
        !!selected.length && (
          <div className="rbt-aux">
            <ClearButton
              onClick={(e) => {
                onClear();
                values.searchText = '';
              }}
            />
          </div>
        )
      }
    </AsyncTypeahead>
  );
};
