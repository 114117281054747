import React from 'react';
import { Container, Alert, Row, Col } from 'react-bootstrap';

export const ProgramSummaryTest = (props) => {
  return (
    <div className="programSummary">
      <>
        <div className="linksBar">
          <Container>
            <Row>
              <Col>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://stagemembers.healthadvocate.com/Content/pdf/programSummary.pdf"
                  className="pdfIconPS">
                  Download Program Summary
                </a>
              </Col>
              <Col>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://stagemembers.healthadvocate.com/Content/pdf/physicianForm.pdf"
                  className="pdfIconPS">
                  Download Physician Form
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Scenario One  */}
        {props.currentScenario === 'ScenarioOne' ? (
          <Container>
            <h1>Wellness Matters 2020</h1>
            <p>
              Health Advocate medical-enrolled employees can earn the
              <strong> 2021 Wellness Premium Discount</strong> by completing well-being activities for points. The
              amount of the 2021 weekly discount will be determined by the points level met by the program deadline of
              December 31, 2020. Download the Incentive Guide for full details on the wellness program and the
              well-being activities available to complete for points.
            </p>
            <Alert variant="primary">
              <div className="discount-disclaimer">
                <p>
                  The amount of the 2021 weekly wellness discount is based on the total points earned by December 31,
                  2020 and levels below:
                </p>
                <ul>
                  <li>
                    <strong>Level 1</strong> = 200 total points = $10 off weekly premium
                  </li>
                  <li>
                    <strong>Level 2</strong> = 400 total points = $20 off weekly premium
                  </li>
                  <li>
                    <strong>Level 3</strong> = 600 total points = $30 off weekly premium
                  </li>
                </ul>
              </div>
            </Alert>
            <p>
              Preventive Care Exams are encouraged to be scheduled before
              <strong> September 30, 2020</strong> in order to earn the points by the deadline. It may take up to 90
              days from the date of service for the exam and points to be loaded to the member wellness website. All
              points must be earned by <strong>December 31, 2020</strong>.
            </p>
          </Container>
        ) : null}

        {props.currentScenario === 'ScenarioTwo' ? (
          <Container>
            <h1>Wellness Matters 2020</h1>
            <p>
              Health Advocate medical-enrolled employees can earn the <strong> 2021 Wellness Premium Discount</strong>{' '}
              by completing well-being activities for points. Complete the following to earn a{' '}
              <strong>$600 Premium Credit</strong>:
            </p>
            <Alert variant="primary">
              <ul>
                <li>Personal Health Profile</li>
                <li>Tobacco Affidavit</li>
                <li>Be Tobacco Free or Complete Tobacco Cessation Program</li>
                <li>Health Screening Completion</li>
                <li>Health Education Session with a Coach</li>
              </ul>
            </Alert>
          </Container>
        ) : null}

        {props.currentScenario === 'ScenarioThree' ? (
          <Container>
            <h1>Wellness Matters 2019</h1>
            <p>
              Health Advocate medical-enrolled employees can earn the
              <strong> 2020 Wellness Premium Discount</strong> by completing well-being activities for points. Complete
              the following to earn a<strong> $400 Premium Credit</strong>:
            </p>
            <Alert variant="primary">
              <ul>
                <li>Personal Health Profile</li>
                <li>Tobacco Affidavit</li>
                <li>Be Tobacco Free or Complete Tobacco Cessation Program</li>
                <li>Health Screening Completion</li>
                <li>Health Education Session with a Coach</li>
              </ul>
            </Alert>
          </Container>
        ) : null}
      </>
    </div>
  );
};
