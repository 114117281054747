import { logError } from '../../logging';
import * as types from './actionTypes.js';
import { AppConfiguration } from '../../configuration';
import { updateSessionToken, getSessionToken } from '../../session';
import { ApiClient } from '../../api';

export const addAttestation = (type, attestationText, result, temperatureFahrenheit) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        const data = {
          attestationType: type,
          content: attestationText,
          result: result,
          temperatureFahrenheit: temperatureFahrenheit
        };
        dispatch(requestAddAttestation());
        const apiResult = await client.post('member/attestation', data);
        if (apiResult.data && apiResult.data.SessionToken) updateSessionToken(apiResult.data.SessionToken);
        resolve(dispatch(attestationAddSuccess(apiResult.data.result)));
      } catch (error) {
        logError('member/attestation/actions', 'addAttestation', error);
        resolve(dispatch(attestationAddFailed()));
      }
    });
  };
};

const requestAddAttestation = () => {
  return { type: types.REQUEST_ADD_ATTESTATION };
};

const attestationAddSuccess = (attestation) => {
  return { type: types.ADD_ATTESTATION_SUCCESS, attestation };
};

const attestationAddFailed = () => {
  return { type: types.ADD_ATTESTATION_FAILED };
};

export const loadAttestations = (type) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        dispatch(requestLoadAttestations());
        const apiResult = await client.get(`member/attestation?type=${type}`);
        if (apiResult.data && apiResult.data.sessionToken) updateSessionToken(apiResult.data.sessionToken);
        resolve(dispatch(attestationsLoadSuccess(type, apiResult.data.result)));
      } catch (error) {
        logError('members/attestations/actions', 'loadAttestations', error);
        resolve(dispatch(attestationsLoadFailed()));
      }
    });
  };
};

const requestLoadAttestations = () => {
  return { type: types.REQUEST_LOAD_ATTESTATIONS };
};

const attestationsLoadSuccess = (type, attestations) => {
  return {
    type: types.LOAD_ATTESTATIONS_SUCCESS,
    data: { type, attestations }
  };
};

const attestationsLoadFailed = () => {
  return { type: types.LOAD_ATTESTATIONS_FAILED };
};
