import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { TodoDropDown } from './todoDropDown';
import { loadTodos } from './actions';

export const loadTodosComponent = (store, logError) => {
  const todoListElement = document.getElementById('todoComponent');
  const todoListElementMobile = document.getElementById('todoComponentMobile');
  if (todoListElement) {
    store.dispatch(loadTodos(logError));
    ReactDOM.render(
      <Provider store={store}>
        <TodoDropDown />
      </Provider>,
      todoListElement
    );
  }
  if (todoListElementMobile) {
    store.dispatch(loadTodos(logError));
    ReactDOM.render(
      <Provider store={store}>
        <TodoDropDown />
      </Provider>,
      todoListElementMobile
    );
  }
};
