import * as types from './actionTypes';

export const providerReset = (searchType) => {
  return (dispatch) => {
    dispatch(providerResetSuccess(searchType));
  };
};

const providerResetSuccess = (searchType) => {
  return {
    type: types.PROVIDER_RESET_SUCCESS,
    providerLookupType: searchType
  };
};
