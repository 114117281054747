import React from 'react';
export const ProcedureTable = ({ procedureName, incentiveAmount, incentiveRating, procedureDisplay }) => {
  return (
    <div className="">
      <div className="col incentive-list-icon" style={{ display: procedureDisplay }}>
        {incentiveRating}
      </div>
      <div className="col incentive-name" style={{ display: procedureDisplay }}>
        {procedureName}
      </div>
      <div className="col" style={{ display: procedureDisplay }}>
        {incentiveAmount ? <span style={{ display: 'table-cell' }}>$</span> : null}{' '}
        <span style={{ display: 'table-cell' }}>{incentiveAmount}</span>
      </div>
    </div>
  );
};
