import React from 'react';
import { TodoTypes } from './todoTypes';
import { TimeTradeTodoItem } from './timeTradeTodoItem';
import { ScheduleWellnessCoachTodoItem } from './scheduleWellnessCoachTodoItem';
import { TodoItem } from './todoItem';

export const todoRenderFactory = todo => {
  if (todo.ToDoType === TodoTypes.TimeTrade) {
    return <TimeTradeTodoItem todo={todo} />;
  } else if (
    todo.ToDoType === TodoTypes.TimetradeSchedulingWellnessCoach ||
    todo.ToDoType === TodoTypes.HealthEducationSessionRequest
  ) {
    return <ScheduleWellnessCoachTodoItem todo={todo} />;
  } else {
    return <TodoItem todo={todo} />;
  }
};
