import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';
import * as types from './actionTypes';

const loadDocumentLinksSuccess = (ipoid, documentLinks) => {
  return {
    type: types.LOAD_DOCUMENTLINKS_SUCCESS,
    data: { ipoid, documentLinks }
  };
};

const loadDocumentLinksFailed = () => {
  return {
    type: types.LOAD_DOCUMENTLINKS_FAILED
  };
};

export const loadDocumentLinks = (ipoid, logError) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get(`incentiveprogram/${ipoid}/documents`);
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        resolve(dispatch(loadDocumentLinksSuccess(ipoid, apiResult.data.result)));
      } catch (error) {
        logError('incentives/documentLinks/actions', 'loadDoucmentLinks', error);
        resolve(dispatch(loadDocumentLinksFailed()));
      }
    });
  };
};
