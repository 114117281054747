import React, { Component } from 'react';
import { todoRenderFactory } from './todoRenderFactory';
import './requiredToDoList.scss';
import { ErrorCard } from '../shared/error';

export class RequiredToDoList extends Component {
  static defaultProps = {
    todos: [],
    loading: true,
    error: false
  };

  render() {
    return (
      <div className="required-todo-list requiredItems">
        {!this.props.error ? (
          this.getTodoList()
        ) : (
          <ErrorCard
            message="An error occurred loading the To Do list, technical support has been
        notified."
          />
        )}
      </div>
    );
  }

  getTodoList() {
    return (
      <>
        <ul className="no-bullet link-list">{this.getTodos()}</ul>
      </>
    );
  }

  getTodos() {
    return (
      this.props.todos &&
      this.props.todos.map(todo => {
        return <li key={todo.ToDoGuid}>{todoRenderFactory(todo)}</li>;
      })
    );
  }
}
