import React from 'react';
import { Tabs, Tab, Spinner, Alert } from 'react-bootstrap';
import { EarnPoints } from './earnPoints';
import { loadHeaderDetails } from './loadHeaderDetails';
import { useSelector } from 'react-redux';
import { loadingSelector } from './loadingSelector';
import { PointsEarned } from './pointsEarned';
import { ProgramSummary } from './programSummary';
import { Header } from './header';
import { PointsCompleted } from './pointsCompleted';
import './myPoints.css';
import { getCurrentProgram } from '../programs';
import moment from 'moment';

export const MyPoints = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const ipoid = urlParams.get('ipoid');
  const loading = useSelector((state) => loadingSelector(state));
  const {
    sections,
    incentivePrograms,
    incentiveRules,
    incentiveButtonLinks,
    incentiveLinkedRules,
    tierDetails,
    memberTierDetails,
    memberIncentiveScores,
    documentLinks
  } = useSelector((state) => state);

  if (!incentivePrograms.loading) {
    const visiblePrograms = getVisiblePrograms(incentivePrograms.list);
    const activeProgram = getCurrentProgram(incentivePrograms.list, ipoid);

    if (!activeProgram) {
      return <PointsCompleted noProgramAvailable={true} />;
    }
    const incentiveProgramOrgId = activeProgram.incentiveProgramOrganizationId;

    if (!loading && !tierDetails.loading && !memberTierDetails.loading && !documentLinks.loading) {
      const headerDetails = loadHeaderDetails(tierDetails, memberTierDetails, activeProgram);
      const showEarnPoints = moment.utc().tz('America/New_York').isSameOrBefore(activeProgram.scroingEndDate, 'day');
      const documentLinksByIpoid = documentLinks.byIpoid[incentiveProgramOrgId];
      return (
        <div className="incentivesContainer">
          <Header headerDetails={headerDetails} visiblePrograms={visiblePrograms} activeProgram={activeProgram} />

          {getErrorMessage(
            sections,
            incentivePrograms,
            incentiveRules,
            incentiveButtonLinks,
            incentiveLinkedRules,
            tierDetails,
            memberTierDetails,
            memberIncentiveScores,
            documentLinks
          )}
          <Tabs
            defaultActiveKey={showEarnPoints ? 'earnPoints' : 'pointsEarned'}
            id="uncontrolled-tab-example"
            transition={false}>
            {showEarnPoints ? (
              <Tab eventKey="earnPoints" title="How to Earn">
                <EarnPoints allLevelsComplete={headerDetails.allLevelsComplete} />
              </Tab>
            ) : (
              ''
            )}
            <Tab eventKey="pointsEarned" title="Points Earned">
              <PointsEarned ipoid={incentiveProgramOrgId} headerDetails={headerDetails} activeProgram={activeProgram} />
            </Tab>
            <Tab eventKey="programSummary" title="Program Summary">
              <ProgramSummary activeProgram={activeProgram} documentLinks={documentLinksByIpoid} />
            </Tab>
          </Tabs>
        </div>
      );
    }
  }

  return <Spinner animation="border" size="lg" variant="primary" />;
};

const getVisiblePrograms = (programList) => {
  return programList.filter(
    (program) =>
      moment.utc().tz('America/New_York').isSameOrAfter(program.visibleStartDate, 'day') &&
      moment.utc().tz('America/New_York').isSameOrBefore(program.visibleEndDate, 'day')
  );
};

const getErrorMessage = (
  sections,
  incentivePrograms,
  incentiveRules,
  incentiveButtonLinks,
  incentiveLinkedRules,
  tierDetails,
  memberTierDetails,
  memberIncentiveScores,
  documentLinks
) => {
  if (
    sections.error ||
    incentivePrograms.error ||
    incentiveRules.error ||
    incentiveButtonLinks.error ||
    incentiveLinkedRules.error ||
    tierDetails.error ||
    memberTierDetails.error ||
    memberIncentiveScores.error ||
    documentLinks.error
  ) {
    return (
      <Alert variant="danger">
        <span>Error fetching incentive list. Please refresh the page or try again later. </span>
      </Alert>
    );
  }
};
