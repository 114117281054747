import { AppConfiguration } from '../configuration';
import { getSessionToken, updateSessionToken } from '../session';
import * as types from './actionTypes';
import { ApiClient } from '../api';
export function loadOrganizationConfigurations(logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const apiClient = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await apiClient.get('OrganizationConfigurations');
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        resolve(dispatch(loadOrganizationConfigurationsSuccess(apiResult.data.Result)));
      } catch (error) {
        logError('organizationConfigurations/actions', 'loadOrganizationConfigurations', error);
        resolve(dispatch(loadOrganizationConfigurationsFailed()));
      }
    });
  };
}

export const loadOrganizationConfigurationsSuccess = (organizationConfigurations) => {
  return {
    type: types.LOAD_ORGANIZATION_CONFIGURATIONS_SUCCESS,
    organizationConfigurations: organizationConfigurations
  };
};

export const loadOrganizationConfigurationsFailed = () => {
  return { type: types.LOAD_ORGANIZATION_CONFIGURATIONS_FAILED };
};
