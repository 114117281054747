export const getSessionToken = () => {
  return window.sessionToken;
};

export const updateSessionToken = (token) => {
  if (token) {
      window.sessionToken = token;
  } else {
    console.warn('Attmped to set null or empty session token.');
  }
};
