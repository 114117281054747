import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { Section } from './section';
import { healthConfig, financeConfig, wellbeingConfig, worklifeConfig } from './sectionConfig';
import { loadSectionCards } from './actions';

export const loadSectionCardComponents = (store, logError) => {
  const healthCardElement = document.getElementById('healthSectionComponent');
  const financeCardElement = document.getElementById('financeSectionComponent');
  const wellbeingCardElement = document.getElementById('wellbeingSectionComponent');
  const worklifeCardElement = document.getElementById('worklifeSectionComponent');
  if (healthCardElement || wellbeingCardElement || financeCardElement || worklifeCardElement) {
    store.dispatch(loadSectionCards(logError));
  }

  if (healthCardElement) {
    ReactDOM.render(
      <Provider store={store}>
        <Section config={healthConfig} />
      </Provider>,
      healthCardElement
    );
  }

  if (financeCardElement) {
    ReactDOM.render(
      <Provider store={store}>
        <Section config={financeConfig} />
      </Provider>,
      financeCardElement
    );
  }

  if (wellbeingCardElement) {
    ReactDOM.render(
      <Provider store={store}>
        <Section config={wellbeingConfig} />
      </Provider>,
      wellbeingCardElement
    );
  }

  if (worklifeCardElement) {
    ReactDOM.render(
      <Provider store={store}>
        <Section config={worklifeConfig} />
      </Provider>,
      worklifeCardElement
    );
  }
};
