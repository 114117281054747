export const getBiometricsMeasurementsList = (result) => {
  const tips = [];
  addBmi(result, tips);
  addBloodGlucoseFasting(result, tips);
  addBloodGlucoseRandom(result, tips);
  addBodyFatPercentage(result, tips);
  addBloodPressure(result, tips);
  addHba1c(result, tips);
  addHeight(result, tips);
  addWeight(result, tips);
  addHdl(result, tips);
  addLdl(result, tips);
  addCotinine(result, tips);
  addPsa(result, tips);
  addTsh(result, tips);
  addTotalCholesterol(result, tips);
  addWaist(result, tips);
  addSarsCov2NaaDiagnostic(result, tips);
  addSarsCov2Antibody(result, tips);
  return tips;
};

const addBmi = (result, tips) => {
  if (result.bmi) {
    tips.push('BMI');
  }
};

const addBloodGlucoseFasting = (result, tips) => {
  if (result.bloodGlucoseFasting) {
    tips.push('Blood Glucose Fasting');
  }
};

const addBloodGlucoseRandom = (result, tips) => {
  if (result.bloodGlucoseRandom) {
    tips.push('Blood Glucose Random');
  }
};

const addBodyFatPercentage = (result, tips) => {
  if (result.bodyFatPercentage) {
    tips.push('Body Fat Percentage');
  }
};

const addBloodPressure = (result, tips) => {
  if (result.diastolic || result.systolic) {
    tips.push('Blood Pressure');
  }
};

const addHdl = (result, tips) => {
  if (result.hdl) {
    tips.push('HDL');
  }
};

const addHba1c = (result, tips) => {
  if (result.hba1c) {
    tips.push('HBA1C');
  }
};

const addHeight = (result, tips) => {
  if (result.height) {
    tips.push('Height');
  }
};

const addLdl = (result, tips) => {
  if (result.ldl) {
    tips.push('LDL');
  }
};

const addCotinine = (result, tips) => {
  if (result.nicotineMetaboliteSerumResult) {
    tips.push('Cotinine');
  }
};

const addPsa = (result, tips) => {
  if (result.psa) {
    tips.push('PSA');
  }
};

const addTsh = (result, tips) => {
  if (result.tsh) {
    tips.push('TSH');
  }
};

const addTotalCholesterol = (result, tips) => {
  if (result.totalCholesterol) {
    tips.push('Total Cholesterol');
  }
};

const addWaist = (result, tips) => {
  if (result.waist) {
    tips.push('Waist Circumference');
  }
};

const addWeight = (result, tips) => {
  if (result.weight) {
    tips.push('Weight');
  }
};

const addSarsCov2NaaDiagnostic = (result, tips) => {
  if (result.sarsCov2NaaDiagnostic) {
    tips.push('Covid-19 Diagnostic');
  }
};

const addSarsCov2Antibody = (result, tips) => {
  if (result.sarsCov2Antibody) {
    tips.push('Covid-19 Antibody');
  }
};
