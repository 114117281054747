import { logError } from '../../logging';
import * as types from './actionTypes';
import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';

const getSpineProvidersStart = () => {
  return {
    type: types.GET_SPINE_PROVIDERS_START
  };
};

const getSpineProvidersSuccess = (spineResults) => {
  return {
    type: types.GET_SPINE_PROVIDERS_SUCCESS,
    spineResults
  };
};

const getSpineProvidersFailed = () => {
  return {
    type: types.GET_SPINE_PROVIDERS_FAILED
  };
};

export const resetSpineProviders = () => {
  return {
    type: types.RESET_SPINE_PROVIDERS
  };
};

export const spineProviderSearch = (searchText, episodeId, networkId, zipCode, latitude, longitude) => {
  return async (dispatch) => {
    try {
      dispatch(getSpineProvidersStart());
      const apiRootUrl = AppConfiguration.getApiRootUrl();
      const sessionToken = getSessionToken();
      const client = new ApiClient(apiRootUrl, sessionToken);
      const data = {
        searchText,
        episodeId,
        zipCode,
        latitude,
        longitude,
        networkId
      };
      const response = await client.post('healthnavigator/spineprogram/search', data);
      if (response.data.sessionToken) {
        updateSessionToken(response.data.sessionToken);
      }
      dispatch(getSpineProvidersSuccess(response.data.result));
    } catch (error) {
      logError('healthnavigator/store/spineProviderActions', 'spineProviderSearch', error);
      dispatch(getSpineProvidersFailed());
    }
  };
};
