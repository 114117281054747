import * as types from './actionTypes.js';

export const showMenuChat = () => {
  return {
    type: types.SHOW_MENU_CHAT,
    visible: true
  };
};

export const showWellnessChat = () => {
  return {
    type: types.SHOW_WELLNESS_CHAT,
    visible: true
  };
};

export const hideChat = () => {
  return {
    type: types.HIDE_CHAT,
    visible: false
  };
};
