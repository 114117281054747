import React from 'react';
import { useSelector } from 'react-redux';
import { AppConfiguration } from '../configuration';
import { todoRenderFactory } from './todoRenderFactory';
import { topTodosSelector, moreTodosAvailableSelector } from './selectors';
import { TodoLoadingAnimation } from './todoLoadingAnimation';
import { ErrorCard } from '../shared';
import './todoList.scss';

export const TodoList = () => {
  const top = 5;
  let { error, loading, todos } = useSelector((state) => state.todos);
  todos = topTodosSelector(todos, top);
  const moreTodosAvailable = moreTodosAvailableSelector(todos);
  if (error) {
    return (
      <ErrorCard
        message="An error occurred loading the To Do list, technical support has been
      notified."
      />
    );
  }
  if (loading) {
    return <TodoLoadingAnimation />;
  }
  if (!todos || todos.length < 1) {
    return null;
  }
  return (
    <div className="card with-icon toDoList">
      <div className="card-section">
        <ul className="no-bullet link-list">
          {getTodos(todos)}
          {getShowAllButton(moreTodosAvailable)}
        </ul>
      </div>
    </div>
  );
};

//const getTodoList = (todos, moreTodosAvailable) => {};

const getShowAllButton = (moreTodosAvailable) => {
  if (moreTodosAvailable) {
    return (
      <a className="hollow button viewToDos" href={getTodoLink()}>
        See All
      </a>
    );
  }
  return null;
};

const getTodos = (todos) => {
  return (
    todos &&
    todos.map((todo) => {
      return <li key={todo.ToDoGuid}>{todoRenderFactory(todo)}</li>;
    })
  );
};

const getTodoLink = () => {
  return `${AppConfiguration.getSiteRootUrl()}ToDo`;
};
