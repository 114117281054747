import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../store/';
import { Collapse, Button } from 'react-bootstrap';
import { MdExpandMore, MdExpandLess, MdAdd } from 'react-icons/md';
import { SpineProvider } from './spineProvider';
import { MaterialSpinner } from '../../../shared/spinner/materialSpinner';
import './spineProviders.scss';

export const SpineProviders = ({ isMobile }) => {
    const [open, setOpen] = useState(false);
    const spineProviders = useSelector(state => state.healthNavigator.spineSearchResults.providers);
    const loadingSpineProviders = useSelector(state => state.healthNavigator.loadingSpineProviders);
    const loadSpineProvidersSuccess = useSelector(state => state.healthNavigator.loadSpineProvidersSuccess);
    const loadSpineProvidersError = useSelector(state => state.healthNavigator.loadSpineProvidersError);
    const searchResults = useSelector(state => state.healthNavigator.searchResults);

    const { latitude, longitude, episodeId, searchText, networkId, zipCode } = searchResults;
    const dispatch = useDispatch();

    useEffect(() => {
        if (loadSpineProvidersSuccess && !loadingSpineProviders) {
            setOpen(true);
        }
        return () => {
            if (loadSpineProvidersSuccess || loadSpineProvidersError) {
                dispatch(actions.resetSpineProviders());
            }
        };
    }, [loadSpineProvidersSuccess, loadSpineProvidersError]);

    const spineHandler = () => {
        if (!loadSpineProvidersSuccess) {
            dispatch(actions.spineProviderSearch(searchText, episodeId, networkId, zipCode, latitude, longitude));
        } else {
            setOpen(state => !state);
        }
    };

    let showProvidersText = <>Expand to see providers <MdExpandMore /></>;;

    if (loadingSpineProviders) {
        showProvidersText = <span style={{ color: "black" }}>
            Loading providers... <MaterialSpinner
                show={loadingSpineProviders && !loadSpineProvidersSuccess}
                width={"15px"}
                height={"15px"}
            /></span>;
    } else if (loadSpineProvidersError) {
        showProvidersText = <span className="spine-providers-error">
            An error occurred loading the spine program providers, technical support has been notified.
            </span>;
    }

    const sortedSpineProviders = latitude && longitude ?
        spineProviders.sort((a, b) => a.distanceMiles - b.distanceMiles)
        : spineProviders.sort((a, b) => a.facilityName.localeCompare(b.facilityName));

    const mappedSpineProviders = spineProviders.length > 0 ?
        sortedSpineProviders.map((provider, index) => (<SpineProvider provider={provider} key={index} isMobile={isMobile} />))
        : "There are currently no Spine Program providers.";

    return (
        <div className="col-md-16 spine-providers">
            <Collapse in={open}>
                <div id="example-collapse-text">
                    <div className={isMobile ? "spine-cards-mobile" : "spine-cards"}>
                        {mappedSpineProviders}
                    </div>
                </div>
            </Collapse>
            <Button
                onClick={spineHandler}
                className="btn btn-light spine-button"
                disabled={loadingSpineProviders || loadSpineProvidersError}
                aria-controls="example-collapse-text"
                aria-expanded={open}
            >
                {open ? (
                    <div>
                        Collapse providers <MdExpandLess />
                    </div>
                ) : (
                    <div>
                        {showProvidersText}
                    </div>
                )}
            </Button>
        </div >
    );
};