import React from "react";
import Modal from 'react-modal';
import './faxRecipientModal.scss';
import * as yup from 'yup';
import { ErrorMessage, withFormik } from 'formik';
import { phoneNumberRegExp, MaterialSpinnerPanel } from '../../shared';
import { addFaxRecipient, updateFaxRecipient } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorCard } from '../../shared/error';
import { resetConflictStatus } from '../recipients';

export const FaxRecipientModal = (props) => {
  const adding = useSelector(state => state.faxRecipients.newRecipient.adding);
  const conflictRecipient = useSelector(state => state.faxRecipients.newRecipient.conflictRecipient);
  return (
    <Modal
      overlayClassName="add-overlay"
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false} //opting out of screen reader support. (for now)
      className="add-doctor-modal"
      isOpen={props.addModalOpen}
      onAfterOpen={e => {}}
      onRequestClose={e => {
        props.setAddModalOpen(false);
      }}>
      <div className="linked card shadow with-icon">
        <div className="card-divider background-health">
          <h3 className="cardtitle">{props.title}</h3>
        </div>
        <div className="card-section">
          <ValidatedRecipientForm
            dispatch={useDispatch()}
            adding={adding}
            conflictRecipient={conflictRecipient}
            editExisting={props.editExisting}
            setAddModalOpen={props.setAddModalOpen}
            recipient={props.recipient}
          />
        </div>
      </div>
    </Modal>
  );
};

const recipientForm = props => {
  const { values, isValid, handleChange, handleBlur, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="linked card ">
        <label className="new-doctor-inputs">
          First Name:
          <input
            type="text"
            name="firstName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
          />
        </label>
        <div className="error-text">
          <ErrorMessage name="firstName" />
        </div>
        <label className="new-doctor-inputs">
          Last Name:
          <input
            type="text"
            name="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
          />
        </label>
        <div className="error-text">
          <ErrorMessage name="lastName" />
        </div>
        <label className="new-doctor-inputs">
          Fax Number:
          <input
            type="text"
            name="faxNumber"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.faxNumber}
          />
        </label>
        <div className="error-text">
          <ErrorMessage name="faxNumber" />
        </div>
        {props.conflictRecipient ? (<ErrorCard message="A record for this doctor already exists in your list of physicians. You can update that record by clicking 'Cancel' and choosing the edit option for that record." />) : null}
        <div className="savecancel-btn">
          <button
            className="hollow button cancel-btn"
            onClick={e => {
              props.setAddModalOpen(false);
              props.dispatch(resetConflictStatus());
            }}>
            Cancel
          </button>

          <button className="button save-btn" type="submit" disabled={!isValid}>
            Save
          </button>
        </div>
        <div className="save-spinner">
          {props.adding ? (
            <MaterialSpinnerPanel width={20} height={20} show={true} />
          ) : null}
        </div>
      </div>
    </form>
  );
};

const schema = yup.object({
  firstName: yup
    .string()
    .required('First name is required')
    .max(60, 'Max of 60 characters'),
  lastName: yup
    .string()
    .required('Last name is required')
    .max(60, 'Max of 60 characters'),
  faxNumber: yup
    .string()
    .required('Fax number is required')
    .matches(phoneNumberRegExp, 'A valid fax number is required.')
});

const ValidatedRecipientForm = withFormik({
  enableReinitialize: true,
  isInitialValid: false,
  mapPropsToValues: ({ recipient }) => ({
    firstName: recipient.firstName,
    lastName: recipient.lastName,
    faxNumber: recipient.faxNumber
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const promise = props.dispatch(
      props.editExisting
        ? updateFaxRecipient({
          firstName: values.firstName,
          lastName: values.lastName,
          faxNumber: values.faxNumber,
          memberFaxRecipientId: props.recipient.memberFaxRecipientId,
          active: true
        })
        : addFaxRecipient({
          firstName: values.firstName,
          lastName: values.lastName,
          faxNumber: values.faxNumber
        })
    );

    promise.then(() => {
      props.setAddModalOpen(false);
    });
  }
})(recipientForm);
