import React from 'react';

export const PleaseWait = (props) => {
  return (
    <p className="wait-message">
      Hello {props.userDisplayName}!
      <br />
      Please wait for a chat agent to respond.
    </p>
  );
};
