import * as types from './actionTypes';

const initialState = {
  client: null,
  loading: true,
  error: false
};

export const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_CLIENT_SUCCESS:
      return {
        ...state,
        client: action.client,
        loading: false,
        error: false
      };
    case types.LOAD_CLIENT_FAILED:
      return {
        ...state,
        client: null,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
