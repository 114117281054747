import React, { useState } from "react";
import { MaterialSpinner, formatPhoneNumber } from "../../shared";
import { FaxRecipientModal } from "./index";
import { useSelector } from "react-redux";
import { Colors, Callout } from "react-foundation";
import { ConfirmationModal } from "./confirmationModal";
import editIcon from "../../images/editIcon.png";
import trashIcon from "../../images/trashIcon.png";
import { getOrderRecipients } from "./faxRecipientSelector";
import "./faxRecipientCard.scss";
import {ErrorCard} from '../../shared/error';

export const FaxRecipientsCard = props => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [editExisting, setEditExisting] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState({
    firstName: "",
    lastName: "",
    faxNumber: "",
    memberFaxRecipientId: ""
  });
  const [modalTitle, setModalTitle] = useState(null);

  const faxRecipients = useSelector(state => state.faxRecipients);
  return (
    <div className="linked card shadow with-icon">
      <div className="card-divider background-health">
        <h3 className="cardtitle">Select Physician</h3>
      </div>
      <div className="card-section">
        <FaxRecipientsTable
          faxRecipients={faxRecipients}
          setRecipientId={props.setRecipientId}
          setConfirmationModalOpen={setConfirmationModalOpen}
          confirmationModalOpen={confirmationModalOpen}
          setAddModalOpen={setAddModalOpen}
          addModalOpen={addModalOpen}
          setSelectedRecipient={setSelectedRecipient}
          setModalTitle={setModalTitle}
          editExisting={editExisting}
          setEditExisting={setEditExisting}
        />
      </div>
      <button
        className="hollow button"
        onClick={e => {
          e.preventDefault();
          setSelectedRecipient({
            firstName: "",
            lastName: "",
            faxNumber: "",
            memberFaxRecipientId: ""
          });
          setAddModalOpen(true);
          setEditExisting(false);
          setModalTitle("+Add a Doctor");
        }}
      >
        +Add New Doctor
      </button>
      <ConfirmationModal
        confirmationModalOpen={confirmationModalOpen}
        setConfirmationModalOpen={setConfirmationModalOpen}
        recipient={selectedRecipient}
      />
      <FaxRecipientModal
        recipient={selectedRecipient}
        setAddModalOpen={setAddModalOpen}
        editExisting={editExisting}
        addModalOpen={addModalOpen}
        title={modalTitle}
      />
    </div>
  );
};

const FaxRecipientsTable = props => {
  if (props.faxRecipients.loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%"
        }}
      >
        <MaterialSpinner show={true} />
      </div>
    );
  }

  if(props.faxRecipients.error) {
    return <ErrorCard message="An error occurred while loading fax recipients list, technical support has been
      notified." />
  }

  if (!props.faxRecipients.list || !props.faxRecipients.list.length) {
    return <EmptyRecipients />;
  }

  const orderedRecipients = getOrderRecipients(props.faxRecipients.list);
  return (
    <table className="recipient-table">
      <thead>
        <tr>
          <th></th>
          <th>Doctor</th>
          <th>Fax Number</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {orderedRecipients && orderedRecipients.length
          ? orderedRecipients.map(x => (
              <RecipientsRow
                key={x.memberFaxRecipientId}
                recipient={x}
                setRecipientId={props.setRecipientId}
                setConfirmationModalOpen={props.setConfirmationModalOpen}
                confirmationModalOpen={props.confirmationModalOpen}
                setAddModalOpen={props.setAddModalOpen}
                addModalOpen={props.addModalOpen}
                setSelectedRecipient={props.setSelectedRecipient}
                setModalTitle={props.setModalTitle}
                editExisting={props.editExisting}
                setEditExisting={props.setEditExisting}
              />
            ))
          : null}
      </tbody>
    </table>
  );
};

const RecipientsRow = props => {
  return (
    <tr>
      <td>
        <input
          type="radio"
          name="physicianselection"
          onChange={e => props.setRecipientId(e.target.value)}
          value={props.recipient.memberFaxRecipientId}
          id={props.recipient.memberFaxRecipientId}
        />
      </td>
      <td>{fullName(props.recipient)}</td>
      <td>{formatPhoneNumber(props.recipient.faxNumber)}</td>
      <td>
        <button
          onClick={e => {
            e.preventDefault();
            props.setSelectedRecipient(props.recipient);
            props.setConfirmationModalOpen(true);
          }}
        >
          <img src={trashIcon} alt="Delete" title="Delete Recipient" />
        </button>
        <button
          onClick={e => {
            e.preventDefault();
            props.setSelectedRecipient(props.recipient);
            props.setAddModalOpen(true);
            props.setEditExisting(true);
            props.setModalTitle("Edit Doctor Information");
          }}
        >
          <img src={editIcon} alt="Edit" title="Edit Recipient" />
        </button>
      </td>
    </tr>
  );
};

const fullName = recipient => {
  return `${recipient.lastName}, ${recipient.firstName}`;
};

const EmptyRecipients = () => {
  return (
    <Callout color={Colors.PRIMARY}>
      No recipients available for faxing.
    </Callout>
  );
};
