import React from 'react';
import { Colors, Callout } from 'react-foundation';
import { useSelector } from 'react-redux';
import { MaterialSpinnerPanel } from '../../shared';
import { getBiometricsMeasurementsList } from './getBiometricsMeasurementsList';
import { ErrorCard } from '../../shared/error';

export const BiometricsSelectionCard = props => {
  const biometrics = useSelector(state => state.biometrics);
  return (
    <div className="linked card shadow with-icon select-bio-overflow">
      <div className="card-divider background-health">
        <h3 className="cardtitle">Select Biometrics</h3>
      </div>
      <div className="card-section">
        <BiometricsTable
          biometrics={biometrics}
          setMeasurementDates={props.setMeasurementDates}
          measurementDates={props.measurementDates}
        />
      </div>
    </div>
  );
};

const EmptyMeasurements = () => {
  return (
    <Callout color={Colors.PRIMARY}>
      No biometrics measurements available for faxing.
    </Callout>
  );
};

const ShortenMeasurements = MeasurementStr => {
  return MeasurementStr.length > 20
    ? MeasurementStr.substring(0, 80).replace(/,*\s+\S*$/, '') + '...'
    : MeasurementStr;
};

const BiometricsTable = props => {
  if (props.biometrics.loading) {
    return <MaterialSpinnerPanel show={true} />;
  }
  if (props.biometrics.error) {
    return (
      <ErrorCard
        message="An error occurred while loading biometrics list, technical support has been
      notified."
      />
    );
  }
  if (!props.biometrics.list.length) {
    return <EmptyMeasurements />;
  }
  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Date</th>
          <th>Measurements</th>
          <th>Source</th>
        </tr>
      </thead>
      <tbody>
        {props.biometrics && props.biometrics.list
          ? props.biometrics.list.map(x => (
              <BiometricsRow
                key={x.id}
                biometrics={x}
                setMeasurementDates={props.setMeasurementDates}
                measurementDates={props.measurementDates}
              />
            ))
          : null}
      </tbody>
    </table>
  );
};

const BiometricsRow = props => {
  const measurementsTooltipText = getBiometricsMeasurementsList(
    props.biometrics
  ).join(', ');
  const idStr = props.biometrics.id.toString();
  const rowChecked = !!props.measurementDates.get(idStr);
  return (
    <tr>
      <td>
        <input
          type="checkbox"
          id={props.biometrics.id}
          checked={rowChecked}
          onChange={e => {
            handleSelectionChange(
              e,
              props.biometrics.dateKey,
              props.setMeasurementDates,
              props.measurementDates
            );
          }}
        />
      </td>
      <td>{props.biometrics.measurementDate}</td>
      <td data-tooltipz={measurementsTooltipText}>
        <span>{ShortenMeasurements(measurementsTooltipText)}</span>
      </td>
      <td>{props.biometrics.measurementSource}</td>
    </tr>
  );
};

const handleSelectionChange = (
  e,
  dateKey,
  setMeasurementDates,
  measurementDates
) => {
  const id = e.target.id;
  const isChecked = e.target.checked;
  const newDates = new Map(measurementDates);
  if (isChecked) {
    newDates.set(id, dateKey);
  } else {
    newDates.delete(id);
  }
  setMeasurementDates(newDates);
};
