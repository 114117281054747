import { ApiVersion } from './apiVersion';
import { MessageTypes } from './messageTypes';
export class QueueStatusRequestMessage {
  constructor() {
    this.apiVersion = ApiVersion;
    this.type = MessageTypes.request;
    this.body = {
      method: 'queueStatus'
    };
  }
}
