export const questions = [
  {
    id: 1,
    question: 'Have you experienced any of the following symptoms in the past 48 hours?',
    answer: '',
    lists: [
      'fever or chills',
      'new or unexplained onset of cough, shortness of breath or difficulty breathing',
      'new or unexplained muscle aches',
      'new or unexplained loss of taste or smell'
    ]
  },
  {
    id: 2,
    question:
      'Are you isolating or quarantining because you tested positive for COVID-19 or are worried that you may be sick with COVID-19?',
    answer: '',
    lists: [],
    additionalText: ''
  },
  {
    id: 3,
    question:
      'Have you been in close physical contact* in the last 10 days with anyone who has tested positive for COVID-19?',
    answer: '',
    lists: [],
    additionalText:
      '*Close physical contact is defined as being within 6 feet of an infected/symptomatic person for a cumulative total of 15 minutes or more over a 24 hour period starting from 48 hours before illness onset (or, for asymptomatic individuals, 48 hours prior to test specimen collection)'
  },
  {
    id: 4,
    question: 'Wear a mask at work for 10 days following exposure',
    answer: '',
    lists: [
      'Get tested 5 days after exposure',
      'If you develop symptoms, immediately leave campus, isolate and consult with your healthcare provider'
    ],
    additionalText: ''
  },
  {
    id: 5,
    question: 'Are you fully vaccinated with the primary series of COVID-19 vaccination?',
    answer: '',
    lists: ['OR', 'Have you recovered from a documented COVID-19 infection in the last 3 months?'],
    additionalText:
      '*To be considered fully vaccinated, you must be ≥2 weeks following receipt of the second dose in a 2 dose series or ≥2 weeks following receipt of one dose of a single dose vaccine'
  },
  {
    id: 6,
    question: 'Have you:',
    answer: '',
    lists: [
      'Have you recovered from a documented COVID-19 infection in the last 3 months? OR',
      'Received a booster COVID-19 vaccination shot? OR',
      'Completed the primary series of Pfizer-BioNTech within the last 5 months? OR',
      'Completed the primary series of Moderna within the last 6 months? OR',
      'Completed the primary series of J&J vaccine within the last two months?'
    ],
    additionalText: ''
  },

  {
    id: 7,
    question: 'Have you quarantined for at least 5 days after you were exposed to COVID-19?',
    answer: '',
    lists: [],
    additionalText: ''
  },
  {
    id: 8,
    question:
      'Did you have a negative COVID-19 test taken at least 5 days after your last exposure to the close contact?',
    answer: '',
    lists: [],
    additionalText: ''
  },
  {
    id: 9,
    question: 'Have you traveled * in the past 5 days?',
    answer: '',
    lists: [],
    additionalText:
      '*Travel is defined as any trip that is overnight AND on public transportation (plane, train, bus, Uber, Lyft, cab, etc.) OR any trip that is overnight AND with people who are not in your household.'
  },

  {
    id: 10,
    question: '',
    answer: '',
    lists: [],
    additionalText: ''
  }
];
