import axios from 'axios';

export class ApiClient {
  constructor(apiRootUrl, sessionToken) {
    this.apiRootUrl = apiRootUrl;
    this.sessionToken = sessionToken;
  }

  get(endpoint) {
    var url = `${this.apiRootUrl}${endpoint}`;
    var client = axios.create({
      headers: ApiClient.createSecureHeaders(this.sessionToken)
    });
    return client.get(url);
  }

  post(endpoint, data) {
      var url = `${this.apiRootUrl}${endpoint}`;
    var client = axios.create({
      headers: ApiClient.createSecureHeaders(this.sessionToken)
    });
    return client.post(url, data);
  }

  put(endpoint, data) {
    var url = `${this.apiRootUrl}${endpoint}`;
    var client = axios.create({
      headers: ApiClient.createSecureHeaders(this.sessionToken)
    });
    return client.put(url, data);
  }

  delete(endpoint, data) {
    var url = `${this.apiRootUrl}${endpoint}`;
    var client = axios.create({
      headers: ApiClient.createSecureHeaders(this.sessionToken)
    });
    return client.delete(url, data);
  }

  patch(endpoint, data) {
    var url = `${this.apiRootUrl}${endpoint}`;
    var client = axios.create({
      headers: ApiClient.createSecureHeaders(this.sessionToken)
    });
    return client.patch(url, data);
  }

  static createSecureHeaders(sessionToken) {
    var authorizationValue = 'Member-Token ' + sessionToken;
    return { Authorization: authorizationValue };
  }
}
