import React from 'react';
import { useSelector } from 'react-redux';
import Equalizer from 'react-equalizer';
import { AppConfiguration, getAppUrl, getAppTarget } from '../configuration';
import { SectionCard } from './sectionCard';
import { getTopCardsForSectionType } from './selectors';
import { CardLoadingPlaceholderComponent } from './cardLoadingPlaceholder';
import { SectionErrorCardComponent } from './sectionErrorCard';
import './section.scss';

const eapWorklifeRootUrlType = 'eapWorklifeRootUrl';
const eapWorklifeSectionType = 'WorkLife';

export const Section = (props) => {
  const sectionCards = useSelector((state) => state.sectionCards);
  const { eapWorklifeRootUrl, loading, error, eapBrandingName, eapSectionCardDescription } = sectionCards;
  const top = 2;
  const cards = getTopCardsForSectionType(sectionCards, props.config.sectionType, top);
  const cardRefs = [];
  let title = (props.config && props.config.title) || '';
  let description = (props.config && props.config.description) || '';
  let sectionUrl = (props.config && props.config.sectionUrl) || '';
  let browseButtonText = (props.config && props.config.browseButtonText) || '';
  if (props.config && props.config.sectionType === eapWorklifeSectionType) {
    if (eapBrandingName) {
      title = eapBrandingName;
      browseButtonText = `Browse ${eapBrandingName}`;
    }
    if (eapSectionCardDescription) description = eapSectionCardDescription;
  }
  return (
    <section title={props.config.title}>
      <div className={getSectionBackgroundClasses(props.config.backgroundClass)}>
        <Equalizer className="row small-up-1 large-up-3" byRow={false} nodes={() => cardRefs} property="minHeight">
          <div className="small-12 columns large-4 columns">
            <div className="rowIcon" />
            <h2>{title}</h2>
            <h4>{description}</h4>
            <p>{getBrowseButton(sectionUrl, eapWorklifeRootUrl, browseButtonText)}</p>
          </div>
          {error ? getErrorMessage(title) : loading ? getLoading() : getCards(cards, cardRefs)}
        </Equalizer>
      </div>
      <br />
    </section>
  );
};

const getErrorMessage = (title) => {
  return <SectionErrorCardComponent title={title} />;
};

const getLoading = () => {
  return <CardLoadingPlaceholderComponent />;
};

const isExternalSiteUrl = (sectionUrl) => {
  return sectionUrl === eapWorklifeRootUrlType;
};

const getTarget = (sectionUrl) => {
  return isExternalSiteUrl(sectionUrl) ? '_blank' : '';
};

const getBrowseButton = (sectionUrl, eapWorklifeRootUrl, browseButtonText) => {
  if (isExternalSiteUrl(sectionUrl)) {
    return (
      <a
        href={getAppUrl(getBrowseUrl(sectionUrl, eapWorklifeRootUrl))}
        target={getAppTarget(getTarget(sectionUrl))}
        className="button float-right">
        {browseButtonText}
      </a>
    );
  }
  return (
    <a href={getBrowseUrl(sectionUrl, eapWorklifeRootUrl)} className="button float-right">
      {browseButtonText}
    </a>
  );
};

// Get section cards, bind section 'this' context so that we can get a child element reference for Equalizer
const getCards = (cards, cardRefs) => {
  return cards.map((card) => {
    return <SectionCard key={card.SectionCardID} card={card} addCardRef={(ref) => cardRefs.push(ref.current)} />;
  });
};

const getSectionBackgroundClasses = (backgroundClass) => {
  return `expanded columns row ${backgroundClass}`;
};

const getBrowseUrl = (sectionUrl, eapWorklifeRootUrl) => {
  let url = sectionUrl;
  // Special case, url from API result
  if (sectionUrl === eapWorklifeRootUrlType) {
    url = eapWorklifeRootUrl;
  } else {
    const siteRootUrl = AppConfiguration.getSiteRootUrl();
    url = `${siteRootUrl}${url}`;
  }
  return url;
};
