import React from 'react';
import { AppConfiguration } from '../configuration';
import './selfCheckerCertificate.scss';
import { MdPrint } from 'react-icons/md';

export const SelfCheckerCertificate = props => {
  return (
    <div className="self-checker-certificate">
      <article></article>
      <div className="row noprint">
        <div className="columns small-10 large-11 ch-row">
          <div className="columns small-3 large-1">{props.certHeader}</div>
          <div className="columns small-9 large-5">{props.certHeaderText}</div>
          <div className="columns small-9 large-3 timeRight">
            <p className="p00">{props.certDate}</p>
          </div>
          <div className="columns small-9 large-2 timeRight">
            <p className="p00">{props.certTime}</p>
          </div>
        </div>
        <div className="columns small-2 large-1">
          {!AppConfiguration.isNativeApp() ? (
            <div
              onClick={() => {
                window.print();
              }}
              className="cta noprint">
              <MdPrint className="ctaprint" size="1.6em" />
            </div>
          ) : null}
        </div>
      </div>
      <div className="certBody">
        <div className="pf0">
          <h3>{props.certName}</h3>
          <p>{props.certDate}</p>
          <p>{props.certTime}</p>
        </div>
        {props.certBody}
      </div>
    </div>
  );
};
