import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';
import * as types from './actionTypes';

const loadMemberScoresSuccess = (ipoid, memberScores) => {
  return {
    type: types.LOAD_MEMBERSCORES_SUCCESS,
    data: { ipoid, memberScores }
  };
};

const loadMemberScoresFailed = () => {
  return {
    type: types.LOAD_MEMBERSCORES_FAILED
  };
};

export const loadMemberScores = (ipoid, logError) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);

        const apiResult = await client.get(`member/incentiveprogram/incentiveprogramorganization/${ipoid}/score`);
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }

        resolve(dispatch(loadMemberScoresSuccess(ipoid, apiResult.data.result)));
      } catch (error) {
        logError('incentives/memberScores/actions', 'loadMemberScores', error);
        resolve(dispatch(loadMemberScoresFailed()));
      }
    });
  };
};
