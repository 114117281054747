export const TodoTypes = {
  Commitment: 'TD_Commitment',
  EnhCommitment: 'TD_EnhCommitment',
  MemberProcedure: 'TD_MemberProcedure',
  Competition: 'TD_Competition',
  HealthEducationSessionContact: 'TD_HealthEducationSession_Contact',
  HealthEducationSessionRequest: 'TD_HealthEducationSession_Request',
  HealthScreening: 'TD_HealthScreening',
  FluShot: 'TD_FluShot',
  HRA: 'TD_HRA',
  OnlineProgram: 'TD_OnlineProgram',
  Tobacco: 'TD_Tobacco',
  Tracker: 'TD_Tracker',
  CompetitionTracker: 'TD_CompetitionTracker',
  Workshop: 'TD_Workshop',
  TimeTrade: 'TD_Timetrade',
  TimetradeSchedulingWellnessCoach: 'TD_TimetradeSchedulingWellnessCoach'
};
