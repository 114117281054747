import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import './spineProgramCard.scss';
import * as actions from '../../store';
import { MaterialSpinner as Spinner } from '../../../shared/spinner/materialSpinner';
import { SpineProviders } from './spineProviders';

export const SpineProgramCard = ({ isMobile }) => {
  const searchText = useSelector((state) => state.healthNavigator.searchResults.searchText);
  const episodes = useSelector((state) => state.healthNavigator.episodes);
  const spineIncentives = useSelector((state) => state.healthNavigator.spineIncentives);
  const loadingSpineIncentives = useSelector((state) => state.healthNavigator.loadingSpineIncentives);
  const loadSpineIncentivesError = useSelector((state) => state.healthNavigator.loadSpineIncentivesError);
  const dispatch = useDispatch();

  const showSpineProgramCard = episodes.find((episode) => {
    return episode.name === searchText;
  }).showSpineProgram;

  useEffect(() => {
    if (showSpineProgramCard) {
      dispatch(actions.getSpineIncentives());
    }
    return () => dispatch(actions.resetSpineIncentives());
  }, []);

  let incentiveBody = null;

  if (loadingSpineIncentives) {
    incentiveBody = (<h4>Loading incentives... <Spinner show={true} width={"15px"} height={"15px"} /></h4>);
  } else if (spineIncentives.length > 0) {
    incentiveBody = spineIncentives.map((incentive, index) => {
      const { name, incentiveAmount } = incentive;
      return (
        <div className="cta" key={index}>
          <h4>{name} <span className="program-amount">${incentiveAmount}</span></h4>
        </div>
      );
    });
  } else if (loadSpineIncentivesError) {
    incentiveBody = <span className="incentive-error">
      An error occurred loading the incentives, technical support has been notified.
      </span>;
  } else {
    incentiveBody = "There are currently no spine incentives.";
  }

  return (
    <>
      {showSpineProgramCard ? (
        <Row className="spine-program-card">
          <Col md={8} className="spine-card-text">
            <h3>COMPREHENSIVE SPINE PROGRAM</h3>
            <p className="card-paragraph">
              The CSP is an integrated delivery system for patients with back and neck pain. The program uses
              standardized tools, pathways and protocols to determine an evidence-based treatment plan. The goal of the
              program is to reduce cost by eliminating unnecessary tests, treatments, surgeries, and emergency
              department visits. Achieve improved patient experience, outcomes and satisfaction and expedite return to
              normal activity and work.
            </p>
          </Col>
          <Col md={4} className="spine-incentive-text">
            <h3 className="contact">INCENTIVE </h3>
            {incentiveBody}
          </Col>
          <SpineProviders isMobile={isMobile} />
        </Row>
      ) : null
      }
    </>
  );
};
