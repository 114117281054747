import { ApiClient } from '../../api';
import { AppConfiguration } from '../../configuration';
import { getSessionToken } from '../../session';
import * as types from './actionTypes';

export function loadGeocodeAddress(latitude, longitude, logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get(
          `geocode/address?lat=${latitude}&lon=${longitude}`
        );
        const address = apiResult.data;
        resolve(dispatch(loadGeocodeAddressSuccess(address)));
      } catch (error) {
        logError('geocode/address', 'loadGeoCodeAddress', error);
        resolve(dispatch(loadGeocodeAddressFailed()));
      }
    });
  };
}

export const loadGeocodeAddressSuccess = (address) => {
  return {
    type: types.LOAD_GEOCODE_ADDRESS_SUCCESS,
    address: address,
  };
};

export const loadGeocodeAddressFailed = () => {
  return { type: types.LOAD_GEOCODE_ADDRESS_FAILED };
};
