import React, { Component } from 'react';
import { ProgramStatusType } from './programStatusType';
import './statusPane.scss';

export class StatusPane extends Component {
  static defaultProps = {
    loading: false,
    status: ProgramStatusType.NotStarted,
    hasRequiredTodos: false,
    pointsToNextTier: 0,
    currentTier: 0,
    oneTierProgram: false
  };

  render() {
    if (this.props.status === ProgramStatusType.TierRequirementsIncomplete) {
      return this.getTierRequirementsIncompleteMessage();
    } else if (this.props.status === ProgramStatusType.Complete) {
      return this.getCompleteMessage();
    } else if (this.props.status === ProgramStatusType.NotStarted) {
      return this.getNotStartedMessage();
    } else {
      return this.getInProgressMessage();
    }
  }

  getTierRequirementsIncompleteMessage() {
    let messageText = this.props.oneTierProgram
      ? "Don't forget to complete the required items"
      : "Don't forget to complete the required items to qualify for the next Level!";
    return <p className="cardtext status-text">{messageText}</p>;
  }

  getCompleteMessage() {
    return (
      <p className="cardtext status-text">
        Congratulations - You have completed this year's program requirements!
      </p>
    );
  }

  getNotStartedMessage() {
    if (this.props.oneTierProgram) {
      return null;
    }
    return (
      <p className="cardtext status-text">
        Earn{' '}
        <strong className="pointsToNext">{this.props.pointsToNextTier}</strong>{' '}
        points to complete Level 1.
        {this.props.hasRequiredTodos ? (
          <span className="requiredItems">
            Start with these required items:
          </span>
        ) : null}
      </p>
    );
  }

  getInProgressMessage() {
    return (
      <p className="cardtext status-text">
        <span>You are currently on </span>
        <span className="currentLevel">Level {this.props.currentTier}</span>
        <span>
          !<br />
          Earn{' '}
          <strong className="pointsToNext">
            {this.props.pointsToNextTier}
          </strong>{' '}
          points to complete this level.
        </span>
      </p>
    );
  }
}
