import React, { Component } from 'react';
import { MaterialSpinner } from '../shared';

export class CardLoadingPlaceholderComponent extends Component {
  render() {
    return (
      <div className="small-12 columns large-4 columns">
        <div className="card shadow spinner-container">
          <div className="">
            <MaterialSpinner show={true} />
          </div>
        </div>
      </div>
    );
  }
}
