import { ApiVersion } from './apiVersion';
import { MessageTypes } from './messageTypes';

export class CloseRequest {
  constructor() {
    this.apiVersion = ApiVersion;
    this.type = MessageTypes.request;
    this.body = {
      method: 'closeConversation'
    };
  }
}
