import React from 'react';

export const QueueStatus = (props) => {
  if (props.estimatedWaitTime) {
    return (
      <div>
        <span>Current wait time:</span> {props.estimatedWaitTime} minutes
      </div>
    );
  }
  return null;
};
