import { AppConfiguration } from '../configuration';
import { getSessionToken } from '../session';
import ReactGa from 'react-ga';
import * as types from './actionTypes.js';
import { ApiClient } from '../api';

export function loadAnalytics(logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const client = new ApiClient(apiRootUrl, sessionToken);
      try {
        const apiResult = await client.get('analytics/trackingparams');
        const result = apiResult.data;
        const params = {
          code: result.Code,
          clientId: result.ClientID,
          clientName: result.ClientName,
          organizationId: result.OrganizationID,
          organizationName: result.OrganizationName,
          device: result.Device
        };
        resolve(dispatch(loadAnalyticsSuccess(params)));
        initializeGa(params);
      } catch (error) {
        logError('analytics/actions', 'loadAnalytics', error);
        resolve(dispatch(loadAnalyticsFailed()));
      }
    });
  };
}

const formatParam = (id, name) => {
  var param = '';
  if (id && name && name.length > 0) {
    param = name + ' (' + id + ')';
  }
  return param;
};

const initializeGa = (params) => {
  ReactGa.initialize(params.code, {
    standardImplementation: true,
    gaOptions: { cookieDomain: 'auto', cookieFlags: 'Secure' }
  });
  const clientParam = formatParam(params.clientId, params.clientName);
  if (clientParam.length > 0) {
    ReactGa.set({ dimension1: clientParam });
  }
  if (params.device && params.device.length > 0) {
    ReactGa.set({ dimension2: params.device });
  }
  const organizationParam = formatParam(params.organizationId, params.organizationName);
  if (organizationParam.length > 0) {
    ReactGa.set({ dimension3: organizationParam });
  }
};

export const loadAnalyticsSuccess = (trackingParams) => {
  return {
    type: types.LOAD_ANALYTICS_SUCCESS,
    trackingParams: trackingParams
  };
};

export const loadAnalyticsFailed = () => {
  return { type: types.LOAD_ANALYTICS_FAILED };
};
