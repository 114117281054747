let appName = '';
const productionRootImagePath = `${window.siteRootUrl}content/img/`;
let rootImagePath = productionRootImagePath;

export class AppConfiguration {
  static getApiRootUrl() {
    return window.apiRootUrl;
  }

  static getSiteRootUrl() {
    return window.siteRootUrl;
  }

  static getImagePath(imageSubUrl) {
    const fullPath = `${rootImagePath}${imageSubUrl}`;
    return fullPath;
  }

  static setImagePath(path) {
    console.warn(`Setting overridden image path: ${path}`);
    rootImagePath = path;
  }

  static setAppName(name) {
    appName = name;
  }

  static getAppName() {
    return appName;
  }

  static isNativeApp() {
    return window.isNativeApp;
  }

  static getBiometricsRootUrl() {
    return window.biometricsRootUrl;
  }
}
