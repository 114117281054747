import * as types from './clientServicesActionTypes';

const initialState = {
  list: [],
  loading: true,
  error: false
};

export const clientServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_CLIENT_SERVICES_SUCCESS:
      return {
        ...state,
        list: action.clientServices,
        loading: false,
        error: false
      };

    case types.LOAD_CLIENT_SERVICES_FAILED:
      return {
        ...state,
        list: [],
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
