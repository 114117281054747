import React, { useState } from 'react';
import { AsyncTypeahead, ClearButton } from 'react-bootstrap-typeahead';
import './search.scss';
import { useDispatch } from 'react-redux';
import { SearchType } from '../searchType';
import { providerLookup, getProviderById } from '../store';
import { logError } from '../../logging';

export const ProviderTypeahead = (props) => {
  const { values, placeHolder, searchType, handleBlur, latitude, longitude, handleProviderFocus } = props;
  const dispatch = useDispatch();
  const ref = React.createRef();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const onSearch = async (inputValue) => {
    setIsLoading(true);
    const networkId = values.networkId;

    const result = await dispatch(providerLookup(searchType, inputValue, networkId, logError));
    setOptions(result.lookupResults);
    setIsLoading(false);
  };
  return (
    <AsyncTypeahead
      className="aync-search"
      id="searchText"
      inputProps={{
        name: 'searchText'
      }}
      useCache={false}
      isLoading={isLoading}
      labelKey={(option) =>
        searchType === SearchType.Doctor ? `${option.firstName} ${option.lastName}` : `${option.facilityName}`
      }
      minLength={3}
      onSearch={onSearch}
      options={options}
      placeholder={placeHolder}
      filterBy={(option) => {
        if (searchType === SearchType.Doctor) return option.firstName + ' ' + option.lastName;
        else return option.facilityName;
      }}
      ref={ref}
      selected={selected}
      onBlur={(e) => {
        handleBlur(e);
      }}
      onInputChange={(input) => {
        values.searchText = input;
      }}
      onChange={async (selected) => {
        if (selected && selected.length) {
          if (searchType === SearchType.Doctor) {
            values.searchText = selected[0].firstName + ' ' + selected[0].lastName;
          } else {
            values.searchText = selected[0].facilityName;
          }
          setSelected(selected);
          handleProviderFocus();
          const provider = selected[0];
          const networkId = values.networkId;

          await dispatch(
            getProviderById(provider.providerTin, provider.providerNpi, networkId, latitude, longitude, logError)
          );
        } else {
          setSelected([]);
        }
      }}>
      {({ onClear, selected }) =>
        !!selected.length && (
          <div className="rbt-aux">
            <ClearButton
              onClick={(e) => {
                onClear();
                values.searchText = '';
              }}
            />
          </div>
        )
      }
    </AsyncTypeahead>
  );
};
