export class Intrinsics {
  constructor(
    email,
    userName,
    areaCode,
    phoneNumber,
    skillset,
    memberId,
    organizationId,
    topicText
  ) {
    this.email = email;
    this.name = userName;
    this.country = null;
    this.area = areaCode;
    this.phoneNumber = phoneNumber;
    this.skillset = skillset;
    this.customFields = [];
    if (topicText) {
      this.customFields.push({
        title: 'topic',
        value: topicText
      });
    }
    // Send member id if possible, if not combination of organiziation and phone
    if (memberId) {
      this.customFields.push({ title: 'memberId', value: memberId });
    } else {
      if (organizationId) {
        this.customFields.push({
          title: 'organizationId',
          value: organizationId
        });
      }
      if (this.phoneNumber) {
        this.customFields.push({
          title: 'phoneNumber',
          value: this.area
            ? `${this.area}${this.phoneNumber}`
            : `${this.phoneNumber}`
        });
      }
    }
  }
}
