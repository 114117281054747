import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { removeFaxRecipient } from "./actions";
import "./confirmationModal.scss";

export const ConfirmationModal = props => {
  const dispatch = useDispatch();
  return (
    <Modal
      overlayClassName="confirmation-modal-overlay"
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false} //opting out of screen reader support. (for now)
      className="confirmation-modal"
      isOpen={props.confirmationModalOpen}
      onRequestClose={e => {
        props.setConfirmationModalOpen(false);
      }}
    >
      <div className="linked card shadow">
        <div className="card-divider background-health">
          <h3 className="cardtitle">Confirmation</h3>
        </div>
        <div className="card-section acknowledgement">
          Are you sure you want to delete the recipient{" "}
          <strong>
            {props.recipient.firstName} {props.recipient.lastName}
          </strong>{" "}
          ?
        </div>
        <div>
          <div className="card-buttons">
            <button
              className="hollow button cancel-btn"
              onClick={e => {
                e.preventDefault();
                props.setConfirmationModalOpen(false);
              }}
            >
              Cancel
            </button>
            <input
              className="button accept-btn"
              type="submit"
              value="Yes"
              onClick={e => {
                e.preventDefault();
                dispatch(
                  removeFaxRecipient(props.recipient.memberFaxRecipientId)
                );
                props.setConfirmationModalOpen(false);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
