export const healthConfig = {
  sectionType: 'Health',
  title: 'Health',
  backgroundClass: 'background-health',
  description:
    'Use our Health resources to find a doctor, clarify your treatment options, and manage your health and well-being.',
  browseButtonText: 'Browse Health',
  sectionUrl: 'Health'
};

export const financeConfig = {
  sectionType: 'Finance',
  title: 'Finance',
  backgroundClass: 'background-finance',
  description:
    'Let us help you manage your healthcare costs and improve your financial well-being.',
  browseButtonText: 'Browse Finance',
  sectionUrl: 'Finance'
};

export const wellbeingConfig = {
  sectionType: 'Wellbeing',
  title: 'Well-being',
  backgroundClass: 'background-wellbeing',
  description:
    'We can help with stress, anxiety, relationships, and emotional health to help you improve your overall well-being.',
  browseButtonText: 'Browse Well-being',
  sectionUrl: 'WellBeing'
};

export const worklifeConfig = {
  sectionType: 'WorkLife',
  title: 'EAP+Work/Life',
  backgroundClass: 'background-worklife',
  description:
    'We’re here to help with work-related issues, workplace safety, and balancing your work and life.',
  browseButtonText: 'Browse EAP+Work/Life',
  sectionUrl: 'eapWorklifeRootUrl'
};
