import React from 'react';
import { Card, Col, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { ToolTip } from './../tooltip';

export const LevelCardTest = (props) => {
  return (
    <Card className="levelCard">
      <Card.Body>
        <Card.Title>You are currently on</Card.Title>

        <Card.Text>
          <span className="topCardHeadline">{props.currLevel}</span>
          {/* Earn {props.ptsToNextLevel} more points to achieve this level. <br /> */}
          {/* <hr /> */}
          Earn 400 total points to achieve Level 2.
        </Card.Text>
        <Row className="levelIndicator">
          <Col>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>200 Points</Tooltip>}>
              <span className="levelIndic tooltipTrigger">1</span>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>400 Points</Tooltip>}>
              <span className="levelIndic tooltipTrigger active">2</span>
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>600 Points</Tooltip>}>
              <span className="levelIndic tooltipTrigger">3</span>
            </OverlayTrigger>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
