const topTodoCount = 5;

export const topTodosSelector = (todos) => {
  return todos ? todos.slice(0, topTodoCount) : [];
};

export const moreTodosAvailableSelector = (todos) => {
  return todos.length > topTodoCount;
};

export const getTopRequiredTodos = (state, topCount) => {
  let topTodos = [];
  let topTodosFound = 0;
  if (state && state.todos) {
    for (let i = 0; i < state.todos.length; i++) {
      let todo = state.todos[i];
      if (todo.Required) {
        topTodos.push(todo);
        topTodosFound++;
        if (topTodosFound === topCount) {
          break;
        }
      }
    }
  }
  return topTodos;
};
