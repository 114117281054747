export const getCurrentProgram = (programList, ipoid) => {
  let currentProgram = null;

  if (ipoid && ipoid != null) {
    currentProgram = programList.filter((program) => program.incentiveProgramOrganizationId == ipoid)[0];
  } else {
    currentProgram = programList.filter((program) => program.defaultProgram == true)[0];
  }

  return currentProgram;
};
