import { ApiClient } from '../api';
import { AppConfiguration } from '../configuration';
import { getSessionToken } from '../session';
import * as types from './actionTypes';

export function loadSectionCardsSuccess(cards, eapWorklifeRootUrl, eapBrandingName, eapSectionCardDescription) {
  return {
    type: types.LOAD_SECTION_CARDS_SUCCESS,
    cards: cards,
    eapWorklifeRootUrl: eapWorklifeRootUrl,
    eapBrandingName: eapBrandingName,
    eapSectionCardDescription: eapSectionCardDescription
  };
}

export function loadSectionCardsFailed() {
  return { type: types.LOAD_SECTION_CARDS_FAILED };
}

export function loadSectionCards(logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get('membersite/sectioncard');
        const siteCards = apiResult.data.Result.SectionCards;
        const url = apiResult.data.Result.EapWorklifeRootUrl;
        const eapBrandingName = apiResult.data.Result.EapBrandingName;
        const eapSectionCardDescription = apiResult.data.Result.EapSectionCardDescription;
        let cardMap = {};
        if (siteCards) {
          siteCards.forEach((card) => {
            if (!cardMap[card.SectionCode]) {
              cardMap[card.SectionCode] = [];
            }
            cardMap[card.SectionCode].push(card);
          });
        }
        resolve(dispatch(loadSectionCardsSuccess(cardMap, url, eapBrandingName, eapSectionCardDescription)));
      } catch (error) {
        logError('sectioncards/actions', 'loadSectionCards', error);
        resolve(dispatch(loadSectionCardsFailed()));
      }
    });
  };
}
