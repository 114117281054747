import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';
import * as types from './actionTypes';

export const providerSearch = (
  searchType,
  searchText,
  episodeId,
  networkId,
  zipCode,
  radius,
  latitude,
  longitude,
  logError
) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        dispatch(requestProviderSearch());
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        const data = {
          providerSearchType: searchType,
          searchText: searchText,
          episodeId: episodeId,
          zipCode: zipCode,
          radius: radius,
          latitude: latitude,
          longitude: longitude,
          networkId: networkId
        };
        const result = await client.post('healthnavigator/provider/search', data);
        if (result.data.sessionToken) {
          updateSessionToken(result.data.sessionToken);
        }
        resolve(dispatch(providerSearchSuccess(result.data.result)));
      } catch (error) {
        logError('healthnavigator/providerActions', 'providerSearch', error);
        resolve(dispatch(providerSearchFailed()));
      }
    });
  };
};

// Get provider @ all locations based on unique key  (ie. autocomplete 'search')
export const getProviderById = (tin, npi, networkId, lat, lon, logError) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        dispatch(requestProviderSearch());
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        let url = `healthnavigator/provider?`;
        tin = tin ? tin : '';
        url = `${url}&tin=${tin}`;
        npi = npi ? npi : '';
        url = `${url}&npi=${npi}`;
        networkId = networkId ? networkId : '';
        url = `${url}&networkId=${networkId}`;
        if (lat && lon) url = `${url}&lat=${lat}&lon=${lon}`;
        const result = await client.get(url);
        if (result.data.sessionToken) {
          updateSessionToken(result.data.sessionToken);
        }
        resolve(dispatch(providerSearchSuccess(result.data.result)));
      } catch (error) {
        logError('healthnavigator/providerActions', 'providerSearch', error);
        resolve(dispatch(providerSearchFailed()));
      }
    });
  };
};

const providerSearchFailed = () => {
  return {
    type: types.PROVIDER_SEARCH_FAILED
  };
};

const providerSearchSuccess = (results) => {
  return {
    type: types.PROVIDER_SEARCH_SUCCESS,
    searchResults: results
  };
};

export const requestProviderSearch = () => {
  return {
    type: types.REQUEST_PROVIDER_SEARCH
  };
};

export const DoctorLookupType = 'Doctor';
export const FacilityLookupType = 'Facility';

export const providerLookup = (lookupType, filterText, networkId, logError) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        dispatch(requestProviderLookup());
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        const result = await client.get(
          `healthnavigator/provider?providerType=${lookupType}&filterText=${filterText}&networkId=${networkId}`
        );
        if (result.data.sessionToken) {
          updateSessionToken(result.data.sessionToken);
        }
        resolve(dispatch(providerLookupSuccess(result.data.result)));
      } catch (error) {
        logError('healthnavigator/providerActions', 'providerLookup', error);
        resolve(dispatch(providerLookupFailed()));
      }
    });
  };
};

const providerLookupFailed = () => {
  return {
    type: types.REQUEST_PROVIDER_LOOKUP_FAILED
  };
};

const providerLookupSuccess = (results) => {
  return {
    type: types.REQUEST_PROVIDER_LOOKUP_SUCCESS,
    lookupResults: results
  };
};

export const requestProviderLookup = () => {
  return {
    type: types.REQUEST_PROVIDER_LOOKUP
  };
};
