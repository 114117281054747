import React from 'react';
import { Row, Column, Button } from 'react-foundation';
import { PropTypes } from 'prop-types';
import { AppConfiguration } from '../../configuration';
import './header.scss';

export const Header = props => {
  const imageUrl = AppConfiguration.getImagePath('icon_chatNav2.svg');
  const errorUrl = AppConfiguration.getImagePath('icon_errorConnection.svg');

  const connectivityError = props => {
    if (props.connectivityError) {
      return (
        <div className="error">
          <img src={errorUrl} alt="Connection Error" />
          <span className="tool-tip">Connection Error</span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="header">
      <Row>
        <Column>
          <div className="icon">
            <img src={imageUrl} alt="header" />
          </div>
          <div className="title">{props.title}</div>
          {connectivityError(props)}
          <div>
            <Button onClick={e => props.closeButtonClicked(e)}>
              <i className="material-icons">clear</i>
            </Button>
          </div>
        </Column>
      </Row>
    </div>
  );
};

Header.propTypes = {
  closeButtonClicked: PropTypes.func.isRequired
};
