import * as types from './actionTypes';
import { ProgramDetails } from './programDetails';

const initialState = {
  loading: true,
  error: false,
  details: new ProgramDetails()
};

export function incentivesReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_INCENTIVE_PROGRAM_STATUS_SUCCESS:
      return {
        ...state,
        details: action.details,
        loading: false,
        error: false
      };
    case types.LOAD_INCENTIVE_PROGRAM_STATUS_FAILED:
      return {
        ...state,
        details: null,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
