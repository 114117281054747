import * as types from './actionTypes.js';
import { AppConfiguration } from '../../configuration';
import { updateSessionToken, getSessionToken } from '../../session';
import { ApiClient } from '../../api';

export const sendReport = (
  recipientId,
  measurementDates,
  signedOff,
  logError
) => {
  return dispatch => {
    return new Promise(async resolve => {
      try {
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        const data = {
          MemberFaxRecipientId: recipientId,
          MeasurementDates: measurementDates,
          MemberSignedOff: signedOff
        };
        dispatch(requestSendReport());
        const apiResult = await client.post('member/fax/biometricdata', data);
        if (apiResult.data && apiResult.data.SessionToken)
          updateSessionToken(apiResult.data.SessionToken);
        if (apiResult.data && apiResult.data.Result) {
          resolve(dispatch(sendReportSuccess()));
        } else {
          resolve(dispatch(sendReportFailed()));
        }
      } catch (error) {
        logError('faxes/biometricsreport/actions', 'sendReport', error);
        resolve(dispatch(sendReportFailed()));
      }
    });
  };
};

export const requestSendReport = () => {
  return { type: types.SEND_BIOMETRICS_REPORT };
};

export const sendReportSuccess = () => {
  return { type: types.SEND_BIOMETRICS_REPORT_SUCCESS };
};

export const sendReportFailed = () => {
  return { type: types.SEND_BIOMETRICS_REPORT_FAILED };
};
