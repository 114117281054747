import * as types from './actionTypes';

const initialState = {
  loading: true,
  error: false,
  byIpoid: {
    byRuleId: new Map()
  }
};

export function memberScoreDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_MEMBERSCOREDETAILS_SUCCESS:
      return {
        ...state,
        byIpoid: { [action.data.ipoid]: { [action.data.ruleId]: action.data.memberScoreDetails } },
        loading: false,
        error: false
      };
    case types.LOAD_MEMBERSCOREDETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
