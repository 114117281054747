import React, { Component } from 'react';
import { AppConfiguration, getAppUrl, getAppTarget } from '../configuration';
import './rewardsProgramSummary.scss';

export class RewardsProgramSummary extends Component {
  static defaultProps = {
    rewardBalance: 0,
    rewardLevel: 0,
    earnedPoints: 0,
    canBrowseRewards: false,
    canRedeemRewards: false,
    rewardsUrl: ''
  };

  render() {
    return (
      <div className="linked card shadow" data-equalizer-watch>
        <div className="rewardsWidget">
          <div className="widget-section rewards-balance">
            <h3>
              <img
                src={AppConfiguration.getImagePath(
                  'card_icon_rewardsBalance.svg'
                )}
                className="card-icon"
                alt=""
              />
              Rewards Balance
            </h3>
            <div>
              <h3 className="rewards-number">${this.props.rewardBalance}</h3>
            </div>
          </div>
          <div className="widget-section current-points">
            <h3>
              <img
                src={AppConfiguration.getImagePath(
                  'card_icon_currentPoints.svg'
                )}
                className="card-icon"
                alt=""
              />
              Current Points
            </h3>
            <div>
              <h3 className="rewards-number">{this.props.earnedPoints}</h3>
            </div>
          </div>
        </div>
        {this.getBrowseButton()}
        <a className="button hollow" href={this.getPointsDetailLink()}>
          Points Detail
        </a>
      </div>
    );
  }

  getPointsDetailLink() {
    return `${AppConfiguration.getSiteRootUrl()}Wellbeing/Rewards/${
      this.props.incentiveProgramOrganizationId
    }`;
  }

  getBrowseButton() {
    let buttonText = '';
    if (this.props.canBrowseRewards && !this.props.canRedeemRewards) {
      buttonText = 'Browse Now';
    } else if (this.props.canRedeemRewards) {
      buttonText = 'Shop Now';
    }
    if (this.props.canBrowseRewards || this.props.canRedeemRewards) {
      return (
        <a
          className="button button-small hollow rewards-browse-redeem-button"
          target={getAppTarget('_blank')}
          href={getAppUrl(this.props.rewardsUrl)}>
          {buttonText}
        </a>
      );
    } else {
      return null;
    }
  }
}
