import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { loadChat } from './store';
import { ChatMount } from './mount';
import { MenuChatButton, WellnessChatButton } from './buttons';

export const loadChatComponent = (store, logError) => {
  loadChatMount(store, logError);
  loadMainMenuChat(store);
  loadWellnessCoachChat(store);
};

const loadChatMount = (store, logError) => {
  const mountElement = document.getElementById('chatMountComponent');
  if (mountElement) {
    ReactDOM.render(
      <Provider store={store}>
        <ChatMount />
      </Provider>,
      mountElement
    );
    store.dispatch(loadChat(logError));
  }
};

const loadMainMenuChat = (store) => {
  const buttonElement = document.getElementById('menuChatButtonComponent');
  if (buttonElement) {
    ReactDOM.render(
      <Provider store={store}>
        <MenuChatButton />
      </Provider>,
      buttonElement
    );
  }
};

const loadWellnessCoachChat = (store) => {
  const buttonElement = document.getElementById('wellnessChatButtonComponent');
  if (buttonElement) {
    ReactDOM.render(
      <Provider store={store}>
        <WellnessChatButton />
      </Provider>,
      buttonElement
    );
  }
};
