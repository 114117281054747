import React from 'react';
import { Col, Table } from 'react-bootstrap';
import './results.scss';

export const ProviderLists = (props) => {
  return (
    <Col md={12} className="provider-list">
      <Table bordered responsive variant="light">
        <thead>
          <tr>
            <th>Name</th>
            <th>Accepting Patients</th>
            <th>Specialties</th>
            <th>Language</th>
            <th>Gender</th>
          </tr>
        </thead>
        <tbody>{props.providerList}</tbody>
      </Table>
    </Col>
  );
};
