import { ApiVersion } from './apiVersion';
import { MessageTypes } from './messageTypes';
export class PingMessage {
  constructor() {
    this.apiVersion = ApiVersion;
    this.type = MessageTypes.request;
    this.body = {
      method: 'ping'
    };
  }
}
