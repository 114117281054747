import { loadIncentivePrograms, getCurrentProgram } from '../programs';
import { loadProgramRules } from '../rules';
import { loadMemberScores } from '../memberScores';
import { loadButtonLinks } from '../buttonLinks';
import { loadDocumentLinks } from '../documentLinks';
import { loadLinkedRules } from '../linkedRules';
import { loadSections } from '../sections';
import { loadTierDetails } from '../tierDetails';
import { loadMemberTierDetails } from '../memberTierDetails';

export const loadCurrentProgram = (logError) => {
  const urlParams = new URLSearchParams(window.location.search);
  let ipoid = urlParams.get('ipoid');

  return (dispatch) => {
    return new Promise(async () => {
      try {
        const visiblePrograms = await dispatch(loadIncentivePrograms(logError));
        const activeProgram = getCurrentProgram(visiblePrograms.list, ipoid);
        ipoid = activeProgram.incentiveProgramOrganizationId;
        // TODO: how do we handle this cleanly?
        if (!activeProgram) {
          console.error('Failed to get active incentive program from list');
          return;
        }
        let promises = [];

        promises.push(dispatch(loadSections(activeProgram.incentiveProgramId, logError)));
        promises.push(dispatch(loadProgramRules(activeProgram.incentiveProgramId, logError)));
        promises.push(dispatch(loadTierDetails(activeProgram.incentiveProgramId, logError)));
        promises.push(dispatch(loadButtonLinks(ipoid, logError)));
        promises.push(dispatch(loadLinkedRules(activeProgram.incentiveProgramId, logError)));
        promises.push(dispatch(loadDocumentLinks(ipoid, logError)));
        promises.push(dispatch(loadMemberScores(ipoid, logError)));
        promises.push(dispatch(loadMemberTierDetails(ipoid, logError)));

        await Promise.all(promises);
      } catch (error) {
        logError('incentive/mypoints/actions', 'loadCurrentProgram', error);
      }
    });
  };
};

//TO DO: move member end points to this method and add currentIncentiveProgramId to incentivesProgram Reducer
const loadMemberDetails = (ipoid, logError) => {
  return (dispatch) => {
    return new Promise(async () => {
      try {
      } catch (error) {
        logError('incentive/mypoints/actions', 'loadMemberDetails', error);
      }
    });
  };
};
