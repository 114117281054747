export const LOAD_FAX_RECIPIENTS_SUCCESS = 'LOAD_FAX_RECIPIENTS_SUCCESS';
export const LOAD_FAX_RECIPIENTS_FAILED = 'LOAD_FAX_RECIPIENTS_FAILED';
export const ADD_FAX_RECIPIENT_FAILED = 'ADD_FAX_RECIPIENT_FAILED';
export const ADD_FAX_RECIPIENT_SUCCESS = 'ADD_FAX_RECIPIENT_SUCCESS';
export const REQUEST_ADD_FAX_RECIPIENT = 'REQUEST_ADD_FAX_RECIPIENT';
export const REQUEST_REMOVE_FAX_RECIPIENT = 'REQUEST_REMOVE_FAX_RECIPIENT';
export const REMOVE_FAX_RECIPIENT_SUCCESS = 'REMOVE_FAX_RECIPIENT_SUCCESS';
export const REMOVE_FAX_RECIPIENT_FAILED = 'REMOVE_FAX_RECIPIENT_FAILED';
export const UPDATE_FAX_RECIPIENT_FAILED = 'UPDATE_FAX_RECIPIENT_FAILED';
export const UPDATE_FAX_RECIPIENT_SUCCESS = 'UPDATE_FAX_RECIPIENT_SUCCESS';
export const REQUEST_UPDATE_FAX_RECIPIENT = 'REQUEST_UPDATE_FAX_RECIPIENT';
export const SET_RECIPIENT_CONFLICT_STATUS = 'SET_RECIPIENT_CONFLICT_STATUS';
export const RESET_RECIPIENT_CONFLICT_STATUS = 'RESET_RECIPIENT_CONFLICT_STATUS';
