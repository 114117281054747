export class ServiceTypeSettingsArray {
  constructor(settingsArray) {
    this.settingsArray = settingsArray;
  }

  getSettingValue(lookupCode) {
    let value = '';
    if (this.settingsArray) {
      for (var i = 0; i < this.settingsArray.length; i++) {
        if (this.settingsArray[i].lookupCode.toLowerCase() === lookupCode.toLowerCase()) {
          value = this.settingsArray[i].value;
          break;
        }
      }
    }
    return value;
  }
}
