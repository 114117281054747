const redirectMap = {
  TD_Commitment: { area: 'Home', controller: 'Home', action: 'Commitments' },
  TD_Competition: {
    area: 'Wellbeing',
    controller: 'Wellbeing',
    action: 'Challenges'
  },
  TD_HealthEducationSession_Contact: {
    area: 'Home',
    controller: 'Home',
    action: 'MessageCoach'
  },
  TD_HealthScreening: {
    area: 'Home',
    controller: 'Home',
    action: 'BiometricScreening'
  },
  TD_FluShot: { area: 'Home', controller: 'Home', action: 'FluShot' },
  TD_HRA: {
    area: 'Health',
    controller: 'Health',
    action: 'PersonalHealthProfile'
  },
  TD_OnlineProgram: { area: 'Home', controller: 'Home', action: 'Programs' },
  TD_Tobacco: { area: 'Home', controller: 'Home', action: 'TobaccoAffidavit' },
  TD_Tracker: {
    area: 'Wellbeing',
    controller: 'Wellbeing',
    action: 'ChallengeTrackers'
  },
  TD_CompetitionTracker: {
    area: 'Wellbeing',
    controller: 'Wellbeing',
    action: 'CompetitionTrackers'
  },
  TD_Workshop: {
    area: 'Wellbeing',
    controller: 'Wellbeing',
    action: 'Workshops'
  },
  TD_BiometricsFax: {
    area: 'Biometrics',
    controller: 'Biometrics',
    action: 'Fax'
  }
};

// Certain To Do item types require iFrame redirect action, these types are
// mapped here and will be posted back to HealthyPath in a form
export function getRedirectRoute(todoType) {
  let redirectRoute = null;
  if (todoType) {
    redirectRoute = redirectMap[todoType];
  }
  return redirectRoute;
}
