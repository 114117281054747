import React from 'react';

export const IncentiveWidgetTest = (props) => {
  return (
    <div className="linked card shadow incentiveWidgCard">
      <div className="card-divider wellbeing incentiveWidg">
        <h3 className="my-points-title">My Points</h3>
      </div>
      <div className="summary-panel">
        <div className="points-meter">
          <figure>
            <div className="figure-content">
              <svg
                className="progress"
                width="225px"
                height="225px"
                viewBox="0 0 120 120">
                <circle
                  className="guideCircle"
                  cx="60"
                  cy="60"
                  r="54"
                  fill="none"
                  stroke="#e6e6e6"
                  strokeWidth="8"></circle>
                <circle
                  className="progressCircle"
                  cx="60"
                  cy="60"
                  r="54"
                  fill="none"
                  stroke="#009ee1"
                  strokeWidth="12"
                  strokeDasharray="339.29200658769764"
                  strokeDashoffset="169.64600329384882"></circle>
                <circle
                  className="tierIndicator"
                  cx="60"
                  cy="60"
                  r="54"
                  fill="none"
                  stroke="#ffffff"
                  strokeWidth="12"
                  strokeDasharray="1 338.29200658769764"></circle>
              </svg>
            </div>
            <div className="ring-text">
              <div className="points-box">
                <div className="ring-number">{props.pointsEarnedTest}</div>
                <div className="ring-label">POINTS EARNED</div>
              </div>
            </div>
          </figure>
        </div>
      </div>
    </div>
  );
};
