import * as types from './actionTypes';

const initialState = {
  loading: true,
  error: false,
  byIpoid: new Map()
};

export function buttonLinksReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_BUTTONLINKS_SUCCESS:
      return {
        ...state,
        byIpoid: { [action.data.ipoid]: action.data.buttonLinks },
        loading: false,
        error: false
      };
    case types.LOAD_BUTTONLINKS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
