export const wellnessQueueSelector = (chatState) => {
  let queueName = '';
  if (chatState && chatState.queues && chatState.queues.length > 0) {
    const filteredQueues = chatState.queues.filter((x) => x.queueType === 'Wellness');
    // Assuming 1 queue per type, if this changes this logic needs to be changed
    if (filteredQueues && filteredQueues.length > 0) {
      if (filteredQueues.length > 1) {
        console.warning('More than queue was found for type Wellness, defaulting to first entry found');
      }
      queueName = filteredQueues[0].name;
    } else {
      console.warn('Failed to find Wellness chat queue');
    }
  }
  return queueName;
};
