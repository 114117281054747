import React, { Component } from 'react';
import { ProgramStatusType } from './programStatusType';
import './pointsMeter.scss';

export class PointsMeter extends Component {
  static defaultProps = {
    tierPoints: [],
    currentPoints: 0,
    currentTier: 0,
    status: null,
    segmentMargin: 1
  };

  render() {
    const segments = this.getTierPercentageSegments(this.props.tierPoints);
    const isOneTierProgram = !segments || segments.length <= 1;
    const totalPoints = this.getTierPointsMax(this.props.tierPoints);
    let fractionCompleted = 0;
    if (this.props.status !== ProgramStatusType.TierRequirementsIncomplete) {
      fractionCompleted = this.getFractionCompleted(
        totalPoints,
        this.props.currentPoints
      );
    }
    const circumference = this.getCircumference();
    const fillLineOffset = this.getFillLine(circumference, fractionCompleted);
    const levelDashArray = this.getStrokeArray(
      circumference,
      segments,
      this.props.segmentMargin
    );
    return (
      <div className="points-meter">
        <figure>
          <div className="figure-content">
            <svg
              className="progress"
              width="180px"
              height="180px"
              viewBox="0 0 120 120">
              <circle
                className="guideCircle"
                cx="60"
                cy="60"
                r="54"
                fill="none"
                stroke="#e6e6e6"
                strokeWidth="8"
              />
              <circle
                className="progressCircle"
                cx="60"
                cy="60"
                r="54"
                fill="none"
                stroke="#009ee1"
                strokeWidth="12"
                strokeDasharray={circumference}
                strokeDashoffset={fillLineOffset}
              />
              <circle
                className="tierIndicator"
                cx="60"
                cy="60"
                r="54"
                fill="none"
                stroke="#ffffff"
                strokeWidth="12"
                strokeDasharray={levelDashArray}
              />
            </svg>
          </div>
          {this.getRingText(isOneTierProgram)}
        </figure>
      </div>
    );
  }

  getTierPercentageSegments = tiers => {
    let segments = [];
    if (tiers && tiers.length > 0) {
      // Last tier entry in array represents total points
      let totalTierPoints = tiers[tiers.length - 1];
      let previousTierPoints = 0;
      tiers.forEach(score => {
        segments.push((score - previousTierPoints) / totalTierPoints);
        previousTierPoints = score;
      });
    }
    return segments;
  };

  getTierPointsMax = tiers => {
    let max = 0;
    if (tiers) {
      max = Math.max(...tiers);
    }
    return max;
  };

  getCircumference() {
    let circumference = 0;
    const radius = 54;
    circumference = 2 * Math.PI * radius;
    return circumference;
  }

  getFractionCompleted(totalPoints, earnedPoints) {
    let percentageComplete = 0;
    if (totalPoints && totalPoints > 0) {
      if (earnedPoints >= totalPoints) {
        percentageComplete = 1;
      } else {
        percentageComplete = earnedPoints / totalPoints;
      }
    }
    return percentageComplete;
  }

  getFillLine(circumference, fractionCompleted) {
    let fillLine = 0;
    fillLine = circumference * (1 - fractionCompleted);
    return fillLine;
  }

  // Calculate dash array based on circumference of circle
  // and percentages of segments.
  getStrokeArray(circumference, segments, segmentMargin) {
    let dashArray = `1 ${circumference - 1}`;
    if (segments && segments.length > 1) {
      dashArray = segments.map(segment => {
        return `${segmentMargin} ${segment * circumference - segmentMargin}`;
      });
    }
    return dashArray;
  }

  getRingText(isOneTierProgram) {
    if (this.props.status === ProgramStatusType.NotStarted) {
      return this.getJustStarted(isOneTierProgram);
    }
    if (this.props.status === ProgramStatusType.InProgress) {
      return this.getInProgress(isOneTierProgram);
    }
    if (this.props.status === ProgramStatusType.TierRequirementsIncomplete) {
      return this.getTierRequirementsIncomplete(isOneTierProgram);
    }
    if (this.props.status === ProgramStatusType.Complete) {
      return this.getComplete(isOneTierProgram);
    }
    return null;
  }

  getJustStarted() {
    return (
      <div className="ring-text">
        <div className="points-box">
          <div className="ring-number">0</div>
        </div>
      </div>
    );
  }

  getInProgress(isOneTierProgram) {
    return (
      <div className="ring-text">
        <div className="points-box">
          <div className="ring-number">{this.props.currentPoints}</div>
          {!isOneTierProgram ? (
            <div className="ring-label">
              {'Level ' + this.props.currentTier}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  getTierRequirementsIncomplete() {
    return (
      <div className="ring-text">
        <div className="points-box">
          <div className="ring-label incomplete">PROGRAM INCOMPLETE</div>
        </div>
      </div>
    );
  }

  getComplete() {
    return (
      <div className="ring-text">
        <div className="points-box">
          <div className="ring-number">{this.props.currentPoints}</div>
          <div className="ring-label">COMPLETE</div>
        </div>
      </div>
    );
  }

  getStatusText() {
    const incomplete = 'PROGRAM INCOMPLETE';
    const complete = 'COMPLETE';
    let statusText = '';
    if (this.props.status === ProgramStatusType.Complete) {
      statusText = complete;
    }
    if (this.props.status === ProgramStatusType.TierRequirementsIncomplete) {
      statusText = incomplete;
    }
    return statusText;
  }

  getSubtitle(isOneTierProgram) {
    let subtitle = '';
    if (
      this.props.status === ProgramStatusType.NotStarted &&
      !isOneTierProgram
    ) {
      subtitle = 'Level 1';
    }
    if (
      this.props.status === ProgramStatusType.InProgress &&
      !isOneTierProgram
    ) {
      subtitle = `Level ${this.props.currentTier}`;
    }
    if (this.props.status === ProgramStatusType.Complete) {
      subtitle = `Complete`;
    }
    return subtitle;
  }
}
