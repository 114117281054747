import * as types from './actionTypes';
import { ApiClient } from '../../api';
import { AppConfiguration } from '../../configuration';
import { updateSessionToken, getSessionToken } from '../../session';

export const loadProgramRules = (programId, logError) => {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        dispatch(requestLoadProgramRules(programId));
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get(`incentiveprogram/${programId}/rulesdetails`);
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        resolve(dispatch(loadProgramRulesSuccess(programId, apiResult.data.result)));
      } catch (error) {
        resolve(dispatch(loadProgramRulesFailed()));
        logError('incentives/rules/actions', 'loadProgramRules', error);
      }
    });
  };
};

export const requestLoadProgramRules = (programId) => {
  return { type: types.REQUEST_LOAD_PROGRAMRULES, programId };
};

export const loadProgramRulesSuccess = (programId, rules) => {
  return { type: types.LOAD_PROGRAMRULES_SUCCESS, data: { programId, rules } };
};

export const loadProgramRulesFailed = () => {
  return { type: types.LOAD_PROGRAMRULES_FAILED };
};
