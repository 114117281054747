import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

// export const ModalDialogTest = props => {
//   const [show, setShow] = useState(false);
//   return (
//     <Modal.Dialog>
//       <Modal.Header closeButton>
//         <Modal.Title>Message</Modal.Title>
//       </Modal.Header>

//       <Modal.Body>
//         <p>
//           This page is intended for testing only. Some elements not necessary
//           for this purpose have been deactivated. In the final version, this
//           link will take you to {props.TestLinkDestination}.
//         </p>
//       </Modal.Body>

//       <Modal.Footer>
//         <Button variant="secondary" onClick={() => setShow(false)}>
//           Close
//         </Button>
//         {/* <Button variant="primary">Save changes</Button> */}
//       </Modal.Footer>
//     </Modal.Dialog>
//   );
// };

export const ModalDialogTest = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className={props.AdditionalClass}>
        {props.ButtonLabel}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This page is intended for testing only. Some elements not necessary for this purpose have been deactivated. In
          the final version, this link will take you to {props.TestLinkDestination}.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
