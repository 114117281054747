import * as types from './actionTypes';

const initialState = {
  sending: false,
  sendSucceeded: false,
  sendFailed: false
};

export const biometricsReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEND_BIOMETRICS_REPORT:
      return {
        ...state,
        sending: true,
        sendSucceeded: false,
        sendFailed: false
      };
    case types.SEND_BIOMETRICS_REPORT_SUCCESS:
      return {
        ...state,
        sending: false,
        sendSucceeded: true,
        sendFailed: false
      };
    case types.SEND_BIOMETRICS_REPORT_FAILED:
      return {
        ...state,
        sending: false,
        sendFailed: true,
        sendSucceeded: false
      };
    default:
      return state;
  }
};
