import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { MdHome, MdBusinessCenter } from 'react-icons/md';
import { FaAmbulance, FaChevronCircleRight } from 'react-icons/fa';
import { CoronaVirusSelfCheckerType } from '../../members/attestations';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { SelfCheckerCertificate } from '../selfCheckerCertificate';
import * as statusTypes from '../checkerStatusTypes';
import './selfCheckerHistory.scss';
import { NoAttestationHistory } from './noAttestationHistory';
import * as attestationBody from '../attestationBody';
export const SelfCheckerHistory = () => {
  const [certIsOpen, setCertOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(true);
  const [certificate, setCertDate] = useState({
    name: '',
    header: '',
    headerText: '',
    date: '',
    time: '',
    body: ''
  });
  const member = useSelector((state) => state.members);
  const members = member ? member.member : [];
  const attestationHistory = useSelector((state) => state.memberAttestations.byType[CoronaVirusSelfCheckerType]);
  const attestations = attestationHistory ? attestationHistory.attestations || [] : [];

  let currentCertName = members ? members.firstName + ' ' + members.lastName : null;
  const certToStayHome = (
    <>
      <span className="h-home">
        <MdHome className="cdc-icon" />
      </span>
    </>
  );
  const certToWork = (
    <>
      <span className="h-work">
        <MdBusinessCenter className="cdc-icon" />
      </span>
    </>
  );
  const certToER = (
    <>
      <span className="h-er">
        <FaAmbulance className="cdc-icon" />
      </span>
    </>
  );
  const attestationHistoryComponents =
    attestations.length > 0
      ? attestations.map((attestation, index) => {
          const certDate = moment(attestation.signOffDateTimeUtc).format('LL');
          const certTime = moment(attestation.signOffDateTimeUtc).format('h:mm a').toLocaleUpperCase();
          const certBody =
            attestation.result === statusTypes.stayHome
              ? attestationBody.notClearedBodyText
              : attestation.result === statusTypes.safeToWork
              ? attestationBody.clearedBodyText
              : attestation.result === statusTypes.getCare
              ? attestationBody.seekCareBodyText
              : null;

          return (
            <div
              style={{ cursor: 'pointer' }}
              className="columns small-12 large-12 cdcCard"
              key={index}
              onClick={(e) => {
                e.preventDefault();
                setCertOpen(true);
                setOpenHistory(false);
                setCertDate({
                  header:
                    attestation.result === statusTypes.stayHome
                      ? attestationBody.notClearedHeader
                      : attestation.result === statusTypes.safeToWork
                      ? attestationBody.clearedHeader
                      : attestation.result === statusTypes.getCare
                      ? attestationBody.seekCareHeader
                      : null,
                  date: certDate,
                  name: currentCertName,
                  time: certTime,
                  body: certBody,
                  headerText:
                    attestation.result === statusTypes.stayHome ? (
                      <strong>NOT cleared to Return to Work</strong>
                    ) : attestation.result === statusTypes.safeToWork ? (
                      <strong>Cleared to Return to Work</strong>
                    ) : attestation.result === statusTypes.getCare ? (
                      <strong>Seek Emergency Care</strong>
                    ) : null
                });
              }}>
              <div className="columns small-10 large-11 center-col-10">
                <div className="columns small-3 large-1 no-pad-left">
                  <div className="m-icon">
                    {attestation.result === statusTypes.stayHome
                      ? certToStayHome
                      : attestation.result === statusTypes.safeToWork
                      ? certToWork
                      : attestation.result === statusTypes.getCare
                      ? certToER
                      : null}
                  </div>
                </div>
                <div className="columns small-9 large-5 no-pad-left">
                  <div>
                    {attestation.result === statusTypes.stayHome ? (
                      <strong>NOT cleared to Return to Work</strong>
                    ) : attestation.result === statusTypes.safeToWork ? (
                      <strong>Cleared to Return to Work</strong>
                    ) : attestation.result === statusTypes.getCare ? (
                      <strong>Seek Emergency Care</strong>
                    ) : null}
                  </div>
                </div>

                <div className="columns small-9 large-3 colRight no-pad-left">
                  <div>{certDate}</div>
                </div>

                <div className="columns small-9 large-2 timeRight no-pad-left">
                  <div>{certTime}</div>
                </div>
              </div>

              <div className="columns small-2 large-1">
                <div className="tablePrint">
                  <FaChevronCircleRight />
                </div>
              </div>
            </div>
          );
        })
      : null;
  const printTableCertificate = (
    <SelfCheckerCertificate
      certName={certificate.name}
      certHeader={certificate.header}
      certHeaderText={certificate.headerText}
      certDate={certificate.date}
      certTime={certificate.time}
      certBody={certificate.body}
    />
  );

  const handTableCertificate = () => {
    if (openHistory) {
      if (!attestationHistoryComponents || attestationHistoryComponents.length === 0) {
        return <NoAttestationHistory />;
      } else {
        return attestationHistoryComponents;
      }
    }
    if (certIsOpen) {
      return (
        <>
          <h3
            className="backBtn noprint"
            onClick={() => {
              setCertOpen(false);
              setOpenHistory(true);
            }}>
            <MdKeyboardArrowLeft className="backIcon" />
            Back to History
          </h3>
          {printTableCertificate}
        </>
      );
    }
  };

  return (
    <div className="self-checker-history" id="self-checker-history">
      <div className="historyRow">{handTableCertificate()}</div>
    </div>
  );
};
