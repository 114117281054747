import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logError } from '../logging';
import { AgentWaitScreenComponent } from './agentwait';
import { ChatViewComponent } from './view';
import { AvayaChatService } from './services/avayaChatService';
import { ErrorComponent } from './error';
import { MessageTypes, NotificationMessageType, getHoldMessages } from './services';
import { RateLimiter } from '../shared';
import {
  loadHoldMessages,
  appendAgentMessage,
  updateParticipants,
  appendInfoMessage,
  updateAgentIsTyping,
  updateAgentsAvailable,
  updateEstimatedWaitTime,
  resetChatSession,
  checkIfAgentsAvailable
} from './store';
import './chatComponent.scss';

const userIsTypingLimitIntevalMs = 2000;
const agentCheckIntervalMs = 30000;
const agentTypingAnimationTimeoutMs = 10000;
const observerRole = 'supervisor_observe';

class ChatComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      user: this.props.user
    };
    this.isTypingMessageRateLimiter = new RateLimiter(userIsTypingLimitIntevalMs);
    this.avayaChatService = null;
    this.messageObservable = null;
    this.comfortSequence = 0;
    this.comfortIntervalId = 0;
    this.agentCheckIntervalId = 0;
  }

  componentDidMount() {
    this.initializeConnections();
    this.startAgentCheckInterval();
  }

  componentWillUnmount() {
    this.stopAgentCheckInterval();
    this.stopQueueStatusInterval();
    this.stopIdleComfortMessages();
    this.props.resetChatSession();
    if (this.avayaChatService) {
      this.avayaChatService.quitChat();
      this.cleanupConnections();
    }
  }

  componentDidCatch(error) {
    this.stopAgentCheckInterval();
    this.stopQueueStatusInterval();
    if (this.avayaChatService) {
      this.avayaChatService.quitChat();
      this.cleanupConnections();
    }
    this.setState({ error: true });
    logError('ChatComponent', 'componentDidCatch', error);
  }

  initializeConnections() {
    if (this.props.serviceSettings.validate() || !this.props.queue) {
      const { chatUrl, pingIntervalMs } = this.props.serviceSettings;
      this.avayaChatService = new AvayaChatService(chatUrl, pingIntervalMs);
    } else {
      console.error('Failed to validate chat settings');
      this.setState({ error: true });
    }
  }

  cleanupConnections() {
    this.avayaChatService = null;
    this.closeObservable = null;
    this.messageObservable = null;
    this.errorObservable = null;
    this.openObservable = null;
  }

  render() {
    let content = null;
    if (this.props.error) {
      content = <ErrorComponent />;
    } else if (this.props.showWaiting) {
      content = (
        <AgentWaitScreenComponent
          userDisplayName={this.props.user.firstName}
          contactUrl={this.props.contactUrl}
          contactPhoneNumber={this.props.contactPhoneNumber}
        />
      );
    } else {
      content = <ChatViewComponent userIsTyping={this.userIsTyping} sendMessage={this.sendMessage} />;
    }
    return content;
  }

  loginUser = (user) => {
    const observables = this.avayaChatService.connect();
    this.openObservable = observables.openObservable;
    this.messageObservable = observables.messageObservable;
    this.closeObservable = observables.closeObservable;
    this.errorObservable = observables.errorObservable;
    this.messageObservable.subscribe((message) => {
      this.messageEvent(message);
    });
    this.closeObservable.subscribe((message) => {
      this.closeEvent(message);
    });
    this.errorObservable.subscribe((message) => {
      this.errorEvent(message);
    });
    this.avayaChatService.login(
      user.email,
      user.userName,
      user.phone.area,
      user.phone.number,
      this.props.queue,
      user.memberId,
      user.organizationId,
      this.props.topicText
    );
  };

  closeEvent = () => {
    this.stopQueueStatusInterval();
  };

  messageEvent = (message) => {
    if (this.props.setConnectivityError) {
      this.props.setConnectivityError(false);
    }
    if (message.type === MessageTypes.notification) {
      const notificationType = message.body.method;
      if (notificationType === NotificationMessageType.newParticipant) {
        this.newParticipant(message);
      } else if (notificationType === NotificationMessageType.requestChat) {
        this.requestChat(message);
      } else if (notificationType === NotificationMessageType.newMessage) {
        this.newMessage(message);
      } else if (notificationType === NotificationMessageType.isTyping) {
        this.agentIsTyping();
      } else if (notificationType === NotificationMessageType.participantLeave) {
        this.participantLeft(message);
      } else if (message.method === NotificationMessageType.queueStatus) {
        this.queueStatusUpdate(message);
      }
    }
  };

  queueStatusUpdate = (message) => {
    this.props.updateEstimatedWaitTime(message.estimatedWaitTime);
  };

  requestChat = (message) => {
    this.props.loadHoldMessages(getHoldMessages(message.body.webOnHoldComfortGroups));
  };

  newParticipant = (message) => {
    this.stopIdleComfortMessages();
    this.stopQueueStatusInterval();
    this.props.updateParticipants(message.body.participants, true);
    if (message.body.role !== observerRole) {
      const id = this.props.messages.length + 1;
      this.props.appendInfoMessage(`${message.body.displayName} has joined.`, id);
    }
  };

  participantLeft = (message) => {
    const chatInProgress = !message.body.endChatFlag;
    const participant = this.getParticipant(message.body.agentId);
    this.props.updateParticipants(message.body.participants, chatInProgress);
    const id = this.props.messages.length + 1;
    if (participant && participant.type !== observerRole) {
      this.props.appendInfoMessage(`${participant.name} has left.`, id);
    }
    if (this.props.participants.length === 0 && chatInProgress) {
      this.startIdleComfortMessages();
    }
    if (!chatInProgress) {
      const id = this.props.messages.length + 1;
      this.props.appendInfoMessage(`Chat session has ended.`, id);
    }
  };

  getParticipant(id) {
    let name = 'Agent';
    let type = '';
    const filteredList = this.props.participants.filter((x) => x.id === id);
    if (filteredList && filteredList.length > 0) {
      name = filteredList[0].name;
      type = filteredList[0].type;
    }
    return { name, type };
  }

  startIdleComfortMessages = () => {
    const defaultDelay = 30;
    const intervalSeconds = this.props.holdMessageDelay ? this.props.holdMessageDelay : defaultDelay;
    this.comfortIntervalId = setInterval(() => {
      const message = this.iterateHoldMessage();
      if (message && message.length > 0) {
        const id = this.props.messages.length + 1;
        this.props.appendAgentMessage(message, '', new Date(), id);
      }
    }, intervalSeconds * 1000);
  };

  iterateHoldMessage = () => {
    let message = '';
    if (this.props.holdMessages && this.props.holdMessages.length > 0) {
      if (this.comfortSequence >= this.props.holdMessages.length) {
        this.comfortSequence = 0;
      }
      message = this.props.holdMessages[this.comfortSequence];
      this.comfortSequence = this.comfortSequence + 1;
    }
    return message;
  };

  stopIdleComfortMessages = () => {
    if (this.comfortIntervalId) {
      clearInterval(this.comfortIntervalId);
      this.comfortIntervalId = 0;
    }
  };

  newMessage = (message) => {
    const id = this.props.messages.length + 1;
    this.props.appendAgentMessage(message.body.message, message.body.displayName, message.body.timestamp, id);
    this.props.updateAgentIsTyping(false);
  };

  agentIsTyping = () => {
    this.props.updateAgentIsTyping(true);
    this.clearAnimationTimer();
    this.animationStopTimerId = setTimeout(this.stopAgentTypingAnimation, agentTypingAnimationTimeoutMs);
  };

  stopAgentTypingAnimation = () => {
    this.props.updateAgentIsTyping(false);
  };

  clearAnimationTimer = () => {
    if (this.animationStopTimerId) {
      clearTimeout(this.animationStopTimerId);
      this.animationStopTimerId = null;
    }
  };

  errorEvent = (error) => {
    if (error && error.type !== 'close') {
      console.error(`ChatComponent encounter an error: `);
      console.error(error);
      if (this.props.setConnectivityError) {
        this.props.setConnectivityError(true);
      }
    }
  };

  sendMessage = (message) => {
    this.avayaChatService.sendChatMessage(message);
  };

  userIsTyping = (isTyping) => {
    this.isTypingMessageRateLimiter.limit(() => {
      this.avayaChatService.sendUserIsTyping(isTyping);
    });
  };

  sendQueueStatusRequest = () => {
    this.avayaChatService.sendQueueStatusRequest();
  };

  closeButtonClicked = (event) => {
    event.preventDefault();
    if (this.props.eventEmitter) {
      this.stopQueueStatusInterval();
      this.stopIdleComfortMessages();
      this.avayaChatService.quitChat();
      this.cleanupConnections();
      this.props.resetChatSession();
      this.props.eventEmitter.emit('ChatComponent.Close');
    } else {
      console.warn('Event emitter was not set for handling close button');
    }
  };

  startQeueStatusInterval = () => {
    if (this.props.serviceSettings.queueStatusIntervalMs) {
      this.queueStatusIntervalId = setInterval(
        this.sendQueueStatusRequest,
        this.props.serviceSettings.queueStatusIntervalMs
      );
    } else {
      console.error('Queue status interval was not set');
    }
  };

  stopQueueStatusInterval = () => {
    if (this.queueStatusIntervalId) {
      clearInterval(this.queueStatusIntervalId);
    }
  };

  startAgentCheckInterval() {
    if (this.props.queue) {
      this.props.checkIfAgentsAvailable(this.props.queue, logError, () => {
        this.agentsAreAvailableCallback();
      });
      this.agentCheckIntervalId = setInterval(() => {
        this.props.checkIfAgentsAvailable(this.props.queue, logError, () => {
          this.agentsAreAvailableCallback();
        });
      }, agentCheckIntervalMs);
    } else {
      console.warn('startAgentCheckInterval failed - queue is null or empty');
    }
  }

  agentsAreAvailableCallback() {
    this.stopAgentCheckInterval();
    this.startQeueStatusInterval();
    if (this.state.user != null) {
      this.loginUser(this.props.user);
    }
  }

  stopAgentCheckInterval() {
    if (this.agentCheckIntervalId) {
      clearInterval(this.agentCheckIntervalId);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.chat
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadHoldMessages: (holdMessages) => dispatch(loadHoldMessages(holdMessages)),
    appendAgentMessage: (message, agentName, timestamp, id) =>
      dispatch(appendAgentMessage(message, agentName, timestamp, id)),
    updateParticipants: (participants) => dispatch(updateParticipants(participants)),
    updateAgentIsTyping: (isTyping) => dispatch(updateAgentIsTyping(isTyping)),
    appendInfoMessage: (message, id) => {
      dispatch(appendInfoMessage(message, id));
    },
    updateAgentsAvailable: (available) => dispatch(updateAgentsAvailable(available)),
    updateEstimatedWaitTime: (estimatedWaitTime) => dispatch(updateEstimatedWaitTime(estimatedWaitTime)),
    resetChatSession: () => dispatch(resetChatSession()),
    checkIfAgentsAvailable: (queue, logError, agentsAreAvailableCallback) =>
      dispatch(checkIfAgentsAvailable(queue, logError, agentsAreAvailableCallback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatComponent);
