export class RateLimiter {
  constructor(wait) {
    this.isCalled = false;
    this.wait = wait;
  }

  limit(fn) {
    if (!this.isCalled) {
      fn();
      this.isCalled = true;
      setTimeout(() => {
        this.isCalled = false;
      }, this.wait);
    }
  }
}
