import React from 'react';
import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { searchReferenceNumber } from '../helper';
import { SpineContact } from './spineContact';
import { MdPlace } from 'react-icons/md';
import './spineProvider.scss';
import { getAppUrl, getAppTarget } from '../../../configuration';

export const SpineProvider = ({ provider, isMobile }) => {
  const searchResultId = useSelector((state) => state.healthNavigator.spineSearchResults.searchResultId);
  const {
    spineProgramProviderId,
    facilityName,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    state,
    zip,
    longitude,
    latitude,
    distanceMiles
  } = provider;

  const address = (
    <>
      <span>{!addressLine1 ? '' : <span>{addressLine1} </span>}</span>
      <span>{!addressLine2 ? '' : <span>{addressLine2} </span>}</span>
      <span>{!addressLine3 ? '' : <span>{addressLine3} </span>}</span>
      <span>{city + ', ' + state + ' ' + zip}</span>
    </>
  );

  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

  const distance = distanceMiles ? `${distanceMiles.toFixed(1)} Miles Away` : 'View Map';

  return (
    <div className="spine-provider">
      <Card className={isMobile ? 'spine-card-mobile' : 'spine-card'}>
        <Card.Body>
          <h3>{facilityName}</h3>
          <h4>{address}</h4>
          <span className="viewMap">
            <a target={getAppTarget('_blank')} href={getAppUrl(googleMapsUrl)} alt="map">
              <MdPlace className="spine-distance" size="1.5em" />
              {distance}
            </a>
          </span>
          <SpineContact
            searchResultId={searchResultId}
            uniqueKeyId={spineProgramProviderId}
            patientCode={searchReferenceNumber(searchResultId.toString())}
            travelIncentive={''}
            isMobile={isMobile}
            name={facilityName}
          />
        </Card.Body>
      </Card>
    </div>
  );
};
