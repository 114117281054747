import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BiometricsFaxView } from './biometricsFaxView';
import { startHistoryRefresh } from '../history';
import { loadBiometrics } from '../../biometrics';
import { loadFaxRecipients } from '../recipients';

export const loadBiometricsFaxView = (store, logError) => {
  const biometricsFaxComponent = document.getElementById(
    'biometricsFaxComponent'
  );
  if (biometricsFaxComponent) {
    ReactDOM.render(
      <Provider store={store}>
        <BiometricsFaxView />
      </Provider>,
      biometricsFaxComponent
    );
    store.dispatch(loadBiometrics(logError));
    startHistoryRefresh(store.dispatch);
    store.dispatch(loadFaxRecipients(logError));
  }
};
