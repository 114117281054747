import React from 'react';
import Modal from 'react-modal';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-47%',
    width: 'auto',
    maxWidth: '33rem',
    transform: 'translate(-50%, -50%)',
    borderRadius: '2px',
    border: '1px solid #948E94'
  }
};

export const SelfCheckerHelpModal = (props) => {
  let subtitle;
  return (
    <Modal
      isOpen={props.modalIsOpen}
      overlayClassName="cert-overlay"
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      onAfterOpen={(e) => {}}>
      <div ref={(_subtitle) => (subtitle = _subtitle)} className="modal-content">
        <div className="modal-header noprint">
          <div className="columns small-12 large-12 modal-title noprint">
            <div className="columns small-9 large-9 no-padding">
              <p>
                After completing the self-checker, look for the CDC guidance message. Use the table below to determine
                the appropriate selection.
              </p>
              {subtitle}
            </div>
            <div className="columns small-3 large-3 no-padding">
              <button onClick={props.certClicked} type="button" className="close">
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
          </div>
        </div>
        <div className="modal-body">
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    borderRight: '1px solid #c8c8c8'
                  }}>
                  CDC Guidance
                </th>
                <th>Select Option</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td
                  style={{
                    borderRight: '1px solid #c8c8c8'
                  }}>
                  <strong className="ts0">“Sounds like you (they) are feeling okay.”</strong>
                  <strong className="ts0">“Monitor for symptoms, wear a mask"</strong>
                </td>
                <td>Cleared</td>
              </tr>

              <tr
                style={{
                  borderTop: '1px solid #c8c8c8',
                  borderBottom: '1px solid #c8c8c8'
                }}>
                <td
                  style={{
                    borderRight: '1px solid #c8c8c8'
                  }}>
                  <strong className="ts0">
                    "Sorry you (they) are feeling sick. Stay home (keep them home) and monitor your (their) symptoms.
                    Call your (their) medical provider if you get worse."
                  </strong>
                  <strong className="ts0">"Contact the occupational health provider at your workplace."</strong>
                  <strong className="ts0">
                    "Contact a medical provider in the care center, nursing home, or homeless shelter where you (they)
                    live."
                  </strong>
                  <strong className="ts0"> "Monitor for symptoms, stay at home."</strong>
                  <strong className="ts0">
                    "Stay home and take care of yourself. Call your provider if you get worse."
                  </strong>
                  <strong className="ts0">
                    "Sounds like you (they) may have symptoms of COVID-19. You (they) should get tested for COVID-19."
                  </strong>
                </td>
                <td className="ts1">Not Cleared</td>
              </tr>

              <tr>
                <td
                  style={{
                    borderRight: '1px solid #c8c8c8'
                  }}>
                  <strong>
                    "Urgent medical attention may be needed. Please call 911 or go to the Emergency Department "
                  </strong>
                </td>
                <td>Seek Care</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};
