import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Menu, MenuItem } from 'react-foundation';
import { debugLogin } from './debugLogin';

// This 'debugApp' is for debugging individual components while we work in a hybrid MVC/SPA mode
// and will only be created while debugging w/ npm start

const coronavirusSelfCheckerTestHarness = () => {
  return (
    <div
      style={{
        maxWidth: '1140px',
        padding: '20px',
        margin: '1rem auto'
      }}>
      <div id="coronavirusSelfCheckerComponent" />
    </div>
  );
};

const todoTestHarness = () => {
  return (
    <div style={{ width: '470px', padding: '20px', margin: '0px auto' }}>
      <div id="todoComponent" />
    </div>
  );
};

const biometricsFaxesTestHarness = () => {
  return (
    <div style={{ width: '100%', padding: '0px' }}>
      <div id="biometricsFaxComponent" />
    </div>
  );
};

const incentiveTestHarness = () => {
  return (
    <div style={{ width: '350px', padding: '20px' }}>
      <div id="incentiveSummaryComponent" />
    </div>
  );
};

const sectionCardsTestHarness = () => {
  return (
    <div className="homepageSubsections">
      <div id="healthSectionComponent" />
      <div id="financeSectionComponent" />
      <div id="wellbeingSectionComponent" />
      <div id="worklifeSectionComponent" />
    </div>
  );
};
const quickLinksTestHarness = () => {
  return (
    <div>
      <div id="quickLinksComponent" />
    </div>
  );
};

const myPointsTestHarness = () => {
  return (
    <div className="newMyPointsPage">
      <div id="myPointsComponent" />
    </div>
  );
};

const myPointsUserTestHarness = () => {
  return (
    <div className="newMyPointsPage">
      <div id="myPointsUtComponent" />
    </div>
  );
};

const chatTestHarness = () => {
  return (
    <div style={{ padding: '20px' }}>
      <div style={{ padding: '20px' }}>
        <div id="menuChatButtonComponent" />
        <div id="chatMountComponent" />
      </div>
      <div style={{ padding: '20px' }}>
        <div id="wellnessChatButtonComponent" />
      </div>
    </div>
  );
};

const healthNavigatorTestHarness = () => {
  return (
    <div>
      <div id="healthNavigatorComponent" />
    </div>
  );
};

const prescriptionDiscountCardTestHarness = () => {
  return (
    <div>
      <div id="prescriptionDiscountCardComponent" />
    </div>
  );
};

export class DebugAppComponent extends Component {
  constructor(props) {
    super(props);
    window.apiRootUrl = process.env.REACT_APP_API_ROOT_URL;
    this.apiRootUrl = window.apiRootUrl;
    window.siteRootUrl = process.env.REACT_APP_SITE_ROOT_URL;
    this.siteRootUrl = window.siteRootUrl;
    this.clientGuid = process.env.REACT_APP_DEBUG_CLIENT_GUID;
    this.userName = process.env.REACT_APP_DEBUG_USER;
    this.password = process.env.REACT_APP_DEBUG_PASSWORD;
  }

  componentDidMount() {
    console.warn(`Debug environment detected, using debug login credentials for user ${this.userName}`);
    debugLogin(this.apiRootUrl, this.clientGuid, this.userName, this.password).then((sessionToken) => {
      console.log(`Debug login succeeded, session token: ${sessionToken}`);
      window.sessionToken = sessionToken;
      this.props.loadComponents(this.apiRootUrl, sessionToken);
    });
  }

  render() {
    return (
      <Router>
        <div>
          <Menu>
            <MenuItem>
              <a href="/todos">todos</a>
            </MenuItem>
            <MenuItem>
              <a href="/incentives">incentives</a>
            </MenuItem>
            <MenuItem>
              <a href="/sectioncards">sectioncards</a>
            </MenuItem>
            <MenuItem>
              <a href="/chat">chat</a>
            </MenuItem>
            <MenuItem>
              <a href="/biometricsfax">biometrics fax</a>
            </MenuItem>
            <MenuItem>
              <a href="/healthnavigator">health navigator</a>
            </MenuItem>
            <MenuItem>
              <a href="/mypoints">my points</a>
            </MenuItem>
            <MenuItem>
              <a href="/mypointsut">my points user testing</a>
            </MenuItem>
            <MenuItem>
              <a href="/iwantto">i want to</a>
            </MenuItem>
            <MenuItem>
              <a href="/returnpass">return pass</a>
            </MenuItem>
            <MenuItem>
              <a href="/prescriptionDiscountCard">Prescription Discount Card</a>
            </MenuItem>
          </Menu>
        </div>
        <div>
          <Route exact path="/returnpass" component={coronavirusSelfCheckerTestHarness} />
          <Route exact path="/todos" component={todoTestHarness} />
          <Route exact path="/incentives" component={incentiveTestHarness} />
          <Route exact path="/sectioncards" component={sectionCardsTestHarness} />
          <Route path="/chat" component={chatTestHarness} />
          <Route path="/biometricsfax" component={biometricsFaxesTestHarness} />
          <Route path="/healthnavigator" component={healthNavigatorTestHarness} />
          <Route path="/mypoints" component={myPointsTestHarness} />
          <Route path="/mypointsut" component={myPointsUserTestHarness} />
          <Route path="/iwantto" component={quickLinksTestHarness} />
          <Route path="/prescriptionDiscountCard" component={prescriptionDiscountCardTestHarness} />
        </div>
      </Router>
    );
  }
}
