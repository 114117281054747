import { AppConfiguration } from '../configuration';
import { getSessionToken, updateSessionToken } from '../session';
import * as types from './clientServicesActionTypes.js';
import { ApiClient } from '../api';

export function loadClientServices(logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return dispatch => {
    return new Promise(async resolve => {
      try {
        const apiClient = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await apiClient.get('client/services');
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        resolve(dispatch(loadClientServicesSuccess(apiResult.data.result)));
      } catch (error) {
        logError('client/actions', 'loadClient', error);
        resolve(dispatch(loadClientServicesFailed()));
      }
    });
  };
}

export const loadClientServicesSuccess = clientServices => {
  return {
    type: types.LOAD_CLIENT_SERVICES_SUCCESS,
    clientServices: clientServices
  };
};

export const loadClientServicesFailed = () => {
  return { type: types.LOAD_CLIENT_SERVICES_FAILED };
};
