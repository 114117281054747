const defaultPingIntervalMs = 10000;
const defaultQueueStatusIntervalMs = 10000;

export class ChatServiceSettings {
  constructor(
    chatUrl,
    restUrl,
    callbackUrl,
    pingIntervalMs,
    queueStatusIntervalMs
  ) {
    this.chatUrl = chatUrl;
    this.restUrl = restUrl;
    this.callbackUrl = callbackUrl;
    this.pingIntervalMs = pingIntervalMs
      ? pingIntervalMs
      : defaultPingIntervalMs;
    this.queueStatusIntervalMs = queueStatusIntervalMs
      ? queueStatusIntervalMs
      : defaultQueueStatusIntervalMs;
  }

  validate() {
    let valid = false;
    if (!this.chatUrl) {
      console.error('Avaya chat url was not specified in ChatServerSettings');
      return valid;
    }
    if (!this.restUrl) {
      console.error('Avaya rest url was not specified in ChatServerSettings');
      return valid;
    }
    if (!this.pingIntervalMs) {
      console.error(
        'Ping interval specified was null, undefined or 0, invalid'
      );
      return valid;
    }
    if (!this.queueStatusIntervalMs) {
      console.error(
        'Queue status interval specified was null, undefined or 0, invalid'
      );
      return valid;
    }
    valid = true;
    return valid;
  }
}
