import { PhoneNumber } from '../demographics';

export class ChatUser {
  constructor() {
    this.firstName = null;
    this.lastName = null;
    this.userName = null;
    this.company = null;
    this.phone = { area: null, number: null };
    this.email = null;
    this.organizationId = null;
    this.memberId = null;
  }

  static create(member, company, organizationId) {
    const user = new ChatUser();
    user.firstName = member.firstName;
    user.lastName = member.lastName;
    user.userName = ChatUser.getUserName(user.firstName, user.lastName);
    user.company = company;
    user.memberId = member.id;
    user.organizationId = organizationId;
    user.email = member.primaryEmail;
    if (member.primaryPhone) {
      const phoneNumber = new PhoneNumber(member.primaryPhone);
      user.phone = {
        area: phoneNumber.areaCode,
        number: `${phoneNumber.prefix}${phoneNumber.lineNumber}`
      };
    }
    return user;
  }

  static getUserName(firstName, lastName) {
    return `${firstName} ${lastName}`;
  }
}
