import React from 'react';
import { ProcedureResults } from './procedureResults';
import { ProviderResults } from './providerResults';
import { FacilityResults } from './facilityResults';
import { Legend } from '../legend';
import { ErrorCard } from '../../shared/error';
import { SearchType } from '../searchType';
import { useSelector } from 'react-redux';
import './results.scss';

export const Results = () => {
  const searchResults = useSelector((state) => state.healthNavigator.searchResults);
  const searching = useSelector((state) => state.healthNavigator.searching);
  const error = useSelector((state) => state.healthNavigator.error);


  return (
    <div className="cost-estimator-results">
      <ResultList
        searchtype={searchResults.providerSearchType}
        searching={searching}
        latLonFromClient={searchResults.latLonFromClient}
        error={error}
      />
    </div>
  );
};

const ResultList = (props) => {
  if (props.error) {
    return (
      <ErrorCard
        message="An error occurred while searching the Health Navigator, technical support has been
      notified."
      />
    );
  }
  if (props.searching) {
    return (
      <div className="text-center">
        <div className="spinner-border text-primary"></div>
      </div>
    );
  }
  if (props.searchtype === SearchType.Procedure) {
    return <ProcedureResults />;
  }
  if (props.searchtype === SearchType.Doctor) {
    return <ProviderResults />;
  }
  if (props.searchtype === SearchType.ByUniqueId) {
    return <ProviderResults />;
  }
  if (props.searchtype === SearchType.Facility) {
    return <FacilityResults />;
  } else {
    return (
      <div className="container text-center">
        <Legend />
      </div>
    );
  }
};
