import React from 'react';
import { Image } from 'react-bootstrap';
import './quickLinksItem.scss';
import { AppConfiguration } from '../configuration';

export const QuickLinksItem = ({ text, image, url, openNewWindow, itemClass }) => {
  const nativeApp = AppConfiguration.isNativeApp();
  let fullUrl = !openNewWindow ? `${AppConfiguration.getSiteRootUrl()}${url}` : url;
  let target = '_self';
  if (nativeApp && openNewWindow) {
    fullUrl = `newtab:${fullUrl}`;
  } else if (openNewWindow && !nativeApp) {
    target = '_blank';
  }

  return (
    <div className={itemClass}>
      <a href={fullUrl} target={target}>
        <div className="quick-link-card">
          <div className="quick-links-group">
            <div className="quick-links-group-item">
              <Image variant="top" draggable="false" className="img" src={image} />
            </div>
            <div className="quick-links-group-item">
              <h3 className="quick-links-title">{text}</h3>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};
