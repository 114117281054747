import { PingMessage } from './messages/pingMessage';

export function startPingService(socket, intervalMs) {
  const pingInterval = setInterval(() => {
    sendPing(socket);
  }, intervalMs);
  return pingInterval;
}

export function stopPingService(pingInterval) {
  clearInterval(pingInterval);
}

const sendPing = socket => {
  if (socket && socket.closed === false) {
    const message = new PingMessage();
    socket.next(message);
  }
};
