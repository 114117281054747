import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addAttestation } from '../members/attestations';
import moment from 'moment';
import { ErrorBoundry } from '../shared';
import { SelfCheckerQuestionnaire } from './selfCheckerQuestionnaire';
import { SelfCheckerHistory } from './history';
import { SelfCheckerCertificate } from './selfCheckerCertificate';
import { questions } from './questionnaire';
import * as attestationBody from './attestationBody';
import { CoronaVirusSelfCheckerType } from '../members/attestations';
import * as checkerStatusTypes from './checkerStatusTypes';
import { AttestationForm } from './attestationForm';
import { ErrorCard } from '../shared/error';
import './coronavirusSelfCheckerView.scss';
import { setIn } from 'formik';

const selfcheckerPanel = 'selfcheckerPanel';
const historyPanel = 'historyPanel';

export const CoronavirusSelfCheckerView = () => {
  const attestationHistory = useSelector(
    (state) => state.memberAttestations.byType[CoronaVirusSelfCheckerType] || null
  );
  const member = useSelector((state) => state.members);
  const members = member ? member.member : [];
  const attestations = attestationHistory ? attestationHistory.attestations : [];
  const dispatch = useDispatch();
  const canAttest = attestationHistory ? attestationHistory.canAttest : true;
  const showTemperatureField = attestationHistory ? attestationHistory.showTemperature : true;
  const isLoading = useSelector((state) => state.memberAttestations.loading);
  const error = useSelector((state) => state.memberAttestations.error || state.memberAttestations.addError);
  const [selectedTab, setSelectedTab] = useState(selfcheckerPanel);
  const [temperature, setTemperature] = useState(98.6);
  const [temperatureUnit, setTemperatureUnit] = useState(checkerStatusTypes.fahrenheit);
  const [cdcResult, setCdcResult] = useState(null);
  const [certificate, setCertificate] = useState(false);
  const viewCertificateRef = useRef(null);
  const rangeValueRef = useRef(null);
  const attestationText = attestationBody.attestationText;
  const [index, setIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(0);
  const [showQuestions, setShowQuestions] = useState(true);
  const [answers, setAnswers] = useState(questions);
  const currentQuestion = questions[index];

  const submittedTemperature =
    temperatureUnit === checkerStatusTypes.fahrenheit
      ? temperature
      : getSubmittedTemperature(temperatureUnit, temperature);

  // display error card
  if (error) {
    return (
      <ErrorCard
        message="An error occurred while loading Return Pass, technical support has been
      notified."
      />
    );
  }

  const handleTabClick = (key) => {
    setSelectedTab(key);
  };

  const handleAnswers = (selection) => {
    return answers.map((answer) => {
      if (currentQuestion.id === answer.id) {
        return (answer.answer = selection);
      }
      return setAnswers([...answers]);
    });
  };
  const handlePrevQuestion = () => {
    if (previousIndex) {
      setIndex(previousIndex);
    }
    if (previousIndex === index) {
      setIndex(previousIndex - 1);
      setPreviousIndex(null);
    }
    if (!previousIndex) {
      setIndex(index - 1);
    }
  };

  const handleQuestionRedirects = () => {
    switch (index) {
      case 0:
        if (answers[index].answer === 'no') {
          setIndex(index + 1);
        }
        break;
      case 1:
        if (answers[index].answer === 'no') {
          setPreviousIndex(index);
          setIndex(2);
        }
        break;
      case 2:
        if (answers[index].answer === 'yes') setIndex(3);
        else {
          setIndex(9);
        }
        setPreviousIndex(2);
        break;
      case 3:
        if (answers[index].answer === 'yes') {
          setIndex(9);
          setPreviousIndex(3);
        }
        break;
      case 4:
        setPreviousIndex(4);
        if (answers[2].answer === 'no') {
          if (answers[4].answer === 'no') setIndex(7);
          else setIndex(5);
        } else {
          if (answers[4].answer === 'no') setIndex(9);
          else {
            if (answers[3].answer === 'yes') setIndex(3);
            if (answers[4].answer === 'yes' && answers[3].answer === 'no') setIndex(5);
            else {
              setIndex(8);
            }
          }
        }
        break;
      case 5:
        if (answers[index].answer === 'yes') {
          setIndex(6);
        }
        setPreviousIndex(1);
        break;
      case 6:
        if (answers[index].answer === 'yes') {
          if (answers[2].answer === 'no') {
            setIndex(7);
          } else {
            setIndex(8);
          }
        }
        setPreviousIndex(2);
        break;
      case 7:
        if (answers[index].answer === 'no') {
          setIndex(8);
          setPreviousIndex(5);
        }
        break;
      case 8:
        if (answers[index].answer === 'yes') {
          setIndex(9);
          setPreviousIndex(9);
        }
        break;
      default:
        break;
    }

    if (
      answers[0].answer === 'yes' ||
      answers[1].answer === 'yes' ||
      answers[5].answer === 'no' ||
      answers[6].answer === 'no' ||
      answers[7].answer === 'yes'
    ) {
      return dispatch(
        addAttestation(CoronaVirusSelfCheckerType, attestationText, checkerStatusTypes.stayHome, submittedTemperature)
      );
    }
  };

  const currentCertDate = attestations.length > 0 ? moment(attestations[0].signOffDateTimeUtc).format('LL') : null;
  const currentCertTime =
    attestations.length > 0 ? moment(attestations[0].signOffDateTimeUtc).format('h:mm a').toLocaleUpperCase() : null;
  const currentCertName = members ? members.firstName + ' ' + members.lastName : null;

  const tabContent = () => {
    //Start Self-Checker Panel & canAttest is false
    if (selectedTab === selfcheckerPanel && attestations.length > 0 && !canAttest) {
      return (
        <ErrorBoundry>
          <SelfCheckerCertificate
            certName={currentCertName}
            certDate={currentCertDate}
            certTime={currentCertTime}
            certHeader={
              attestations[0].result === checkerStatusTypes.stayHome
                ? attestationBody.notClearedHeader
                : attestations[0].result === checkerStatusTypes.safeToWork
                ? attestationBody.clearedHeader
                : attestations[0].result === checkerStatusTypes.getCare
                ? attestationBody.seekCareHeader
                : null
            }
            certHeaderText={
              attestations[0].result === checkerStatusTypes.stayHome ? (
                <strong>NOT cleared to Return to Work</strong>
              ) : attestations[0].result === checkerStatusTypes.safeToWork ? (
                <strong>Cleared to Return to Work</strong>
              ) : attestations[0].result === checkerStatusTypes.getCare ? (
                <strong>Seek Emergency Care</strong>
              ) : null
            }
            certBody={
              attestations[0].result === checkerStatusTypes.stayHome
                ? attestationBody.notClearedBodyText
                : attestations[0].result === checkerStatusTypes.safeToWork
                ? attestationBody.clearedBodyText
                : attestations[0].result === checkerStatusTypes.getCare
                ? attestationBody.seekCareBodyText
                : null
            }
          />
        </ErrorBoundry>
      );
    }
    //Start Self-Checker Panel & canAttest is true
    if (selectedTab === selfcheckerPanel && canAttest) {
      if (certificate) {
        return (
          <ErrorBoundry>
            <SelfCheckerCertificate
              certName={currentCertName}
              certDate={currentCertDate}
              certTime={currentCertTime}
              certHeader={
                cdcResult === checkerStatusTypes.stayHome
                  ? attestationBody.notClearedHeader
                  : cdcResult === checkerStatusTypes.safeToWork
                  ? attestationBody.clearedHeader
                  : cdcResult === checkerStatusTypes.getCare
                  ? attestationBody.seekCareHeader
                  : null
              }
              certHeaderText={
                cdcResult === checkerStatusTypes.stayHome ? (
                  <strong>NOT cleared to Return to Work</strong>
                ) : cdcResult === checkerStatusTypes.safeToWork ? (
                  <strong>Cleared to Return to Work</strong>
                ) : cdcResult === checkerStatusTypes.getCare ? (
                  <strong>Seek Emergency Care</strong>
                ) : null
              }
              certBody={
                cdcResult === checkerStatusTypes.stayHome
                  ? attestationBody.notClearedBodyText
                  : cdcResult === checkerStatusTypes.safeToWork
                  ? attestationBody.clearedBodyText
                  : cdcResult === checkerStatusTypes.getCare
                  ? attestationBody.seekCareBodyText
                  : null
              }
            />
          </ErrorBoundry>
        );
      }

      return (
        <ErrorBoundry>
          <SelfCheckerQuestionnaire
            current={currentQuestion}
            yes={(e) => {
              e.preventDefault();
              handleAnswers('yes');
              handleQuestionRedirects();
            }}
            no={() => {
              if (index < 9) {
                handleAnswers('no');
                handleQuestionRedirects();
              }
            }}
            back={() => {
              handlePrevQuestion();
            }}>
            {index === 9 && selectedTab === selfcheckerPanel ? getAttestationForm() : null}
          </SelfCheckerQuestionnaire>
        </ErrorBoundry>
      );
    }

    // Start History Panel
    if (selectedTab === historyPanel) {
      return (
        <section>
          <ErrorBoundry>
            <SelfCheckerHistory />
          </ErrorBoundry>
        </section>
      );
    }
  };

  const getAttestationForm = () => {
    if (canAttest) {
      return (
        <AttestationForm
          loading={isLoading}
          rangeValueRef={rangeValueRef}
          cdcResult={
            temperatureUnit === checkerStatusTypes.fahrenheit && temperature > 100.3
              ? checkerStatusTypes.stayHome
              : temperatureUnit === checkerStatusTypes.celsius && temperature > 37.9
              ? checkerStatusTypes.stayHome
              : checkerStatusTypes.safeToWork
          }
          temperature={temperature}
          temperatureUnit={temperatureUnit}
          submittedTemperature={
            temperatureUnit === checkerStatusTypes.fahrenheit
              ? temperature
              : getSubmittedTemperature(temperatureUnit, temperature)
          }
          showQuestions={showQuestions}
          handleCert={() => {
            setCertificate(true);
          }}
          fahrenheitClicked={() => {
            setTemperatureUnit(checkerStatusTypes.fahrenheit);
            let temp = (temperature * 9) / 5 + 32;
            setTemperature(temp.toFixed(1));
          }}
          celciusClicked={() => {
            setTemperatureUnit(checkerStatusTypes.celsius);
            let temp = (temperature - 32) / 1.8;
            setTemperature(temp.toFixed(1));
          }}
          rangeValueOnChange={(e) => {
            setTemperature(e.target.value);
          }}
          rangeOnChange={(e) => {
            let temp = Number(e.target.value).toFixed(1);
            setTemperature(temp);
          }}
          stepUpClicked={() => {
            let temp = Number(rangeValueRef.current.value);
            setTemperature((temp + 0.1).toFixed(1));
          }}
          stepDownClicked={() => {
            let temp = Number(rangeValueRef.current.value);
            setTemperature((temp - 0.1).toFixed(1));
          }}
        />
      );
    }
  };

  return (
    <div className="covid-self-checker-view">
      <div className="row intro noprint">
        <div ref={viewCertificateRef} className="columns medium-8">
          {showQuestions
            ? showTemperatureField
              ? attestationBody.introText
              : attestationBody.hideTemperatureIntroText
            : attestationBody.shortenedQuestionnaireIntroText}
        </div>
      </div>
      <div className="row">
        <nav className="col-md-12 col-sm-12 cdc-nav noprint">
          <a
            href="noscript:void(0);"
            onClick={(e) => {
              e.preventDefault();
              handleTabClick(selfcheckerPanel);
            }}
            className={selectedTab === selfcheckerPanel ? 'active noprint' : ''}>
            TRACKER
          </a>
          <a
            href="noscript:void(0);"
            onClick={(e) => {
              e.preventDefault();
              handleTabClick(historyPanel);
            }}
            className={selectedTab === historyPanel ? 'active noprint' : ''}>
            HISTORY
          </a>
        </nav>
      </div>
      <div className="container-fluid">
        <hr className="noprint" />
      </div>
      <div className="row justify-content-md-center">
        <div className="columns medium-9 panel-container rw0">{tabContent()}</div>

        {/* end of questionnaire */}
      </div>
      {/*  end of responsive row */}
    </div>
  );
};

const getSubmittedTemperature = (temperatureUnit, temperature) => {
  if (temperatureUnit === checkerStatusTypes.celsius && temperature !== '') {
    let temp = (temperature * 9) / 5 + 32;
    return temp.toFixed(1);
  }
};
