import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Overlay, Popover, Button } from 'react-bootstrap';
import { FaCaretDown } from 'react-icons/fa';
import { TodoList } from './todoList';
import { AppConfiguration } from '../configuration';
import { ErrorBoundry, ErrorCard } from '../shared';
import './todoDropDown.scss';



export const TodoDropDown = () => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  let { todos, error, loading } = useSelector((state) => state.todos);

  if (loading) {
    return (
      <>
        <div className="spinner-grow spinner-grow-sm todo-spinner text-primary" role="status"></div>
        <div className="spinner-grow spinner-grow-sm todo-spinner text-primary" role="status"></div>
        <div className="spinner-grow spinner-grow-sm todo-spinner text-primary" role="status"></div>
      </>
    );
  }
  if (todos && !error && todos.length <= 0) return null;
  return (
    <ErrorBoundry>
      <div className="todo-dropdown" ref={ref}>
        <Button
          variant="outline-primary"
          onClick={(e) => {
            setShow(!show);
            setTarget(e.target);
          }}
          className="todo-button">
          <span className="todo-button-text">MY TO-DO LIST</span>
          <span className="todo-button-text uOfL">Point Suggestions</span>{' '}
          <FaCaretDown size="1.5em" style={{ pointerEvents: 'none' }} />
        </Button>

        <Overlay show={show} target={target} placement="bottom-end" container={ref.current} containerPadding={0}>
          <Popover id="popover-contained" className="background-blur">
            <Popover.Content>
              {!error ? <TodoList /> : <ErrorCard message="Eror loading To-Do List" />}
              {todos.length > 5 ? (
                <a className="hollow button viewToDos" href={getTodoLink()}>
                  See All
                </a>
              ) : null}
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
    </ErrorBoundry>
  );
};

const getTodoLink = () => {
  return `${AppConfiguration.getSiteRootUrl()}ToDo`;
};
