import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';
import * as types from './actionTypes';

const loadEpisodesSuccess = (episodes) => {
  return {
    type: types.LOAD_EPISODES_SUCCESS,
    episodes
  };
};

const loadEpisodesFailed = () => {
  return {
    type: types.LOAD_EPISODES_FAILED
  };
};

export const loadEpisodes = (logError) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get('healthnavigator/episode');
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        let episodes = apiResult.data.result;
        episodes = addEpisodeNameToSearchTerms(episodes);
        resolve(dispatch(loadEpisodesSuccess(episodes)));
      } catch (error) {
        logError('healthnavigator/episodeActions', 'loadEpisodes', error);
        resolve(dispatch(loadEpisodesFailed()));
      }
    });
  };
};

const addEpisodeNameToSearchTerms = (episodes) => {
  if (episodes) {
    episodes.forEach((episode) => {
      if (episode.searchTerms && episode.searchTerms.length > 0 && episode.name) {
        episode.searchTerms.push(episode.name);
      }
    });
  }
  return episodes;
};
