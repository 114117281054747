import { ApiClient } from '../api';
import { AppConfiguration } from '../configuration';
import { getSessionToken } from '../session';
import * as types from './actionTypes';

export function loadMember(logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return dispatch => {
    return new Promise(async resolve => {
      try {
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get('member');
        const member = getMemberFromResult(apiResult.data.Result);
        resolve(dispatch(loadMemberSuccess(member)));
      } catch (error) {
        logError('members/actions', 'loadMember', error);
        resolve(dispatch(loadMemberFailed()));
      }
    });
  };
}

const getMemberFromResult = result => {
  const member = {
    addressLine1: result.AddressLine1,
    addressLine2: result.AddressLine2,
    alternatePhone: result.AlternatePhone,
    birthDate: new Date(result.BirthDate),
    city: result.City,
    countryCode: result.CountryCode,
    demoAccount: result.DemoAccount,
    firstName: result.FirstName,
    gender: result.Gender,
    id: result.Id,
    lastName: result.LastName,
    middleName: result.MiddleName,
    prefix: result.Prefix,
    primaryEmail: result.PrimaryEmail,
    primaryPhone: result.PrimaryPhone,
    state: result.State,
    stateAbbreviation: result.StateAbbreviation,
    suffix: result.Suffux,
    vipMember: result.VIPMember,
    zipCode: result.ZipCode
  };
  return member;
};

export const loadMemberSuccess = member => {
  return {
    type: types.LOAD_MEMBER_SUCCESS,
    member: member
  };
};

export const loadMemberFailed = () => {
  return { type: types.LOAD_MEMBER_FAILED };
};
