import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QuickLinksView } from './quickLinksView';

export const loadQuickLinksComponent = store => {
  const quickLinksComponent = document.getElementById('quickLinksComponent');
  if (quickLinksComponent) {
    ReactDOM.render(
      <Provider store={store}>
        <QuickLinksView />
      </Provider>,
      quickLinksComponent
    );
  }
};
