import { getCurrentProgram } from '../programs';

const OP_OR = 'OP_OR';
const OP_GRPREQD = 'OP_GRPREQD';
const OP_GRPMAX = 'OP_GRPMAX';
const defaultSectionName = 'Other Ways to Participate';
const IN_NoSpous = 'IN_NoSpous';
const buttonLinkIncentiveCodes = [
  'IN_Waist',
  'IN_TtlChol',
  'IN_HdlChol',
  'IN_BldPres',
  'IN_CholRat',
  'IN_LdlChol',
  'IN_GlocsNF',
  'IN_GlucsF',
  'IN_Triglyc',
  'IN_BMI',
  'IN_HbA1c',
  'IN_PSA',
  'IN_BMIYoY',
  'IN_TChlYoY',
  'IN_HdlYoY',
  'IN_BpYoY',
  'IN_FGlYoY',
  'IN_RGlYoy',
  'IN_HbA1YoY'
];

export const loadSectionData = (
  sections,
  memberIncentiveScores,
  incentiveProgramId,
  incentiveRuleDetails,
  incentiveButtonLinks,
  incentiveLinkedRules,
  incentiveProgramOrgId
) => {
  let sectionsByProgamId = sections.byProgramId[incentiveProgramId];

  //filter incentive rules with no sections assigned
  let incentiveRules = incentiveRuleDetails.byProgramId[incentiveProgramId].filter((item) => {
    return item.sectionId != null && item.incentiveCode != IN_NoSpous;
  });

  const buttonLinks = incentiveButtonLinks.byIpoid[incentiveProgramOrgId];
  const linkedRules = incentiveLinkedRules.byProgramId[incentiveProgramId];

  const defaultSection = sectionsByProgamId.filter(
    (section) => section.sectionName.toLowerCase() === defaultSectionName.toLowerCase()
  )[0];

  const scoresMap = memberIncentiveScores.byIpoid[incentiveProgramOrgId].reduce((scoresMap, score) => {
    scoresMap[score.incentiveRuleId] = {
      points: score.rollupPoints
    };
    return scoresMap;
  }, {});

  sectionsByProgamId.sort((a, b) => (a.priority > b.priority ? 1 : -1));
  let sectionsList = sectionsByProgamId.reduce((sectionsList, section) => {
    sectionsList[section.sectionId] = {
      sectionName: section.sectionName,
      priority: section.priority,
      rules: []
    };
    return sectionsList;
  }, {});

  const buttonLinksMap = buttonLinks.reduce((buttonLinksMap, link) => {
    buttonLinksMap[link.incentiveRuleId] = {
      url: link.url,
      buttonName: link.buttonName,
      buttonType: link.buttonType
    };
    return buttonLinksMap;
  }, {});

  // hook up linked rules in sections
  if (linkedRules.length > 0) {
    let result = hookLinkedRulesForSections(
      sectionsList,
      defaultSection,
      linkedRules,
      incentiveRules,
      buttonLinksMap,
      scoresMap
    );
    incentiveRules = result.incentiveRules;
    sectionsList = result.sectionsList;
  }

  //get individual incentives rules
  incentiveRules.forEach((rule) => {
    const buttonLink = {
      buttonName: '',
      url: '',
      buttonType: ''
    };

    let incentiveButtonLink = buttonLinksMap[rule.incentiveRuleId];
    if (incentiveButtonLink && !buttonLinkIncentiveCodes.includes(rule.incentiveCode)) {
      buttonLink.buttonName = incentiveButtonLink.buttonName;
      buttonLink.url = incentiveButtonLink.url;
      buttonLink.buttonType = incentiveButtonLink.buttonType;
    }

    let incentiveScore = scoresMap[rule.incentiveRuleId];

    if (incentiveScore && incentiveScore.points >= rule.maxPoints) {
      return;
    }

    if (sectionsList[rule.sectionId] && sectionsList[rule.sectionId].rules) {
      sectionsList[rule.sectionId].rules.push(getIncentiveRule(rule, buttonLink));
    }
  });

  sectionsList = sortSectionsList(sectionsList);

  //push all rules from different sections to one list
  let noSectionsList = { 0: { sectionName: '', rules: [] } };

  for (const [sectionId, section] of Object.entries(sectionsList)) {
    if (noSectionsList[0] && noSectionsList[0].rules)
      noSectionsList[0].rules = noSectionsList[0].rules.concat(section.rules);
  }

  noSectionsList = sortSectionsList(noSectionsList);

  const rulesCount = noSectionsList[0].rules.length;

  return {
    sectionsList: rulesCount < 15 ? noSectionsList : sectionsList,
    buttonLinks: buttonLinks,
    rulesCount: rulesCount
  };
};

const hookLinkedRulesForSections = (
  sectionsList,
  defaultSection,
  linkedRules,
  incentiveRules,
  buttonLinksMap,
  scoresMap
) => {
  linkedRules.forEach((linkedRule) => {
    // get rules from incentive rules that are linked
    let groupedRules = incentiveRules.filter((rule) => rule.incentiveRuleLinkId === linkedRule.incentiveRuleLinkId);
    if (groupedRules.length > 0) {
      // get button details for the linked rules
      let result = getDetailsForLinkedRule(groupedRules, buttonLinksMap, scoresMap, linkedRule);
      let required = linkedRule.operatorLookupCode === OP_GRPREQD || result.required;
      //check if the rules are from same section
      let sameSectionId = groupedRules.every((rule) => rule.sectionId === groupedRules[0].sectionId);
      //if same section, push to that section else push to 'Other Ways to Participate' section
      let sectionId = sameSectionId ? groupedRules[0].sectionId : defaultSection.sectionId;
      if (sectionsList[sectionId] && sectionsList[sectionId].rules && !result.maxMet)
        sectionsList[sectionId].rules.push(
          getIncentiveRule(groupedRules[0], result.buttonLink, linkedRule, result.dueDate, required)
        );
      //remove linked rules from the main incentive rules
      incentiveRules = incentiveRules.filter((rule) => rule.incentiveRuleLinkId != linkedRule.incentiveRuleLinkId);
    }
  });
  return {
    incentiveRules: incentiveRules,
    sectionsList: sectionsList
  };
};

const getDetailsForLinkedRule = (groupedRules, buttonLinksMap, scoresMap, linkedRule) => {
  const buttonDetails = {
    buttonName: '',
    url: '',
    buttonType: ''
  };
  let maxMet = false;
  let dueDate = '';
  let required = false;
  let buttonFound = false,
    dueDateFound = false,
    requiredFound = false,
    i = 0,
    groupScore = 0;

  while (i < groupedRules.length) {
    let incentiveScore = scoresMap[groupedRules[i].incentiveRuleId];

    if (incentiveScore) {
      if (linkedRule.operatorLookupCode == OP_OR || linkedRule.operatorLookupCode === OP_GRPREQD) {
        maxMet = incentiveScore.points >= groupedRules[i].pointValue;
      } else if (linkedRule.operatorLookupCode === OP_GRPMAX) {
        groupScore = groupScore + incentiveScore.points;
      }
    }

    if (!buttonFound && !buttonLinkIncentiveCodes.includes(groupedRules[i].incentiveCode)) {
      let buttonLink = buttonLinksMap[groupedRules[i].incentiveRuleId];
      if (buttonLink) {
        buttonFound = true;
        buttonDetails.buttonName = buttonLink.buttonName;
        buttonDetails.url = buttonLink.url;
        buttonDetails.buttonType = buttonLink.buttonType;
      }
    }
    if (!dueDateFound && groupedRules[i].ruleOverrideEndDate) {
      dueDateFound = true;
      dueDate = groupedRules[i].ruleOverrideEndDate;
    }
    if (!requiredFound && groupedRules[i].required) {
      requiredFound = true;
      required = true;
    }
    i++;
  }

  if (linkedRule.operatorLookupCode === OP_GRPMAX && linkedRule.groupMaxPoints != null) {
    maxMet = groupScore >= linkedRule.groupMaxPoints;
  }
  return {
    buttonLink: buttonDetails,
    dueDate: dueDate,
    required: required,
    maxMet: maxMet
  };
};

const getIncentiveRule = (rule, buttonLink, linkedRule, dueDate, required) => {
  return {
    incentiveruleId: rule.incentiveRuleId,
    maxPoints: linkedRule && linkedRule.operatorLookupCode !== OP_OR ? linkedRule.groupMaxPoints : rule.maxPoints,
    name: linkedRule ? linkedRule.incentiveRuleLinkName : rule.name,
    pointValue: rule.pointValue,
    priority: rule.priority,
    required: required ? required : rule.required,
    buttonName: buttonLink.buttonName,
    buttonUrl: buttonLink.url,
    buttonType: buttonLink.buttonType,
    description: linkedRule ? linkedRule.incentiveRuleLinkDesc : rule.description,
    dueDate: dueDate ? dueDate : rule.ruleOverrideEndDate,
    operatorLookupCode: linkedRule ? linkedRule.operatorLookupCode : ''
  };
};

const sortSectionsList = (sectionsList) => {
  for (const [sectionId, section] of Object.entries(sectionsList)) {
    section.rules.sort(ruleCompare);
  }
  return sectionsList;
};

const ruleCompare = (rule1, rule2) => {
  if (!rule1 || !rule2) return 0;
  if (rule1.required && !rule2.required) return -1;
  if (!rule1.required && rule2.required) return 1;
  if (rule1.required && rule2.required) {
    if (rule1.operatorLookupCode && !rule2.operatorLookupCode) return 1;
    if (!rule1.operatorLookupCode && rule2.operatorLookupCode) return -1;
    if (rule1.operatorLookupCode && rule2.operatorLookupCode) {
      if (rule1.operatorLookupCode === rule2.operatorLookupCode) return 0;
      if (rule1.operatorLookupCode == OP_GRPREQD) return -1;
      if (rule2.operatorLookupCode == OP_GRPREQD) return 1;
      if (rule1.operatorLookupCode == OP_GRPMAX) return -1;
      if (rule2.operatorLookupCode == OP_GRPMAX) return 1;
    }
  }
  if (rule1.operatorLookupCode && !rule2.operatorLookupCode) return -1;
  if (!rule1.operatorLookupCode && rule2.operatorLookupCode) return 1;
  if (rule1.operatorLookupCode && rule2.operatorLookupCode) {
    if (rule1.operatorLookupCode === rule2.operatorLookupCode) return 0;
    if (rule1.operatorLookupCode == OP_GRPREQD) return -1;
    if (rule2.operatorLookupCode == OP_GRPREQD) return 1;
    if (rule1.operatorLookupCode == OP_GRPMAX) return -1;
    if (rule2.operatorLookupCode == OP_GRPMAX) return 1;
  }
  if (rule1.name > rule2.name) return 1;
  if (rule1.name < rule2.name) return -1;
  return 0;
};
