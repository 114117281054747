export const LOAD_CHAT = 'LOAD_CHAT';
export const LOAD_CHAT_SUCCESS = 'LOAD_CHAT_SUCCESS';
export const LOAD_CHAT_FAILED = 'LOAD_CHAT_FAILED';
export const SHOW_MENU_CHAT = 'SHOW_MENU_CHAT';
export const SHOW_WELLNESS_CHAT = 'SHOW_WELLNESS_CHAT';
export const HIDE_CHAT = 'HIDE_CHAT';
export const LOAD_HOLD_MESSAGES = 'LOAD_HOLD_MESSAGES';
export const APPEND_MESSAGE = 'APPEND_INFO_MESSAGE';
export const RESET_CHAT_SESSION = 'RESET_CHAT_SESSION';
export const UPDATE_AGENT_IS_TYPING = 'UPDATE_AGENT_IS_TYPING';
export const UPDATE_PARTICIPANTS = 'UPDATE_PARTICIPANTS';
export const UPDATE_AGENTS_AVAILABLE = 'UPDATE_AGENTS_AVAILABLE';
export const UPDATE_ESTIMATED_WAIT_TIME = 'UPDATE_ESTIMATED_WAIT_TIME';
