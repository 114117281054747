import React from 'react';
import { ChatMessageType } from './chatMessageType';
import { MessageHeader } from './messageHeader';

const bubbleClassMap = {};
bubbleClassMap[ChatMessageType.agentMessageType] = 'ha-bubble msg';
bubbleClassMap[ChatMessageType.userMessageType] = 'user-bubble msg';

const infoClassMap = {};
infoClassMap[ChatMessageType.agentMessageType] = 'info agent';
infoClassMap[ChatMessageType.userMessageType] = 'info user';

export const ChatMessage = props => {
  return (
    <div className="wrapper">
      <div className={infoClassMap[props.message.type]}>
        <MessageHeader
          name={props.message.name}
          type={props.message.type}
          time={props.message.time}
        />
      </div>
      <div className={bubbleClassMap[props.message.type]}>
        <p>{props.message.text}</p>
      </div>
    </div>
  );
};
