import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { MyPointsUtView } from './myPointsUtView';
import { loadIncentiveProgramStatus } from '../../widget/actions';

export const loadMyPointsUtComponent = (store, logError) => {
  const myPointsElement = document.getElementById('myPointsUtComponent');
  if (myPointsElement) {
    store.dispatch(loadIncentiveProgramStatus(logError));
    ReactDOM.render(
      <Provider store={store}>
        <MyPointsUtView />
      </Provider>,
      myPointsElement
    );
  }
};
