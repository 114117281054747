import React, { Component } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import 'promise-polyfill/src/polyfill';
import { Button } from 'react-bootstrap';
import altHALogo from './img/haLogo.png';
import hpLogo from './img/haLogoRx.jpg';
import cardBG from './img/rxCardBG.png';
import scissorOne from './img/scissorOne.png';
import scissorTwo from './img/scissorTwo.png';
// do nto make this a function....component reqires a class
export class RxCardContent extends Component {
  generatePDF() {
    //Hide Download Button for PDF
    document.getElementById('downloadCardBtn').style.display = 'none';
    //Create screenshot of the card in svg
    html2canvas(document.querySelector('#capture')).then((canvas) => {
      document.body.appendChild(canvas).style.display = 'none';
      //Re-show Download Button
      document.getElementById('downloadCardBtn').style.display = 'inline';
    });
    //convert svg to png
    const input = document.getElementById('capture');
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    // });
    //convert png to pdf
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('RxSaverCard.pdf');
    });
  }

  windowPrint() {
    window.print();
  }

  render() {
    return (
      <div id="capture">
        <h1>Save money on your prescriptions, compliments of Health Advocate!</h1>
        <p className="centerP">
          We’re pleased to offer you Rx Shopper to help you find the lowest prices for your prescriptions.
          <strong>
            Download and print your card, then bring it with you when filling your prescription to save up to 80%.
          </strong>
          <br />
          {/* <span className="rxEmphasis">This card is accepted at over 35,000 pharmacies nationwide!</span> */}
        </p>
        <Button variant="primary" onClick={this.generatePDF} id="downloadCardBtn">
          Download my card!
        </Button>
        <Button variant="primary" onClick={this.windowPrint} id="printCardBtn">
          Print my card!
        </Button>
        {/* <p className="clickPrint">
          Click
          <a href="#" onClick={this.windowPrint}>
            &nbsp; here&nbsp;
          </a>
          to print out your card.
        </p> */}
        <div className="cardHolder">
          <img src={scissorOne} className="scissorOne" alt="Scissor" />
          <img src={scissorTwo} className="scissorTwo" alt="Scissor" />
          <div className="foldLine">&#x2014; Fold</div>

          <div className="rxCardFront">
            <img src={cardBG} className="rxCardBackground" alt="Rx Card" />
            <img src={altHALogo} className="haLogoCard" alt="Health Advocate Logo" />
            <h1>Rx Shopper</h1>
            {/* <div className="rxName">
              <span>{this.props.cardName}</span>
            </div> */}
            <div className="rxGroup">
              <span className="boldLable">Group: </span>HARX<span>{this.props.organizationId}</span> <br />
            </div>
            <div className="rxBIN">
              <span className="boldLable">BIN: </span>610301
            </div>
            <div className="rxPCN">
              <span className="boldLable">PCN: </span>HD
            </div>
            <div className="rxmemNum">
              <span className="boldLable">Member Number: </span>1120012002<span></span> <br />
            </div>
            <div className="rxCardFrontFine">
              This is not insurance. It is a prescription discount program. This card is free.
            </div>
          </div>
          <div className="rxCardBack">
            {/* <h3>
              This card is accepted at over <br /> 35,000 pharmacies nationwide!
            </h3> */}
            <h4>
              Members: If you have questions or need <br />
              assitance, please call 877.783.6772
            </h4>
            <div>
            By using this card, you have agreed to the terms and conditions
            found at <a href="https://glichealth.com/terms-and-conditions">https://glichealth.com/terms-and-conditions</a>. The
            GlicHealth Program is administered by Glic Health LLC,
            One Marina Park Drive, Suite 1410, Boston, MA.
            </div>
            <div className="rxCardBackFine">
              Pharmacist Instructions: <br />
              This card is pre-activated and provides discounts through the BIN, PCN and <br /> Group. If you need
              assitance, please call 877.800.7820
            </div>
          </div>
        </div>
        <div className="RxFooterText">
          <img src={hpLogo} className="hpLogo" alt="HA Logo" />
          <div className="footerLeft">
            <span className="haHeartcon"></span>{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.healthadvocate.ui&hl=en_US"
              className="androidIcon"
              target="_blank"
              rel="noopener noreferrer">
              {' '}
            </a>{' '}
            <a
              href="https://apps.apple.com/us/app/health-advocate/id561029373"
              className="iosIcon"
              target="_blank"
              rel="noopener noreferrer">
              {' '}
            </a>{' '}
            <br />
            <strong>We're not an insurance company.</strong> Health Advocate is not a direct healthcare provider and is
            not affiliated with any insurance company or thrid party provider. 2020 health Advocate HA-M-2007020-1CRD
          </div>
        </div>
      </div>
    );
  }
}
