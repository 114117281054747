import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';
import * as types from './actionTypes';

const loadTierDetailsSuccess = (programId, tierDetails) => {
  return {
    type: types.LOAD_TIERDETAILS_SUCCESS,
    data: { programId, tierDetails }
  };
};

const loadTierDetailsFailed = () => {
  return {
    type: types.LOAD_TIERDETAILS_FAILED
  };
};

export const loadTierDetails = (programId, logError) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get(`incentiveprogram/${programId}/tierdetail`);
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        resolve(dispatch(loadTierDetailsSuccess(programId, apiResult.data.result)));
      } catch (error) {
        logError('incentives/TierDetails/actions', 'loadTierDetails', error);
        resolve(dispatch(loadTierDetailsFailed()));
      }
    });
  };
};
