import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { CoronavirusSelfCheckerView } from './coronavirusSelfCheckerView';
import { loadAttestations, CoronaVirusSelfCheckerType } from '../members/attestations';
import { ErrorBoundry } from '../shared';

export const loadCoronavirusSelfChecker = store => {
  const coronavirusSelfCheckerComponent = document.getElementById('coronavirusSelfCheckerComponent');
  if (coronavirusSelfCheckerComponent) {
    store.dispatch(loadAttestations(CoronaVirusSelfCheckerType));
    ReactDom.render(
      <Provider store={store}>
        <ErrorBoundry>
          <CoronavirusSelfCheckerView />
        </ErrorBoundry>
      </Provider>,
      coronavirusSelfCheckerComponent
    );
  }
};
