import * as types from './actionTypes';

const initialState = {
  list: [],
  loading: true,
  error: false
};

export const biometricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_BIOMETRICS_SUCCESS:
      return {
        ...state,
        list: action.biometrics,
        loading: false,
        error: false
      };
    case types.LOAD_BIOMETRICS_FAILED:
      return {
        ...state,
        list: [],
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
