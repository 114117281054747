import * as types from './actionTypes';
import { ApiClient } from '../../api';
import { AppConfiguration } from '../../configuration';
import { updateSessionToken, getSessionToken } from '../../session';

export function loadIncentiveProgramStatus(logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return (dispatch) => {
    return new Promise((resolve) => {
      var client = new ApiClient(apiRootUrl, sessionToken);
      client.get('member/incentiveprogram/status').then(
        (apiResult) => {
          if (apiResult.data.sessionToken) {
            updateSessionToken(apiResult.data.sessionToken);
          }
          resolve(dispatch(loadIncentiveProgramStatusSuccess(apiResult.data.result)));
        },
        (error) => {
          logError('incentive/actions', 'loadIncentiveProgramStatus', error);
          resolve(dispatch(loadIncentiveProgramStatusFailed()));
        }
      );
    });
  };
}

export function loadIncentiveProgramStatusSuccess(details) {
  return { type: types.LOAD_INCENTIVE_PROGRAM_STATUS_SUCCESS, details };
}

export function loadIncentiveProgramStatusFailed() {
  return { type: types.LOAD_INCENTIVE_PROGRAM_STATUS_FAILED };
}
