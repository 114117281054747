import { RateLimiter } from '../shared';
import axios from 'axios';
import { AppConfiguration } from '../configuration';
import { updateSessionToken } from '../session';

const keepAliveIntevalMs = 1000;
const keepAliveLimiter = new RateLimiter(keepAliveIntevalMs);

// Asp.net 'keep alive' is a temporary solution until more of HP becomes react code and we
// manage session in a different manner.  For now this will keep the forms auth session going
export const keepAlive = () => {
  keepAliveLimiter.limit(() => {
    const pingUrl = `${AppConfiguration.getSiteRootUrl()}Account/PingSession`;
    axios.get(pingUrl).then((result) => {
      resetTimeoutWarningTimer();
      if (result.data.sessionToken) {
        updateSessionToken(result.data.sessionToken);
      }
    });
  });
};

// Check for the global reset warning function established in healthypath, call it if it exists.
// This is to keep the sliding window going for the pop-up that warns the user about the session
// expiration
const resetTimeoutWarningTimer = () => {
  if (window.resetTimeoutTimer) {
    window.resetTimeoutTimer();
  }
};
