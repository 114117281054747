import { ApiVersion } from './apiVersion';
import { MessageTypes } from './messageTypes';

export class ChatMessage {
  constructor(message) {
    this.apiVersion = ApiVersion;
    this.type = MessageTypes.request;
    this.body = {
      method: 'newMessage',
      message: message
    };
  }
}
