import * as types from './actionTypes';

const initialState = {
  loading: true,
  error: false,
  byType: [],
  result: null,
  adding: false,
  addError: false
};

export const memberAttestationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_ADD_ATTESTATION:
      return {
        ...state,
        result: null,
        adding: true,
        addError: false
      };
    case types.ADD_ATTESTATION_SUCCESS:
      let attestations = state.byType[action.attestation.type]
        ? state.byType[action.attestation.type].attestations
        : [];
      attestations = [action.attestation, ...attestations];
      return {
        ...state,
        result: action.attestation,
        byType: {
          [action.attestation.type]: {
            attestations: attestations,
            canAttest: false
          }
        },
        adding: false,
        addError: false
      };
    case types.ADD_ATTESTATION_FAILED:
      return {
        ...state,
        result: null,
        adding: false,
        addError: true
      };
    case types.REQUEST_LOAD_ATTESTATIONS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case types.LOAD_ATTESTATIONS_SUCCESS:
      return {
        ...state,
        byType: { [action.data.type]: action.data.attestations },
        loading: false,
        error: false
      };
    case types.LOAD_ATTESTATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
