import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from 'react-foundation';
import { ChatMessage, InfoMessage, ChatMessageType } from '../chatmessage';
import { appendUserMessage } from '../store';
import { keepAlive } from '../../session';
import './chatViewComponent.scss';

class ChatViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMessage: '',
      currentDateString: moment(new Date()).format('LL')
    };
  }

  componentDidMount() {
    this.messagesEnd = React.createRef();
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messagesEnd && this.messagesEnd.current) {
      this.messagesEnd.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  getAgentTypeingAnimation = () => {
    return this.props.agentTyping ? (
      <div className="wrapper">
        <div className="ha-bubble msg typing">
          <span>&#183;</span>
          <span>&#183;</span>
          <span>&#183;</span>
        </div>
      </div>
    ) : null;
  };

  render() {
    return (
      <>
        <div className={this.state.waiting ? 'agent-name-time error' : 'agent-name-time'}>
          {this.state.currentDateString}
        </div>
        <div className="chat-window scrollable">
          {this.props.messages.map((message) =>
            message.type === ChatMessageType.infoMessageType ? (
              <InfoMessage message={message} key={message.id} />
            ) : (
              <ChatMessage message={message} key={message.id} />
            )
          )}
          {this.getAgentTypeingAnimation()}
          <div ref={this.messagesEnd} />
        </div>
        <div className="bottom-reply">
          <form onSubmit={this.handleSubmit}>
            <div className="input-group">
              <input
                type="text"
                maxLength="1000"
                disabled={this.canSendChat() ? null : 'disabled'}
                placeholder="Type here"
                value={this.state.currentMessage}
                onChange={(e) => {
                  this.handleChatInputChange(e);
                }}
                className="input-group-field"
              />
              <div className="input-group-button">
                <Button
                  inline="true"
                  disabled={this.canSendChat() ? null : 'disabled'}
                  className="send-message"
                  variant="primary"
                  type="submit">
                  Send
                </Button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }

  canSendChat() {
    return this.props.participants.length > 0;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const message = this.state.currentMessage;
    if (message && message.length > 0) {
      if (process.env.NODE_ENV !== 'development') {
        keepAlive();
      }
      this.props.sendMessage(message);
      this.props.appendUserMessage(message, this.props.messages.length + 1);
      this.setState({ currentMessage: '' });
    }
  };

  handleChatInputChange = (event) => {
    this.setState({ currentMessage: event.target.value });
    this.props.userIsTyping(true);
  };
}

const mapStateToProps = (state) => {
  return {
    ...state.chat
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    appendUserMessage: (message, index) => dispatch(appendUserMessage(message, index))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatViewComponent);
