import React, { Component } from 'react';
import { getAppUrl, AppConfiguration } from '../configuration';

export class ScheduleWellnessCoachTodoItem extends Component {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();
  }

  render() {
    return (
      <a
        href={this.props.todo.Href}
        target="_blank"
        rel="noopener noreferrer"
        ref={getAppUrl(this.anchorRef)}
        onClick={e => {
          this.launchTimeTradeWorkflow(e);
        }}>
        {this.props.todo.Title ? <p>{this.props.todo.Title}</p> : null}
        {this.props.todo.ShortInfo ? (
          <div>{this.props.todo.ShortInfo}</div>
        ) : null}
      </a>
    );
  }

  launchTimeTradeWorkflow(event) {
    if (!AppConfiguration.isNativeApp()) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (window.tt) {
      return window.tt.launchWorkflow(this.anchorRef.current);
    } else {
      console.error('tt library is not defined');
    }
  }
}
