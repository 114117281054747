export class PhoneNumber {
  constructor(phoneNumberString) {
    this.areaCode = '';
    this.prefix = '';
    this.lineNumber = '';
    if (phoneNumberString) {
      let cleanPhone = phoneNumberString
        .trim()
        .split('')
        .map(x => (isNaN(parseInt(x, 10)) ? null : x))
        .filter(x => x !== null)
        .join('');
      if (cleanPhone.length === 10) {
        this.areaCode = cleanPhone.substr(0, 3);
        this.prefix = cleanPhone.substr(3, 3);
        this.lineNumber = cleanPhone.substr(6, 4);
      } else if (cleanPhone.length === 7) {
        this.areaCode = '';
        this.prefix = cleanPhone.substr(0, 3);
        this.lineNumber = cleanPhone.substr(3, 4);
      }
    }
  }
  toString() {
    return this.areaCode
      ? `${this.areaCode}-${this.prefix}-${this.lineNumber}`
      : `${this.prefix}-${this.lineNumber}`;
  }
}
