import React, { useState } from 'react';
import { FaxHistoryCard } from '../history';
import { SendFaxView } from './sendFaxView';
import { ErrorBoundry } from '../../shared';
import './biometricsFaxView.scss';

const sendPanel = 'sendPanel';
const historyPanel = 'historyPanel';

export const BiometricsFaxView = () => {
  const [selectedView, setSeletedView] = useState(sendPanel);
  /* Disable lint warnings for this view */
  /* complaining about about anchor href & aria but
   foudnation tabs not functioning properly when lint advice used. */
  /* eslint-disable */

  return (
    <div className="biometrics-fax-view">
      <ul className="tabs" id="fax-tabs">
        <li
          onClick={e => {
            e.preventDefault();
            setSeletedView(sendPanel);
          }}
          className={`tabs-title ${
            selectedView === sendPanel ? 'is-active' : ''
          }`}>
          <a href="#" aria-selected={selectedView === sendPanel}>
            Send Biometrics
          </a>
        </li>
        <li
          onClick={e => {
            e.preventDefault();
            setSeletedView(historyPanel);
          }}
          className={`tabs-title ${
            selectedView === sendPanel ? 'is-active' : ''
          }`}>
          <a href="#" aria-selected={selectedView === historyPanel}>
            History
          </a>
        </li>
      </ul>
      <div className="tabs-content">
        <div
          id="sendPanel"
          className={`tabs-panel ${
            selectedView === sendPanel ? 'is-active' : ''
          }`}>
          <ErrorBoundry>
            <SendFaxView />
          </ErrorBoundry>
        </div>
        <div
          id="historyPanel"
          className={`tabs-panel ${
            selectedView === historyPanel ? 'is-active' : ''
          }`}>
          <ErrorBoundry>
            <FaxHistoryCard />
          </ErrorBoundry>
        </div>
      </div>
    </div>
  );
  /* eslint-enable */
};
