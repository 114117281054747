import { logError } from '../../logging';
import * as types from './actionTypes';
import { updateSessionToken } from '../../session';
import { ApiClient } from '../../api';
import { AppConfiguration } from '../../configuration';
import { getSessionToken } from '../../session';

export const loadFaxRecipients = () => {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return dispatch => {
    return new Promise(async resolve => {
      const apiClient = new ApiClient(apiRootUrl, sessionToken);
      try {
        const apiResult = await apiClient.get('member/faxrecipient');
        if (apiResult.data.SessionToken) {
          updateSessionToken(apiResult.data.SessionToken);
        }
        if (apiResult.data && apiResult.data.Result) {
          const result = convertRecipientList(apiResult.data.Result);
          resolve(dispatch(loadFaxRecipientsSuccess(result)));
        } else {
          resolve(dispatch(loadFaxRecipientsFailed()));
        }
      } catch (error) {
        logError('faxes/recipients/actions', 'loadFaxRecipients', error);
        resolve(dispatch(loadFaxRecipientsFailed()));
      }
    });
  };
};

export const loadFaxRecipientsSuccess = faxRecipients => {
  return {
    type: types.LOAD_FAX_RECIPIENTS_SUCCESS,
    faxRecipients: faxRecipients
  };
};

const convertRecipientList = results => {
  return !results ? [] : results.map(x => convertRecipient(x));
};

const convertRecipient = x => {
  return {
    memberId: x.MemberId,
    firstName: x.FirstName,
    lastName: x.LastName,
    faxNumber: x.FaxNumber,
    memberFaxRecipientId: x.MemberFaxRecipientId,
    dateCreated: x.dateCreated
  };
};

export const loadFaxRecipientsFailed = () => {
  return { type: types.LOAD_FAX_RECIPIENTS_SUCCESS };
};

export const requestAddRecipient = () => {
  return { type: types.REQUEST_ADD_FAX_RECIPIENT };
};

export const addFaxRecipient = newRecipient => {
  return dispatch => {
    return new Promise(async resolve => {
      let sessionToken = getSessionToken();
      const apiRootUrl = AppConfiguration.getApiRootUrl();
      const apiClient = new ApiClient(apiRootUrl, sessionToken);
      try {
        dispatch(requestAddRecipient());
        const apiResult = await apiClient.post('member/faxrecipient', {
          firstName: newRecipient.firstName,
          lastName: newRecipient.lastName,
          faxNumber: newRecipient.faxNumber
        });
        if (apiResult.data.SessionToken) {
          updateSessionToken(apiResult.data.SessionToken);
        }
        if (apiResult.status === 200) {
          if (apiResult.data && apiResult.data.Result) {
            const result = convertRecipient(apiResult.data.Result);
            resolve(dispatch(addFaxRecipientSuccess(result)));
          } else {
            resolve(dispatch(addFaxRecipientFailure()));
          }
        }
      } catch (error) {
        if (error.response.status === 409) {
          dispatch(setConflictStatus());
          return;
        }
        logError('faxes/recipients/actions', 'addFaxRecipient', error);
        resolve(dispatch(addFaxRecipientFailure()));
      }
    });
  };
};

export const setConflictStatus = () => {
  return {
    type: types.SET_RECIPIENT_CONFLICT_STATUS
  }
}

export const resetConflictStatus = () => {
  return {
    type: types.RESET_RECIPIENT_CONFLICT_STATUS
  }
}

export const addFaxRecipientSuccess = newRecipient => {
  return {
    type: types.ADD_FAX_RECIPIENT_SUCCESS,
    newRecipient: newRecipient
  };
};

export const addFaxRecipientFailure = () => {
  return {
    type: types.ADD_FAX_RECIPIENT_FAILED
  };
};

export const requestUpdateRecipient = () => {
  return { type: types.REQUEST_UPDATE_FAX_RECIPIENT };
};

export const updateFaxRecipient = recipient => {
  return dispatch => {
    return new Promise(async resolve => {
      let sessionToken = getSessionToken();
      const apiRootUrl = AppConfiguration.getApiRootUrl();
      const apiClient = new ApiClient(apiRootUrl, sessionToken);
      try {
        dispatch(requestUpdateRecipient());
        const apiResult = await apiClient.put('member/faxrecipient', {
          firstName: recipient.firstName,
          lastName: recipient.lastName,
          faxNumber: recipient.faxNumber,
          memberFaxRecipientId: recipient.memberFaxRecipientId,
          active: recipient.active
        });
        if (apiResult.data.SessionToken) {
          updateSessionToken(apiResult.data.SessionToken);
        }
        if (apiResult.data && apiResult.data.Result) {
          const result = convertRecipient(apiResult.data.Result);
          resolve(
            dispatch(
              updateFaxRecipientSuccess(result, recipient.memberFaxRecipientId)
            )
          );
        } else {
          resolve(dispatch(updateFaxRecipientFailure()));
        }
      } catch (error) {
        logError('faxes/recipients/actions', 'updateFaxRecipient', error);
        resolve(dispatch(updateFaxRecipientFailure()));
      }
    });
  };
};

export const updateFaxRecipientSuccess = (updateRecipient, recipientId) => {
  return {
    type: types.UPDATE_FAX_RECIPIENT_SUCCESS,
    updateRecipient: updateRecipient,
    recipientId: recipientId
  };
};

export const updateFaxRecipientFailure = () => {
  return {
    type: types.UPDATE_FAX_RECIPIENT_FAILED
  };
};

export const requestRemoveRecipient = () => {
  return { type: types.REQUEST_REMOVE_FAX_RECIPIENT };
};

export const removeFaxRecipient = recipientId => {
  return dispatch => {
    return new Promise(async resolve => {
      let sessionToken = getSessionToken();
      const apiRootUrl = AppConfiguration.getApiRootUrl();
      const apiClient = new ApiClient(apiRootUrl, sessionToken);
      try {
        dispatch(requestRemoveRecipient());
        const apiResult = await apiClient.delete(
          `member/faxrecipient/${recipientId}`
        );
        if (apiResult.status === 200) {
          resolve(dispatch(removeFaxRecipientSuccess(recipientId)));
        } else {
          resolve(dispatch(removeFaxRecipientFailure()));
        }
      } catch (error) {
        logError('faxes/recipients/actions', 'removeFaxRecipient', error);
        resolve(dispatch(removeFaxRecipientFailure()));
      }
    });
  };
};

export const removeFaxRecipientSuccess = recipientId => {
  return {
    type: types.REMOVE_FAX_RECIPIENT_SUCCESS,
    recipientId: recipientId
  };
};

export const removeFaxRecipientFailure = () => {
  return {
    type: types.REMOVE_FAX_RECIPIENT_FAILED
  };
};
