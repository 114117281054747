import { webSocket } from 'rxjs/webSocket';
import { Subject } from 'rxjs';
import { startPingService, stopPingService } from './pingService';
import {
  MessageTypes,
  Intrinsics,
  NotificationMessageType,
  LoginMessage,
  ChatMessage,
  CloseRequest,
  UserIsTypingMessage,
  QueueStatusRequestMessage
} from './messages';

export class AvayaChatService {
  constructor(chatServiceUrl, pingIntervalMs) {
    this.pingIntervalId = null;
    this.socket = null;
    this.chatServiceUrl = chatServiceUrl;
    this.openObservable = null;
    this.closeObservable = null;
    this.messageObservable = null;
    this.socketObservable = null;
    this.pingInterval = null;
    this.pingIntervalMs = pingIntervalMs;
    this.authenticationKey = null;
    this.guid = null;
  }

  connect() {
    this._createSocket();
    return {
      openObservable: this.openObservable,
      closeObservable: this.closeObservable,
      messageObservable: this.messageObservable,
      errorObservable: this.errorObservable
    };
  }

  _createSocket() {
    this.openObservable = new Subject();
    this.closeObservable = new Subject();
    this.messageObservable = new Subject();
    this.errorObservable = new Subject();
    this.socket = new webSocket({
      url: this.chatServiceUrl,
      openObserver: this.openObservable,
      closeObserver: this.closeObservable
    });
    this.socketObservable = this.socket.subscribe(
      (msg) => {
        this._logServerMessage(msg);
        this._socketMessageHandler(msg, this.socketMessageObservable);
        if (this.messageObservable && !this.messageObservable.closed) {
          this.messageObservable.next(msg);
        }
      },
      (err) => {
        if (this.errorObservable && !this.errorObservable.closed) {
          this.errorObservable.next(err);
        }
      }
    );
    this.openObservable.subscribe(() => {
      this.pingIntervalId = startPingService(this.socket, this.pingIntervalMs);
    });
    this.closeObservable.subscribe(() => {
      stopPingService(this.pingIntervalId);
    });
  }

  // Note: use for debugging messages from Avaya server (filtering pings)
  _logServerMessage(msg) {
    return; // No-op unless needed - eventually remove this completely
    //if (msg && msg.body && msg.body.method !== 'ping') {
    //console.log(JSON.stringify(msg));
    //}
  }

  login(email, userName, areaCode, phoneNumber, skillSet, memberId, organizationId, topicText) {
    let intrinsics = new Intrinsics(
      email,
      userName,
      areaCode,
      phoneNumber,
      skillSet,
      memberId,
      organizationId,
      topicText
    );
    let message = new LoginMessage(null, null, false, intrinsics);
    this._sendMessage(message);
  }

  sendChatMessage(messageText) {
    let message = new ChatMessage(messageText);
    this._sendMessage(message);
  }

  sendUserIsTyping(isTyping) {
    let message = new UserIsTypingMessage(isTyping);
    this._sendMessage(message);
  }

  sendQueueStatusRequest() {
    let message = new QueueStatusRequestMessage();
    this._sendMessage(message);
  }

  quitChat() {
    if (this.socket) {
      let message = new CloseRequest();
      this._sendMessage(message);
      this._disconnect();
    }
  }

  _disconnect() {
    if (this.pingInterval) {
      stopPingService(this.pingIntervalId);
    }
    this.socket.complete();
    this.openObservable = null;
    this.closeObservable = null;
    this.messageObservable = null;
    this.socketObservable = null;
    this.socket = null;
  }

  _socketMessageHandler = (message) => {
    if (message.type === MessageTypes.notification) {
      this._notificationMessageHandler(message);
      return;
    }
    if (message.type === MessageTypes.error) {
      this._errorMessageHandler(message);
      return;
    }
  };

  _notificationMessageHandler = (message) => {
    if (message.type !== MessageTypes.notification) {
      return;
    }
    let body = message.body;
    let method = body.method;
    if (method === NotificationMessageType.requestChat) {
      this.authenticationKey = body.authenticationKey;
      this.guid = body.guid;
    }
  };

  _errorMessageHandler = (message) => {
    console.error(`Received socket error message of type: ${message.type}`);
    console.error(message);
    this.errorObservable.next(message);
  };

  _sendMessage(message) {
    if (this.socket !== null && this.socket.closed === false) {
      this.socket.next(message);
    } else {
      const message = 'Failed to send chat message, web socket null or not in open state';
      console.error(message);
      this.errorObservable.next(message);
    }
  }
}
