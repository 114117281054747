import React, { useState, useContext } from 'react';
import {
  Container,
  Col,
  Row,
  Accordion,
  Button,
  Spinner,
  Alert,
  Pagination,
  AccordionContext,
  useAccordionToggle
} from 'react-bootstrap';
import { ToolTip } from './tooltip';
import './pointsEarned.scss';
import { logError } from '../../logging';
import { loadMemberScoreDetails } from '../memberScoreDetails';
import { getAppUrl, AppConfiguration } from '../../configuration';
import { useSelector, useDispatch } from 'react-redux';
import { loadPointsEarned } from './loadPointsEarned';
import { getAntiForgeryToken } from '../../antiforgery';
import { ErrorBoundry } from '../../shared';
import _ from 'lodash';
import moment from 'moment';

export const PointsEarned = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filterDate, setFilterDate] = useState(0);
  const [ruleId, setRuleId] = useState('');
  const loading = useSelector((state) => loadingSelector(state));

  const { incentiveRules, incentiveButtonLinks, memberIncentiveScores, memberScoreDetails } = useSelector(
    (state) => state
  );

  const dispatch = useDispatch();
  const scoreRollups = loadPointsEarned(
    props.activeProgram,
    incentiveRules,
    incentiveButtonLinks,
    memberIncentiveScores
  );

  if (loading) {
    return <Spinner animation="border" size="lg" variant="primary" />;
  }

  if (scoreRollups.length < 1) {
    return (
      <Alert variant="secondary" className="text-center points-earned-alert">
        You have not earned any points. Click on How to Earn to get started.
      </Alert>
    );
  }

  const pageSize = 10;
  const pastDays = moment.utc().tz('America/New_York').subtract(filterDate, 'days');

  const memScoreDetails =
    memberScoreDetails.byIpoid[props.ipoid] && memberScoreDetails.byIpoid[props.ipoid][ruleId] && ruleId != ''
      ? memberScoreDetails.byIpoid[props.ipoid][ruleId]
      : [];
  const rulesCount = memScoreDetails.filter((rules) => {
    if (moment(rules.eventDate).utc().tz('America/New_York').isAfter(pastDays)) {
      return rules;
    }
    if (filterDate < 1) {
      return rules;
    }
  }).length;
  const pagination = getPagination(rulesCount, pageSize, currentPage, setCurrentPage);
  const paginationData = getPaginationData(memScoreDetails, currentPage, pageSize, filterDate);

  return (
    <ErrorBoundry>
      <div className="tabContent points-earned">
        <Accordion>
          <Container className="pointsGrid">
            <Row>
              <Col md={4} xs={6}>
                Activity
              </Col>
              <Col md={2} className="outcomeColPE">
                Outcome
              </Col>
              <Col md={2} xs={3}>
                Points
              </Col>
              <Col md={2} className="dateColPE">
                Date
              </Col>
              <Col md={2} xs={3}></Col>
            </Row>
            {/* show all points earned data */}
            {scoreRollups.map((score, index) => {
              //show button links
              const postUrl = `${AppConfiguration.getSiteRootUrl()}${'Home'}/${'TobaccoAffidavit'}`;
              const tobaccoAffidavitUrl = `${AppConfiguration.getSiteRootUrl()}${'Wellness'}/${'Secure/TobaccoOath/Default.aspx'}`;
              const verificationTokenName = '__RequestVerificationToken';
              const token = getAntiForgeryToken();
              const sectionButton =
                score.buttonName != '' ? (
                  <a href={getAppUrl(score.buttonUrl)} className="btn btn-primary">
                    {score.buttonName}
                  </a>
                ) : (
                  ''
                );
              let form = null;
              /*Show accordion and rule details */
              if (score.showViewDetails) {
                let ruleId = score.incentiveRuleId;

                return (
                  <Row key={index} className={`aco${index}`}>
                    <Col className="rollup-rules-col">
                      <Row>
                        <Col md={4} xs={6}>
                          {score.description != '' ? (
                            <ToolTip tooltipContent={score.description} tooltipTriggerContent={score.ruleName} />
                          ) : (
                            score.ruleName
                          )}
                        </Col>
                        <Col md={2} className={'outcomeColPE ' + score.incentiveTypeLookupCode}>
                          {score.value}
                        </Col>
                        <Col md={2} xs={3} className="pointsColumn">
                          {score.points === 0 && score.maxPoints === 0 ? 'Complete' : score.points}
                          {score.points != 0 && score.maxPoints != 0 ? (
                            <span className="maxPts">{`Max of ${score.maxPoints}`}</span>
                          ) : null}
                        </Col>
                        <Col md={2} className="dateColPE">
                          various
                        </Col>
                        {/*Dispatch redux action to fetch rollup rules*/}
                        <Col md={2} xs={3}>
                          <div
                            className={`aco${index}`}
                            onClick={() => {
                              dispatch(loadMemberScoreDetails(props.ipoid, ruleId, logError));
                              setCurrentPage(1);
                              setFilterDate(0);
                              setRuleId(ruleId.toString());
                            }}>
                            <ContextAwareToggle eventKey={index + 1}>Click me!</ContextAwareToggle>
                          </div>
                        </Col>
                      </Row>
                      <Accordion.Collapse eventKey={index + 1}>
                        <Container>
                          <Row className="selectDateRow">
                            <Col xs={3}>
                              <Button
                                variant="link"
                                onClick={() => {
                                  setFilterDate(7);
                                  setCurrentPage(1);
                                }}
                                className="date-range-link">
                                Last Week &#10148;
                              </Button>
                            </Col>
                            <Col xs={2}>
                              <Button
                                variant="link"
                                onClick={() => {
                                  setFilterDate(30);
                                  setCurrentPage(1);
                                }}
                                className="date-range-link">
                                Last 30 Days &#10148;
                              </Button>
                            </Col>
                            <Col xs={2}>
                              <Button
                                variant="link"
                                onClick={() => {
                                  setFilterDate(60);
                                  setCurrentPage(1);
                                }}
                                className="date-range-link">
                                Last 60 Days &#10148;
                              </Button>
                            </Col>
                            <Col xs={2}>
                              <Button
                                variant="link"
                                onClick={() => {
                                  setFilterDate(90);
                                  setCurrentPage(1);
                                }}
                                className="date-range-link">
                                Last 90 Days &#10148;
                              </Button>
                            </Col>
                            <Col className="active" xs={3}>
                              <Button
                                variant="link"
                                onClick={() => {
                                  setFilterDate(0);
                                  setCurrentPage(1);
                                }}
                                className="date-range-link">
                                All Available &#10148;
                              </Button>
                            </Col>
                          </Row>
                          <Row className="headerRowCollapse">
                            <Col md={6}>Activity</Col>
                            <Col md={3}>Date</Col>
                            <Col md={3}>Points Earned</Col>
                          </Row>
                          {/*Get rollup rules for pagination*/}
                          {memberScoreDetails.loading ? (
                            <Spinner animation="border" size="sm" variant="primary" />
                          ) : (
                            paginationData.map((rules, key) => {
                              return (
                                <Row key={key}>
                                  <Col md={6}>{rules.incentiveRuleName}</Col>
                                  <Col md={3}>{moment(rules.eventDate).format('MM/DD/YYYY')}</Col>
                                  <Col md={3} className="pointsColumn">
                                    {rules.points}
                                  </Col>
                                </Row>
                              );
                            })
                          )}

                          <Row className="justify-content-center rollup-pagination">{pagination}</Row>
                        </Container>
                      </Accordion.Collapse>
                    </Col>
                  </Row>
                );
              }
              return (
                <Row key={index}>
                  <Col md={4} xs={6}>
                    {score.description != '' ? (
                      <ToolTip tooltipContent={score.description} tooltipTriggerContent={score.ruleName} />
                    ) : (
                      score.ruleName
                    )}
                  </Col>
                  <Col md={2} className={'outcomeColPE ' + score.incentiveTypeLookupCode}>
                    {score.value}
                  </Col>
                  <Col md={2} xs={3} className="pointsColumn">
                    {score.points < 1 && score.maxPoints < 1 ? 'Complete' : score.points}
                    {score.points > 0 && score.maxPoints > 0 ? (
                      <span className="maxPts">{`Max of ${score.maxPoints}`}</span>
                    ) : null}
                  </Col>
                  <Col md={2} className="dateColPE">
                    {moment(score.lastActivityDate).format('MM/DD/YYYY')}
                  </Col>
                  {/*Check to see if button is Tobacco Affidavit*/}
                  <Col md={2} xs={3}>
                    {score.buttonUrl.toLowerCase().includes('tobaccoaffidavit') ? (
                      <form
                        action={postUrl}
                        id={score.incentiveruleId}
                        method="post"
                        ref={(ref) => {
                          form = ref;
                        }}>
                        <input id="href" name="Href" type="hidden" value={tobaccoAffidavitUrl} />
                        <input name={verificationTokenName} type="hidden" value={token || ''} />
                        <a
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            form.submit();
                          }}
                          href=""
                          className="btn btn-primary">
                          {score.buttonName}
                        </a>
                      </form>
                    ) : (
                      sectionButton
                    )}
                  </Col>
                </Row>
              );
            })}

            <Row className="totalRow">
              <Col md={4} xs={6}>
                Total
              </Col>
              <Col md={2} className="outcomeColPE"></Col>
              <Col md={2} xs={3} className="pointsColumn">
                {props.headerDetails.pointsEarned}
              </Col>
              <Col md={2} className="dateColPE"></Col>
              <Col md={2} xs={3}></Col>
            </Row>
          </Container>
        </Accordion>
      </div>
    </ErrorBoundry>
  );
};

const loadingSelector = (state) => {
  return (
    state.incentiveRules.loading ||
    state.incentiveButtonLinks.loading ||
    state.incentiveLinkedRules.loading ||
    state.memberIncentiveScores.loading
  );
};

//Render pagination based on number of member score details

const getPagination = (rulesCount, pageSize, currentPage, setCurrentPage) => {
  const pageCount = Math.ceil(rulesCount / pageSize);
  if (pageCount === 1) return null;

  const pages = _.range(1, pageCount + 1);
  return (
    <Pagination size="sm">
      {pages.map((page) => (
        <Pagination.Item
          href={null}
          key={page}
          active={page === currentPage}
          onClick={() => setCurrentPage(page)}
          className={page === currentPage ? 'active' : 'page-item'}>
          {page}
        </Pagination.Item>
      ))}
    </Pagination>
  );
};
//Render member score details list based on number of list
const getPaginationData = (memScoreDetails, currentPage, pageSize, filterDate) => {
  let pastDays = moment.utc().tz('America/New_York').subtract(filterDate, 'days');

  let filterMemScoreDetails = memScoreDetails.filter((rules) => {
    if (moment(rules.eventDate).utc().tz('America/New_York').isAfter(pastDays)) {
      return rules;
    }
    if (filterDate < 1) {
      return rules;
    }
  });

  const sortMemScoreDetails = filterMemScoreDetails.sort().reverse();
  const initialPage = (currentPage - 1) * pageSize;
  return sortMemScoreDetails.slice(initialPage, initialPage + 10);
};
//Custom accordion toggle with expansion awareness
const ContextAwareToggle = ({ eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);
  const handleClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button type="button" className="btn btn-link accordion-detial-toggle" onClick={handleClick}>
      {isCurrentEventKey ? 'Hide Details' : 'View Details'}
    </button>
  );
};
