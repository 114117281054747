import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ProcedureTable } from './procedureTable';
import { FullDetails } from './fullDetails';
import { FilterResults } from './filterResults';
import { Profile } from './profile';
import { FaTrophy } from 'react-icons/fa';
import { MdCheckCircle, MdStar } from 'react-icons/md';
import { Procedures } from './procedures';
import { Incentive } from './incentive';
import { useSelector } from 'react-redux';
import { SearchType } from '../searchType';
import { searchReferenceNumber, sortResults } from './helper';
import './facilityResults.scss';
import './results.scss';
import { formatPhoneNumber } from '../../shared';

export const FacilityResults = () => {
  const [setShow] = useState(false);
  const [isMobile, setMobile] = useState(window.innerWidth < 990);
  const { searchResults } = useSelector((state) => state.healthNavigator);

  const updateSreenSize = () => {
    setMobile(window.innerWidth < 990);
  };
  useEffect(() => {
    window.addEventListener('resize', updateSreenSize);
    return () => window.removeEventListener('resize', updateSreenSize);
  });
  const providerData = searchResults.providers && searchResults.providers !== null ? searchResults.providers : [];
  const getProcedureResults =
    searchResults.providerSearchType === SearchType.Facility ||
    searchResults.providerSearchType === SearchType.ByUniqueId
      ? providerData.map((result, index) => {
          const {
            uniqueKeyId,
            facilityName,
            locationType,
            distanceMiles,
            addressLine1,
            addressLine2,
            addressLine3,
            acceptingPatients,
            city,
            state,
            zip,
            phoneNumber,
            latitude,
            longitude,
            primarySpecialty,
            providerGenderDisplay,
            languageDisplay
          } = result;
          const sortIncentivesByName = [...result.procedureIncentives].sort(sortResults('procedureName'));
          const searchResultId = searchResults.searchResultId;

          const phoneNumberClicked = () => {
            ReactGa.event({
              category: 'ContactHA',
              action: 'Submit',
              label: 'Click to call'
            });
          };
          const address = (
            <>
              <span>{!addressLine1 ? '' : <span>{addressLine1}</span>}</span>
              <span>{!addressLine2 ? '' : <span>{addressLine2}</span>}</span>
              <span>{!addressLine3 ? '' : <span>{addressLine3}</span>}</span>
              <span>{city + ', ' + state + ' ' + zip}</span>
              <a onClick={phoneNumberClicked} href={`tel:${formatPhoneNumber(phoneNumber)}`}>
                {formatPhoneNumber(phoneNumber)}
              </a>
            </>
          );
          const isAcceptingPatients = acceptingPatients ? (
            <span>
              <MdCheckCircle size="1.5em" className="mdcc0" />
              <span> Accepting Patients</span>
            </span>
          ) : (
            ''
          );
          const firstIncentiveTableIcon =
            sortIncentivesByName[0].incentiveLevel === 'CenterOfExcellence' ? (
              <MdStar className="star" />
            ) : (
              <FaTrophy className="trophy" />
            );
          const providerFullDetails = {
            acceptingPatients: isAcceptingPatients,
            firstProcedureName: sortIncentivesByName[0].procedureName,
            firstIncentiveAmount: sortIncentivesByName[0].incentive,
            resultType: false,
            firstIncentiveRating: firstIncentiveTableIcon,
            providerSpecialties: !primarySpecialty ? '' : primarySpecialty,
            providerGenderDisplay: !providerGenderDisplay ? '' : providerGenderDisplay,
            languageDisplay: !languageDisplay ? '' : languageDisplay,
            proceduresCount: result.procedureIncentives.length
          };

          return (
            <div className="health-navigator-card" key={index}>
              <Col md={12} className="cd0">
                <Profile
                  providerFacilityName={!facilityName ? '' : facilityName}
                  providerServiceType={locationType}
                  providerAddress={address}
                  distanceAway={!distanceMiles ? 'View Map' : distanceMiles.toFixed(1) + ' Miles Away'}
                  providerLatitude={latitude}
                  providerLongitude={longitude}
                />
                {!isMobile ? (
                  <Procedures>
                    {result.procedureIncentives
                      ? sortIncentivesByName.map((procedure, index) => {
                          const incentiveTableIcon =
                            procedure.incentiveLevel === 'CenterOfExcellence' ? (
                              <MdStar className="star" title="Center of Excellence" />
                            ) : (
                              <FaTrophy className="trophy" title="Providers of Distinction" />
                            );
                          const incentiveTooltipMessage =
                            procedure.incentiveLevel === 'CenterOfExcellence'
                              ? 'The State of Connecticut has identified doctors, hospitals and medical care groups in the state that have verified high-quality outcomes for some of the most common medical conditions and procedures. Doctors and locations proven to meet these high standards are known as “Providers of Distinction.” These providers will appear first in the  Providers of Distinction Search, clearly marked by a silver trophy. When you choose a Provider of Distinction for a listed procedure, you will automatically receive a cash incentive in the mail. '
                              : 'Centers of Excellence should always be the first choice, however, there are many other high-quality doctors and locations that have demonstrated above-average high-quality care.  Those above-average high-quality doctors and locations are part of a “Providers of Distinction.” When using the Providers of Distinction Search, doctors and locations that earned the “Providers of Distinction” designation will appear immediately under the Centers of Excellence in your search results – noted by a silver seal.';

                          return (
                            <ProcedureTable
                              key={index}
                              procedureDisplay={index === 0 ? 'none' : 'table-cell'}
                              procedureName={procedure.procedureName}
                              incentiveAmount={procedure.incentive}
                              incentiveRating={incentiveTableIcon}
                              incentiveIconTooltip={incentiveTooltipMessage}
                            />
                          );
                        })
                      : null}
                  </Procedures>
                ) : null}
                {!isMobile ? (
                  <Incentive
                    searchResultId={searchResultId}
                    uniqueKeyId={uniqueKeyId}
                    patientCode={searchReferenceNumber(searchResultId)}
                    name={facilityName}
                    handleShow={() => setShow(true)}
                    travelIncentive=""
                  />
                ) : null}
              </Col>
              <Col md={12} className="more-details">
                <FullDetails key={index} providerFullDetails={providerFullDetails}>
                  {result.procedureIncentives
                    ? sortIncentivesByName.map((procedure, index) => {
                        const incentiveTableIcon =
                          procedure.incentiveLevel === 'CenterOfExcellence' ? (
                            <MdStar className="star" title="Center of Excellence" />
                          ) : (
                            <FaTrophy className="trophy" title="Providers of Distinction" />
                          );
                        const incentiveTooltipMessage =
                          procedure.incentiveLevel === 'CenterOfExcellence'
                            ? 'The State of Connecticut has identified doctors, hospitals and medical care groups in the state that have verified high-quality outcomes for some of the most common medical conditions and procedures. Doctors and locations proven to meet these high standards are known as “Providers of Distinction.” These providers will appear first in the Providers of Distinction Search, clearly marked by a silver trophy. When you choose a Provider of Distinction for a listed procedure, you will automatically receive a cash incentive in the mail. '
                            : 'Centers of Excellence should always be the first choice, however, there are many other high-quality doctors and locations that have demonstrated above-average high-quality care.  Those above-average high-quality doctors and locations are part of a “Providers of Distinction.” When using the Providers of Distinction Search, doctors and locations that earned the “Providers of Distinction” designation will appear immediately under the Centers of Excellence in your search results – noted by a silver seal.';

                        return (
                          <ProcedureTable
                            key={index}
                            procedureDisplay={index === 0 ? 'none' : 'table-cell'}
                            procedureName={procedure.procedureName}
                            incentiveAmount={procedure.incentive}
                            incentiveRating={incentiveTableIcon}
                            incentiveIconTooltip={incentiveTooltipMessage}
                          />
                        );
                      })
                    : null}
                </FullDetails>

                {isMobile ? (
                  <Incentive
                    searchResultId={searchResultId}
                    uniqueKeyId={uniqueKeyId}
                    patientCode={searchReferenceNumber(searchResultId)}
                    name={facilityName}
                    handleShow={() => setShow(true)}
                    travelIncentive=""
                  />
                ) : null}
              </Col>
            </div>
          );
        })
      : null;

  return (
    <div className="facility-results">
      <Container>
        <FilterResults providerResultCount={providerData.length} />
        <Row>
          {getProcedureResults.length > 0 ? (
            getProcedureResults
          ) : (
            <center style={{ margin: '16px auto' }}>
              <h6>New providers are added frequently, please check back or call a life navigator advocate.</h6>
            </center>
          )}
        </Row>
      </Container>
    </div>
  );
};
