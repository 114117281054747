// function to help get MVC antiforgery token when needed
export function getAntiForgeryToken() {
  var token = '';
  if (window.$) {
    var tokenField = window.$('[name= "__RequestVerificationToken"]').first();
    if (tokenField) {
      token = tokenField.val();
      if (!token) {
        console.warn('getAntiForgeryToken() - empty or null token.');
      }
    } else {
      console.warn(
        'getAntiForgeryToken() - __RequestVerificationToken was not found.'
      );
    }
  }
  return token;
}
