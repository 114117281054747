export const links = [
  {
    title: 'Return Pass',
    description: 'Daily coronavirus self-checker to be cleared to return to work',
    url: 'Health/ReturnPass',
    image: 'quicklinks_icon_return_pass.svg',
    priority: 1,
    requiredServices: ['ReturnPassAttestation'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'COVID Results',
    description: 'View results for your COVID test.',
    url: 'Home/BiometricScreening',
    image: 'quicklinks_icon_return_pass_results.svg',
    priority: 1,
    requiredServices: [],
    services: ['CovidAbTesting', 'CovidDxTesting'],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Report COVID Test Results',
    description: 'Report COVID Test Results',
    url: 'Health/CovidTest',
    image: 'quicklinks_icon_report_covid_results.svg',
    priority: 1,
    requiredServices: ['SelfReportCovidTest'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },

  {
    title: 'COVID Testing',
    description: 'Download a COVID Voucher or schedule an appointment to get tested.',
    url: 'Home/BiometricScreening',
    image: 'quicklinks_icon_return_pass_testing.svg',
    priority: 1,
    requiredServices: [],
    services: ['CovidAbTesting', 'CovidDxTesting'],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Resolve a Billing Issue',
    description: 'Dealing with insurance companies can be hard. Let us do it for you!',
    url: 'Finance/ClaimsResolution',
    image: 'quicklinks_icon_resolve_billing.svg',
    priority: 1,
    requiredServices: ['CoreAdvocacy'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Find a Doctor',
    description: 'Locate in-network providers.',
    url: 'Health/GetCare',
    image: 'quicklinks_icon_find_a_doctor.svg',
    priority: 2,
    requiredServices: ['CoreAdvocacy'],
    services: [],
    exclusionTypes: ['HealthgradesDoctorLocator'],
    openNewWindow: false
  },
  {
    title: 'Message an Advocate',
    description: 'Need help? We’re just a click away.',
    url: 'Home/Message',
    image: 'quicklinks_icon_message_advocate.svg',
    priority: 3,
    requiredServices: ['CoreAdvocacy'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Take the Personal Health Profile',
    description: 'Learn if you have any health risks.',
    url: 'Health/PersonalHealthProfile',
    image: 'quicklinks_icon_take_the_php.svg',
    priority: 4,
    requiredServices: ['Wellness', 'Wellness_PHP'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Join a Challenge',
    description: 'Challenge yourself to achieve your goals.',
    url: 'Wellbeing/Challenges',
    image: 'quicklinks_icon_join_challenge.svg',
    priority: 5,
    requiredServices: ['Wellness'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Sync My Fitness Device',
    description: 'Click here to sync your devices.',
    url: 'Home/Settings?section=fitness',
    image: 'quicklinks_icon_link_my_fitness_devices.svg',
    priority: 6,
    requiredServices: ['Wellness'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Log My Activity',
    description: 'Use health trackers to keep tabs on your progress.',
    url: 'Wellbeing/ChallengeTrackers',
    image: 'quicklinks_icon_log_my_activity.svg',
    priority: 7,
    requiredServices: ['Wellness'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Search for Recipes',
    description:
      "We'll help you take the guesswork out of healthy eating through delicious recipes and easy meal plans.",
    url: 'Wellbeing/Nutrition',
    image: 'quicklinks_icon_nutrition.svg',
    priority: 7,
    requiredServices: ['Nutrition_Meredith'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Find High-Quality, Low-Cost Providers',
    description: 'Compare quality and costs in your area.',
    url: 'Finance/HealthCostEstimatorPlus',
    image: 'quicklinks_icon_low_cost_providers.svg',
    priority: 1,
    requiredServices: ['HCEPlus'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },

  {
    title: 'Providers of Distinction Search',
    description: 'Find providers participating in the incentive program in Connecticut',
    url: 'Finance/healthnavigator',
    image: 'quicklinks_icon_find_a_doctor.svg',
    priority: 10,
    requiredServices: ['HealthNavigator'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Improve My Personal Finances',
    description: 'Start with a confidential assessment to learn where to begin.',
    url:
      'https://healthadvocate.personaladvantage.com/gateway.jsp?SingleSignIn:refid=<#REFID#>&SingleSignIn:target=/portal/content/10113924;subject=10000073',
    image: 'quicklinks_icon_improve_personal_finances.svg',
    priority: 1,
    requiredServices: ['FinancialWellness'],
    services: [],
    exclusionTypes: ['Finance'],
    openNewWindow: true
  },
  {
    title: 'Legal Center',
    description: 'Access state-specific legal forms and information.',
    url:
      'https://healthadvocate.personaladvantage.com/gateway.jsp?SingleSignIn:refid=<#REFID#>&SingleSignIn:target=subject/10000313;subject=10000313',
    image: 'quicklinks_icon_create_a_will.svg',
    priority: 1,
    requiredServices: [],
    services: ['EAP', 'SAP', 'WorkLife'],
    exclusionTypes: [],
    openNewWindow: true
  },
  {
    title: 'Telemedicine Consult',
    description: 'Connect to a MeMD medical provider instantly.',
    url: 'Shared/GetMeMdLogin',
    image: 'quicklinks_icon_telemedicine.svg',
    priority: 1,
    requiredServices: ['Telemedicine'],
    services: [],
    exclusionTypes: [],
    openNewWindow: true
  },
  {
    title: 'Telemedicine Consult',
    description: 'Connect to a MeMD medical provider instantly.',
    url: '',
    image: 'quicklinks_icon_telemedicine.svg',
    priority: 9,
    requiredServices: ['ExternalTelemedicineProvider'],
    services: [],
    exclusionTypes: [],
    openNewWindow: true
  },
  {
    title: 'Schedule a Flu Shot',
    description: 'Schedule your onsite flu shot with Health Advocate and view your flu shot information here.',
    url: 'Home/FluShot',
    image: 'quicklinks_icon_schedule_flu_shot.svg',
    priority: 8,
    requiredServices: ['FluShot'],
    services: [],
    exclusionTypes: ['FluShotCard'],
    openNewWindow: false
  },
  {
    title: 'Send Health Screening Results to Physician',
    description: 'Request your screening results to be faxed',
    url: 'Biometrics/Fax',
    image: 'quicklinks_icon_send_biometrics.svg',
    priority: 7,
    requiredServices: ['BiometricScreenings'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Schedule a Health Screening',
    description: 'Schedule an appointment, view results, or download forms for your Biometric Screening program.',
    url: 'Home/BiometricScreening',
    image: 'quicklinks_icon_schedule_biometric_screening.svg',
    priority: 9,
    requiredServices: ['BiometricScreenings'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Save $$ on Prescriptions',
    description: 'Save $$ on Prescriptions.',
    url: 'Finance/PrescriptionDiscountCard',
    image: 'quicklinks_icon_rxCard.svg',
    priority: 10,
    requiredServices: ['RxDiscountCard'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Health Education',
    description: '',
    url: 'https://www.healthwise.net/healthadvocate/Content/CustDocument.aspx?XML=STUB.XML&XSL=CD.FRONTPAGE.XSL',
    image: 'quicklinks_icon_health_education.svg',
    priority: 1,
    requiredServices: ['HealthwiseHealthTopics'],
    services: [],
    exclusionTypes: [],
    openNewWindow: true
  },
  {
    title: 'Decision Support',
    description: '',
    url: 'https://www.healthwise.net/healthadvocate/Content/StdDocument.aspx?DOCHWID=share',
    image: 'quicklinks_icon_decision_support.svg',
    priority: 1,
    requiredServices: ['HealthwiseDecisionPoints'],
    services: [],
    exclusionTypes: [],
    openNewWindow: true
  },
  {
    title: 'Symptom Checker',
    description: '',
    url: 'https://www.healthwise.net/healthadvocate/sxchecker/index',
    image: 'quicklinks_icon_symptom_checker.svg',
    priority: 1,
    requiredServices: ['HealthwiseSymptomChecker'],
    services: [],
    exclusionTypes: [],
    openNewWindow: true
  },
  {
    title: 'Practice Mindfulness & Reduce Stress',
    description: '',
    url: 'Wellbeing/Mindfulness',
    image: 'quicklinks_icon_mindfulness.svg',
    priority: 1,
    requiredServices: [],
    services: ['Wellness'],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Focus on Financial Health',
    description: '',
    url: 'Wellbeing/FinancialHealth',
    image: 'quicklinks_icon_financial_health.svg',
    priority: 1,
    requiredServices: [],
    services: ['Wellness'],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Achieve a Healthy Weight',
    description: '',
    url: 'Wellbeing/HealthyWeight',
    image: 'quicklinks_icon_healthy_weight.svg',
    priority: 1,
    requiredServices: [],
    services: ['Wellness'],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Improve My Eating Habits',
    description: '',
    url: 'Wellbeing/EatHealthy',
    image: 'quicklinks_icon_eat_healthy.svg',
    priority: 1,
    requiredServices: [],
    services: ['Wellness'],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Be More Active',
    description: '',
    url: 'Wellbeing/PhysicalActivity',
    image: 'quicklinks_icon_physical_activity.svg',
    priority: 1,
    requiredServices: [],
    services: ['Wellness'],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Report COVID Vaccination Status',
    description: '',
    url: 'Home/Message?source=vaccination',
    image: 'quicklinks_icon_upload_vacc_card.svg',
    priority: 1,
    requiredServices: ['CovidVaccinationForms'],
    services: [],
    exclusionTypes: [],
    openNewWindow: false
  },
  {
    title: 'Benefits Selection Tool',
    description: '',
    url: '',
    image: 'quicklinks-icon-benefit-selectiont.svg',
    priority: 1,
    requiredServices: ['PicWellSelectionTool'],
    services: [],
    exclusionTypes: [],
    openNewWindow: true
  }
];
