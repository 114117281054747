import * as types from './actionTypes';

const initialState = {
  list: [],
  loading: true,
  error: false,
  pending: false
};

export const faxHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_FAX_HISTORY_SUCCESS:
      return {
        ...state,
        list: action.faxHistory,
        pending: action.faxesPending,
        loading: false,
        error: false
      };
    case types.LOAD_FAX_HISTORY_FAILED:
      return {
        ...state,
        faxes: [],
        pending: false,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
