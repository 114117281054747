import 'core-js/stable';
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { configureStore } from './store';
import registerServiceWorker from './registerServiceWorker';
import { AppConfiguration } from './configuration';
import { getSessionToken } from './session';
import { loadSectionCardComponents } from './sectioncards/loadSectionCardComponents';
import { DebugAppComponent } from './debugApp';
import { loadIncentiveSummaryComponent } from './incentives/widget/loadIncentiveSummaryComponent';
import { loadMyPointsComponent, loadMyPointsUtComponent } from './incentives';
import { loadTodosComponent } from './todos/loadTodosComponent';
import { loadChatComponent } from './chat/loadChatComponent';
import { loadMember } from './members/actions';
import { loadOrganization } from './organizations/actions';
import { loadClient } from './clients/actions';
import { loadAnalytics } from './analytics/actions';
import { loadBiometricsFaxView } from './faxes';
import { logError } from './logging';
import { loadQuickLinksComponent } from './quicklinks';
import { loadHealthNavigator } from './healthnavigator';
import { loadClientServices } from './services';
import { loadOrganizationConfigurations } from './organizationConfigurations';
import { loadContentExclusions } from './contentExclusions';
import { loadCoronavirusSelfChecker } from './coronavirusselfchecker';
import { loadPrescriptionDiscountCard } from './prescriptionDiscountCard';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const appName = 'HealthyPath.WebComponents';
AppConfiguration.setAppName(appName);
const store = configureStore();

const loadComponents = (sessionToken) => {
  if (sessionToken) {
    store.dispatch(loadAnalytics(logError));
    store.dispatch(loadClient(logError));
    store.dispatch(loadMember(logError));
    store.dispatch(loadOrganization(logError));
    store.dispatch(loadClientServices(logError));
    store.dispatch(loadOrganizationConfigurations(logError));
    store.dispatch(loadContentExclusions(logError));
    loadSectionCardComponents(store, logError);
    loadTodosComponent(store, logError);
    loadIncentiveSummaryComponent(store, logError);
    loadChatComponent(store, logError);
    loadBiometricsFaxView(store, logError);
    loadHealthNavigator(store, logError);
    loadMyPointsComponent(store, logError);
    loadMyPointsUtComponent(store, logError); // TODO: Remove once Evan done user testing
    loadQuickLinksComponent(store, logError);
    loadCoronavirusSelfChecker(store);
    loadPrescriptionDiscountCard(store);
  }
};

// In development environment, test harness with debug app will be created to host components
if (process.env.NODE_ENV === 'development') {
  window.siteRootUrl = '/';
  AppConfiguration.setImagePath('/img/');
  ReactDOM.render(
    <Provider store={store}>
      <DebugAppComponent loadComponents={loadComponents} />
    </Provider>,
    document.getElementById('root')
  );
} else {
  // In production mode DOM target elements will exist in healthypath
  loadComponents(getSessionToken());
}

registerServiceWorker();
