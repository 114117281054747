import React, { Component } from 'react';
import { AppConfiguration, getAppUrl, getAppTarget } from '../configuration';

export class SectionCard extends Component {
  constructor(props) {
    super(props);
    this.cardRef = React.createRef();
  }

  componentDidMount() {
    this.props.addCardRef(this.cardRef);
  }

  render() {
    return (
      <div className="small-12 columns large-4 columns">
        <div className="linked card shadow" ref={this.cardRef}>
          {this.getCardImage(this.props.card.CardImageType)}
          <div className="card-section">
            <h3 className="cardtitle">{this.props.card.Title}</h3>
            <p className="cardtext">{this.props.card.Description}</p>
            {this.getViewLink()}
          </div>
        </div>
      </div>
    );
  }

  getViewLink() {
    var url = this.props.card.Url.replace(
      '~/',
      AppConfiguration.getSiteRootUrl()
    );
    if (this.props.card.IsModalWindow) {
      return (
        <a
          href={url}
          data-modal-content-url={url}
          className="button hollow modalButton"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}>
          {this.props.card.ButtonText}
        </a>
      );
    } else if (this.props.card.IsNewWindow) {
      return (
        <a
          href={getAppUrl(url)}
          target={getAppTarget('_blank')}
          className="button hollow"
          rel="noopener noreferrer">
          {this.props.card.ButtonText}
        </a>
      );
    } else {
      return (
        <a href={url} className="button hollow">
          {this.props.card.ButtonText}
        </a>
      );
    }
  }

  getCardImage(cardImageType) {
    const nutrition = 'nutrition';
    if (cardImageType) {
      if (cardImageType.toLowerCase() === nutrition) {
        const imageFilename = 'zipongoImageOne.jpg';
        return (
          <img alt="" src={AppConfiguration.getImagePath(imageFilename)} />
        );
      }
    }
    return null;
  }
}
