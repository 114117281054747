import React from 'react';
import { QuickLinksItems } from './quickLinksItems';
import './quickLinksView.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { ErrorBoundry } from '../shared';

export const QuickLinksView = () => {
  return (
    <ErrorBoundry>
      <Container className="quick-links-view">
        <Row>
          <Col>
            <h3>I would like to...</h3>
          </Col>
        </Row>
        <Row className="align-items-start">
          <QuickLinksItems />
        </Row>
      </Container>
    </ErrorBoundry>
  );
};
