import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Callout, Colors } from 'react-foundation';
import { MaterialSpinnerPanel, MaterialSpinner } from '../../shared';
import './faxHistoryCard.scss';
import { ErrorCard } from '../../shared/error';

export const FaxHistoryCard = () => {
  const faxHistory = useSelector(state => state.faxHistory);
  return (
    <div className="linked card shadow with-icon">
      <div className="card-divider background-health">
        <h3 className="cardtitle">History</h3>
      </div>
      <div className="card-section">
        <FaxHistoryTable faxHistory={faxHistory} />
      </div>
    </div>
  );
};

const EmptyHistory = () => {
  return (
    <Callout color={Colors.PRIMARY}>
      No biometrics faxes have been sent.
    </Callout>
  );
};

const FaxHistoryTable = props => {
  if (props.faxHistory.loading) {
    return <MaterialSpinnerPanel show={true} />;
  }
  if (props.faxHistory.error) {
    return (
      <ErrorCard
        message="An error occurred while loading fax history, technical support has been
      notified."
      />
    );
  }
  if (!props.faxHistory.list || !props.faxHistory.list.length) {
    return <EmptyHistory />;
  }
  return (
    <table className="history-table">
      <thead>
        <tr>
          <th>Fax Date</th>
          <th>Physician</th>
          <th>Screening Date(s)</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {props.faxHistory && props.faxHistory.list
          ? props.faxHistory.list.map(x => (
              <HistoryRow key={x.memberFaxId} fax={x} />
            ))
          : null}
      </tbody>
    </table>
  );
};

const HistoryRow = props => {
  return (
    <tr>
      <td>{moment(props.fax.memberSignOffDate).format('MM/DD/YYYY')}</td>
      <td>{fullName(props.fax)}</td>
      <td>{props.fax.documentTitle}</td>
      <td>
        <div className="status-container">
          <div>{props.fax.faxStatus}</div>
          {showProgress(props.fax.faxStatus) ? (
            <div className="spinner-container">
              <MaterialSpinner
                show={true}
                width={10}
                height={10}></MaterialSpinner>
            </div>
          ) : null}
        </div>
      </td>
    </tr>
  );
};

const fullName = fax => {
  return `${fax.lastName}, ${fax.firstName}`;
};

const showProgress = status => {
  if (!status) return false;
  status = status.toLowerCase();
  return status !== 'sent' && status !== 'failed' && status !== 'unknown';
};
