import React from 'react';
import ReactGa from 'react-ga';
import { Row, Col } from 'react-bootstrap';
import { getAppUrl, getAppTarget } from '../../configuration';
import { useSelector } from 'react-redux';
import { MdPhone, MdOpenInNew } from 'react-icons/md';
import './upswingCard.scss';
const upSwingUrl = 'https://upswinghealth.com/CT';
export const UpswingCard = () => {
  const searchText = useSelector((state) => state.healthNavigator.searchResults.searchText);
  const episodes = useSelector((state) => state.healthNavigator.episodes);

  const showUpswingCard = episodes.find((episode) => {
    return episode.name === searchText;
  }).showOrthopedicTeleProvider;

  return (
    <>
      {showUpswingCard ? (
        <Row className="upswing">
          <Col md={8}>
            <h3>UPSWING HEALTH</h3>
            <p>
              Use this tool to help identify any orthopedic injury or condition, Chat with a healthcare professional to
              determine what is wrong and how to best treat the condition - whether through telemedicine visits,
              home-based therapies, or referrals to in-network specialists this tool will help members link to the
              appropriate level of care.
            </p>
          </Col>
          <Col md={4}>
            <h3 className="contact">CONTACT</h3>
            <div className="cta">
              <h4>Online 24/7</h4>
              <a
                onClick={() => {
                  ReactGa.event({
                    category: 'External Link',
                    action: 'Upswing'
                  });
                }}
                target={getAppTarget('_blank')}
                href={getAppUrl(upSwingUrl)}>
                www.upswinghealth.com/CT <MdOpenInNew />
              </a>
            </div>
            {/* <div className="cta-telephone">
              <h4>Telephone 8am-8pm Est Daily</h4>
              <a href="tel:203-204-3855">
                <span>
                  <MdPhone /> 203-204-3855
                </span>
              </a>
            </div> */}
          </Col>
        </Row>
      ) : null}
    </>
  );
};
