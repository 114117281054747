import moment from 'moment';
import { ChatMessageType } from '../chatmessage';
import * as types from './actionTypes.js';

export const loadHoldMessages = (holdMessageResult) => {
  return {
    type: types.LOAD_HOLD_MESSAGES,
    holdMessages: holdMessageResult.messages,
    holdMessageDelay: holdMessageResult.delay
  };
};

export const appendAgentMessage = (messageText, agentName, timestamp, index) => {
  const message = {
    id: index,
    type: ChatMessageType.agentMessageType,
    name: agentName,
    text: messageText,
    time: moment(new Date(timestamp)).format('LTS')
  };
  return {
    type: types.APPEND_MESSAGE,
    message: message
  };
};

export const appendInfoMessage = (messageText, index) => {
  const message = {
    id: index,
    type: ChatMessageType.infoMessageType,
    text: messageText,
    time: moment(new Date()).format('LTS')
  };
  return {
    type: types.APPEND_MESSAGE,
    message: message
  };
};

export const appendUserMessage = (messageText, index) => {
  const message = {
    id: index,
    type: ChatMessageType.userMessageType,
    name: '',
    text: messageText,
    time: moment(new Date()).format('LTS')
  };
  return {
    type: types.APPEND_MESSAGE,
    message: message
  };
};

export const updateAgentIsTyping = (isTyping) => {
  return {
    type: types.UPDATE_AGENT_IS_TYPING,
    agentTyping: isTyping
  };
};
