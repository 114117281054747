import React from 'react';
import { useSelector } from 'react-redux';
import { AppConfiguration } from '../../configuration';
import { Row, Column } from 'react-foundation';
import { ContactUsMessage } from './contactUsMessage';
import { NoAgentsAvailable } from './noAgentsAvailable';
import { PleaseWait } from './pleaseWait';
import { QueueStatus } from './queueStatus';
import './agentWaitScreenComponent.scss';

export const AgentWaitScreenComponent = (props) => {
  const imagePath = AppConfiguration.getImagePath('img_agentWaitScreen.png');
  const { estimatedWaitTime, agentsAvailable, inChatTimeWindow } = useSelector((state) => state.chat);
  return (
    <div className="agent-wait-screen">
      <Row>
        <Column>
          <div className="content">
            {agentsAvailable && inChatTimeWindow ? (
              <PleaseWait userDisplayName={props.userDisplayName} />
            ) : (
              <NoAgentsAvailable contactPhoneNumber={props.contactPhoneNumber} />
            )}
            {<QueueStatus estimatedWaitTime={estimatedWaitTime} />}
            <img src={imagePath} alt="wait" />
            {<ContactUsMessage contactUrl={props.contactUrl} />}
          </div>
        </Column>
      </Row>
    </div>
  );
};
