import React from 'react';
import { Accordion, Button, Container, Row, Col, Badge } from 'react-bootstrap';
import { ModalDialogTest } from './modalTemplate.jsx';
//import FinishLogo from './../images/finishLine.png';

export const CombinedTabTest = (props) => {
  const colHeaderOne = 'Activity';
  const colHeaderTwo = 'Point Value';
  const colHeaderThree = 'Points Earned';
  const colHeaderFour = 'Date Completed';
  return (
    <div className="tabContent">
      <Container className="pointsGrid earnPointsGrid">
        <h4>
          Participation Activities
          <span className="reqHolder">
            <Badge className="req">R</Badge> Required
          </span>
        </h4>
        <Container>
          <Row className="tableHeadRow">
            <Col md={4}>{colHeaderOne}</Col>
            <Col md={2}>{colHeaderTwo}</Col>
            <Col md={2}>{colHeaderThree}</Col>
            <Col md={2}>{colHeaderFour}</Col>
            <Col md={2}></Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="mobileHeader">{colHeaderOne}: </span>
              Participate in a Health Screening
              <Badge className="req">R</Badge>
            </Col>
            <Col md={2} className="pointsColumn">
              <span className="mobileHeader">{colHeaderTwo}: </span>
              100
              <span className="maxPts">Max of 100</span>
            </Col>
            <Col md={2} className="ptsEarnedCol">
              100
            </Col>
            <Col md={2} className="dateCompleted">
              02/04/2020
            </Col>
            <Col md={2}></Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="mobileHeader">{colHeaderOne}: </span>
              Complete Your Personal Health Profile
              <Badge className="req">R</Badge>
            </Col>
            <Col md={2} className="pointsColumn">
              <span className="mobileHeader">{colHeaderTwo}: </span>
              50
              <span className="maxPts">Max of 50</span>
            </Col>
            <Col md={2} className="ptsEarnedCol">
              50
            </Col>
            <Col md={2} className="dateCompleted">
              01/15/2020
            </Col>
            <Col md={2} className="linkAppearance">
              <ModalDialogTest TestLinkDestination="your Personal Health Profile Results" ButtonLabel="View Results" />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="mobileHeader">{colHeaderOne}: </span>
              Attend a Workplace Event
            </Col>
            <Col md={2} className="pointsColumn">
              <span className="mobileHeader">{colHeaderTwo}: </span>5<span className="maxPts">Max of 10</span>
            </Col>
            <Col md={2} className="ptsEarnedCol"></Col>
            <Col md={2} className="dateCompleted"></Col>
            <Col md={2}>
              <ModalDialogTest
                TestLinkDestination="information on attending a workplace event"
                ButtonLabel="More Info"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="mobileHeader">{colHeaderOne}: </span>
              Commit to Get Healthy
            </Col>
            <Col md={2} className="pointsColumn">
              <span className="mobileHeader">{colHeaderTwo}: </span>
              10
              <span className="maxPts">Max of 10</span>
            </Col>
            <Col md={2} className="ptsEarnedCol"></Col>
            <Col md={2} className="dateCompleted"></Col>
            <Col md={2}>
              <ModalDialogTest
                TestLinkDestination="information on how to commit to be healthy"
                ButtonLabel="More Info"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="mobileHeader">{colHeaderOne}: </span>
              Commit to Attend a Weight Meeting
            </Col>
            <Col md={2} className="pointsColumn">
              <span className="mobileHeader">{colHeaderTwo}: </span>
              10
              <span className="maxPts">Max of 10</span>
            </Col>
            <Col md={2} className="ptsEarnedCol"></Col>
            <Col md={2} className="dateCompleted"></Col>
            <Col md={2}>
              <ModalDialogTest
                TestLinkDestination="information on attending a Weight Meeting"
                ButtonLabel="More Info"
              />
            </Col>
          </Row>
          <Row className="totalRow">
            <Col md={6} className="totalRowLabel">
              <span className="mobileHeader">{colHeaderOne}: </span>
              Total Participation Activity Points Earned
            </Col>
            <Col md={2} className="pointsColumn">
              150
            </Col>
            <Col md={4}></Col>
          </Row>
        </Container>

        <h4>
          Tobacco
          <span className="reqHolder">
            <Badge className="req">R</Badge> Required
          </span>
        </h4>
        <Container>
          <Row className="tableHeadRow">
            <Col md={4}>{colHeaderOne}</Col>
            <Col md={2}>{colHeaderTwo}</Col>
            <Col md={2}>{colHeaderThree}</Col>
            <Col md={2}>{colHeaderFour}</Col>
            <Col md={2}></Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="mobileHeader">{colHeaderOne}: </span>
              Complete a Tobacco Affidavit
              <Badge className="req">R</Badge>
            </Col>
            <Col md={2} className="pointsColumn">
              <span className="mobileHeader">{colHeaderTwo}: </span>
              100
              <span className="maxPts">Max of 100</span>
            </Col>
            <Col md={2} className="ptsEarnedCol">
              100
            </Col>
            <Col md={2} className="dateCompleted">
              02/04/2020
            </Col>
            <Col md={2} className="linkAppearance">
              <ModalDialogTest TestLinkDestination="your Tobacco Affidavit" ButtonLabel="View Affidavit" />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="mobileHeader">{colHeaderOne}: </span>
              Commit to be Tobacco-Free
              <Badge className="req">R</Badge>
            </Col>
            <Col md={2} className="pointsColumn">
              <span className="mobileHeader">{colHeaderTwo}: </span>
              50
              <span className="maxPts">Max of 50</span>
            </Col>
            <Col md={2} className="ptsEarnedCol"></Col>
            <Col md={2} className="dateCompleted"></Col>
            <Col md={2}>
              <ModalDialogTest TestLinkDestination="information on becoming tobacco-free" ButtonLabel="More Info" />
            </Col>
          </Row>
          <Row className="totalRow">
            <Col md={6} className="totalRowLabel">
              <span className="mobileHeader">{colHeaderOne}: </span>
              Total Tobacco Points Earned
            </Col>
            <Col md={2} className="pointsColumn">
              100
            </Col>
            <Col md={4}></Col>
          </Row>
        </Container>

        <h4>
          Create Healthy Habits
          <span className="reqHolder">
            <Badge className="req">R</Badge> Required
          </span>
        </h4>
        <Container>
          <Row className="tableHeadRow">
            <Col md={4}>{colHeaderOne}</Col>
            <Col md={2}>{colHeaderTwo}</Col>
            <Col md={2}>{colHeaderThree}</Col>
            <Col md={2}>{colHeaderFour}</Col>
            <Col md={2}></Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="mobileHeader">{colHeaderOne}: </span>
              Eat 5 Servings of Fruits/Vegetables a Day
            </Col>
            <Col md={2} className="pointsColumn">
              <span className="mobileHeader">{colHeaderTwo}: </span>5<span className="maxPts">Max of 200</span>
            </Col>
            <Col md={2} className="ptsEarnedCol"></Col>
            <Col md={2} className="dateCompleted"></Col>
            <Col md={2}>
              <ModalDialogTest TestLinkDestination="a Fruit/Veggie Tracker" ButtonLabel="More Info" />
            </Col>
          </Row>

          <Row>
            <Col>
              <Accordion>
                <Row>
                  <Col md={4}>
                    <span className="mobileHeader">{colHeaderOne}: </span>
                    Log 10,000 Steps per Day
                  </Col>
                  <Col md={2} className="pointsColumn">
                    <span className="mobileHeader">{colHeaderTwo}: </span>5<span className="maxPts">Max of 200</span>
                  </Col>
                  <Col md={2} className="ptsEarnedCol">
                    20
                  </Col>
                  <Col md={2} className="dateCompleted">
                    various
                  </Col>
                  <Col md={2}>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      View Details
                    </Accordion.Toggle>
                  </Col>
                </Row>
                <Accordion.Collapse eventKey="0">
                  <Container>
                    {/* <Row className="selectDateRow">
                      <Col xs={3}>
                        <Button variant="link" className="date-range-link">
                          Last Week &#10148;
                        </Button>
                      </Col>
                      <Col xs={2}>
                        <Button variant="link" className="date-range-link">
                          Last 30 Days &#10148;
                        </Button>
                      </Col>
                      <Col xs={2}>
                        <Button variant="link" className="date-range-link">
                          Last 60 Days &#10148;
                        </Button>
                      </Col>
                      <Col xs={2}>
                        <Button variant="link" className="date-range-link">
                          Last 90 Days &#10148;
                        </Button>
                      </Col>
                      <Col className="active" xs={3}>
                        <Button variant="link" className="date-range-link">
                          All Available &#10148;
                        </Button>
                      </Col>
                    </Row> */}
                    <Row className="headerRowCollapse">
                      <Col md={6}>Steps</Col>
                      <Col md={3}>Date</Col>
                      <Col md={3}>Points Earned</Col>
                    </Row>
                    <Row>
                      <Col md={6}>10,000</Col>
                      <Col md={3}>1/8/2020</Col>
                      <Col md={3} className="pointsColumn">
                        5
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>11,000</Col>
                      <Col md={3}>1/7/2020</Col>
                      <Col md={3} className="pointsColumn">
                        5
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>10,123</Col>
                      <Col md={3}>1/6/2020</Col>
                      <Col md={3} className="pointsColumn">
                        5
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>15,430</Col>
                      <Col md={3}>1/3/2020</Col>
                      <Col md={3} className="pointsColumn">
                        5
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Collapse>
              </Accordion>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="mobileHeader">{colHeaderOne}: </span>
              Sleep 6-8 Hours per Night
            </Col>
            <Col md={2} className="pointsColumn">
              <span className="mobileHeader">{colHeaderTwo}: </span>5<span className="maxPts">Max of 200</span>
            </Col>
            <Col md={2} className="ptsEarnedCol"></Col>
            <Col md={2} className="dateCompleted">
              various
            </Col>
            <Col md={2}>
              <ModalDialogTest TestLinkDestination="a Sleep Tracker" ButtonLabel="More Info" />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <span className="mobileHeader">{colHeaderOne}: </span>
              Log Weight Once per Week
            </Col>
            <Col md={2} className="pointsColumn">
              <span className="mobileHeader">{colHeaderTwo}: </span>5<span className="maxPts">Max of 200</span>
            </Col>
            <Col md={2} className="ptsEarnedCol"></Col>
            <Col md={2} className="dateCompleted">
              various
            </Col>
            <Col md={2}>
              <ModalDialogTest TestLinkDestination="a Weight Tracker" ButtonLabel="More Info" />
            </Col>
          </Row>
          <Row className="totalRow">
            <Col md={6} className="totalRowLabel">
              <span className="mobileHeader">{colHeaderOne}: </span>
              Total Create Healthy Habits Points Earned
            </Col>
            <Col md={2} className="pointsColumn">
              20
            </Col>
            <Col md={4}></Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};
