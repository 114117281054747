import React from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';
import { Button } from 'react-bootstrap';
import './selfCheckerQuestionnaire.scss';
export const SelfCheckerQuestionnaire = (props) => {
  return (
    <div className="self-checker-panel col-md-auto">
      {props.current.id != 10 && (
        <>
          <h3>{props.current.question}</h3>
          <ul>
            {props.current.lists.map((list, key) => {
              return (
                <li className={props.current.id === 3 ? 'list-unstyled' : ''} key={key}>
                  {list}
                </li>
              );
            })}
          </ul>
          <p className="additional-text">{props.current.additionalText}</p>
          <div className="mt-4">
            <Button variant="primary" className="btns rounded-0" size="lg" onClick={props.yes}>
              {props.current.id === 4 ? 'OK' : 'YES'}
            </Button>
            {props.current.id !== 4 && (
              <Button variant="primary" className="btns rounded-0" size="lg" onClick={props.no}>
                NO
              </Button>
            )}
          </div>
        </>
      )}
      {props.children}
      {props.current.id !== 1 && (
        <div className="text-center">
          <Button className="back-button" variant="link" onClick={props.back}>
            <MdKeyboardBackspace size="1em" /> BACK TO PREVIOUS QUESTION
          </Button>
        </div>
      )}
    </div>
  );
};
