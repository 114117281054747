// Determines if newtab: should be added (ie. native app)
export function getAppUrl(url) {
  if (window.isNativeApp) {
    url = `newtab:${url}`;
  }
  return url;
}

export function getAppTarget(currentTarget) {
  if (
    window.isNativeApp &&
    currentTarget &&
    currentTarget.toLowerCase() === '_blank'
  ) {
    return '_self';
  } else {
    return currentTarget;
  }
}
