// Get a unique key based on facility & address
const facilityKey = (provider) => {
  if (!provider) return null;
  return `${provider.facilityName}|${provider.addressLine1}|${provider.addressLine2}|${provider.addressLine3}|${provider.city}|${provider.state}|${provider.zip}`;
};

// Add a provider to the map
const mapFacilityProvider = (facilityMap, provider) => {
  const key = facilityKey(provider);
  provider.providers = [];
  if (provider.facilityName && provider.facilityName.length > 0) {
    if (!facilityMap.has(key)) {
      const groupedProviders = [];
      groupedProviders.push(provider);
      facilityMap.set(key, groupedProviders);
    } else {
      facilityMap.get(key).push(provider);
    }
    return true;
  }
  return false;
};

// Group providers at a given facility under the provider record representing said facility
const groupProvidersAtFacility = (facilityMap) => {
  let groupedProviders = [];
  for (var key of facilityMap.keys()) {
    const facilityProviders = facilityMap.get(key);
    if (facilityProviders && facilityProviders.length > 0) {
      // Copy first record and designate it the 'facility provider' record to nest doctors under
      let facility = facilityProviders[0];
      facility = { ...facility, firstName: '', lastName: '', credentialSuffix: '' };
      facility.providers = [];
      if (!facility || !facilityProviders || facilityProviders.length === 0) continue;
      // For each provider at said facility add them to an array nested in the facility
      facilityProviders.forEach((provider) => {
        if (provider.firstName && provider.firstName.length > 0 && provider.lastName && provider.lastName.length > 0) {
          facility.providers.push(provider);
        }
      });
      groupedProviders.push(facility);
    }
  }
  return groupedProviders;
};

export const groupProcedureProviders = (providers) => {
  if (!providers || providers.length === 0) return [];
  const standAloneProviders = [];
  const facilityMap = new Map();
  providers.forEach((provider) => {
    if (!mapFacilityProvider(facilityMap, provider)) standAloneProviders.push(provider);
  });
  const groupedProviders = groupProvidersAtFacility(facilityMap);
  const allProviders = [...groupedProviders, ...standAloneProviders];
  allProviders.sort((p1, p2) => p1.distanceMiles - p2.distanceMiles);
  return allProviders;
};
