export const reinitEqualizer = () => {
  // Re-init equalize next tick
  setTimeout(() => {
    if (window.Foundation) {
      try {
        window.Foundation.reInit('equalizer');
      } catch (error) {
        console.error('Foundation reinit error');
        console.error(error);
      }
    }
  }, 0);
};
