import * as types from './actionTypes';

const initialState = {
  list: [],
  loading: true,
  error: false,
  newRecipient: {
    adding: false,
    addSuccess: false,
    addFailure: false,
    conflictRecipient: false
  },
  updateRecipient: {
    updating: false,
    updateSuccess: false,
    updateFailure: false
  },
  removeRecipient: {
    removing: false,
    removeSuccess: false,
    removeFailure: false
  }
};

export const faxRecipientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_FAX_RECIPIENTS_SUCCESS:
      return {
        ...state,
        list: action.faxRecipients,
        loading: false
      };
    case types.LOAD_FAX_RECIPIENTS_FAILED:
      return {
        ...state,
        list: [],
        loading: false,
        error: true
      };
    case types.REQUEST_ADD_FAX_RECIPIENT:
      return {
        ...state,
        newRecipient: {
          adding: true,
          conflictRecipient: false
        }
      };
    case types.ADD_FAX_RECIPIENT_SUCCESS:
      return {
        ...state,
        newRecipient: {
          adding: false,
          addSuccess: true,
          conflictRecipient: false
        },
        list: [...state.list.concat(action.newRecipient)]
      };
    case types.ADD_FAX_RECIPIENT_FAILED:
      return {
        ...state,
        newRecipient: {
          adding: false,
          addFailure: true,
          conflictRecipient: false
        }
      };
    case types.REQUEST_UPDATE_FAX_RECIPIENT:
      return {
        ...state,
        updateRecipient: {
          updating: true
        }
      };
    case types.UPDATE_FAX_RECIPIENT_SUCCESS:
      return {
        ...state,
        updateRecipient: {
          updating: false,
          updateSuccess: true
        },
        list: [
          ...state.list
            .filter(item => item.memberFaxRecipientId !== action.recipientId)
            .concat(action.updateRecipient)
        ]
      };
    case types.UPDATE_FAX_RECIPIENT_FAILED:
      return {
        ...state,
        updateRecipient: {
          updating: false,
          updateFailure: true
        }
      };
    case types.REQUEST_REMOVE_FAX_RECIPIENT:
      return {
        ...state,
        removeRecipient: {
          removing: true
        }
      };
    case types.REMOVE_FAX_RECIPIENT_SUCCESS:
      return {
        ...state,
        removeRecipient: {
          removing: false,
          removeSuccess: true
        },
        list: [
          ...state.list.filter(
            item => item.memberFaxRecipientId !== action.recipientId
          )
        ]
      };
    case types.REMOVE_FAX_RECIPIENT_FAILED:
      return {
        ...state,
        removeRecipient: {
          removing: false,
          removeFailure: true
        }
      };
    case types.SET_RECIPIENT_CONFLICT_STATUS:
      return {
        ...state,
        newRecipient: {
          adding: false,
          conflictRecipient: true
        }
      }
    case types.RESET_RECIPIENT_CONFLICT_STATUS:
      return {
        ...state,
        newRecipient: {
          adding: false,
          conflictRecipient: false
        }
      }
    default:
      return state;
  }
};
