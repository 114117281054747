import * as types from './actionTypes';

const initialState = {
  searching: false,
  error: false,
  searchResults: [],
  loadingEpisodes: true,
  loadEpisodesError: false,
  episodes: [],
  loadingNetworks: true,
  loadNetworksError: false,
  networks: [],
  matchingProviders: [],
  findingProviders: false,
  findingProvidersError: false,
  providerLookupType: '',
  providerNetwork: '',
  loadingSpineProviders: false,
  loadSpineProvidersSuccess: false,
  loadSpineProvidersError: false,
  spineSearchResults: { providers: [] },
  loadingSpineIncentives: false,
  loadSpineIncentivesError: false,
  spineIncentives: []
};

export const healthNavigatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_PROVIDER_SEARCH:
      return {
        ...state,
        searching: true
      };
    case types.PROVIDER_RESET_SUCCESS:
      return {
        ...state,
        searchResults: [],
        searching: false,
        providerLookupType: action.providerLookupType
      };
    case types.PROVIDER_SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: action.searchResults,
        searching: false,
        error: false
      };
    case types.PROVIDER_SEARCH_FAILED:
      return {
        ...state,
        searchResults: [],
        searching: false,
        error: true
      };
    case types.LOAD_EPISODES_SUCCESS:
      return {
        ...state,
        loadingEpisodes: false,
        loadEpisodesError: false,
        episodes: action.episodes
      };
    case types.LOAD_EPISODES_FAILED:
      return {
        ...state,
        loadingEpisodes: false,
        loadEpisodesError: true,
        episodes: []
      };
    case types.REQUEST_PROVIDER_LOOKUP:
      return {
        ...state,
        findingProviders: true,
        findingProvidersError: false
      };
    case types.REQUEST_PROVIDER_LOOKUP_SUCCESS:
      return {
        ...state,
        findingProviders: false,
        findingProvidersError: false,
        matchingProviders: action.lookupResults
      };
    case types.REQUEST_PROVIDER_LOOKUP_FAILED:
      return {
        ...state,
        findingProviders: false,
        findingProvidersError: true,
        matchingProviders: []
      };
    case types.LOAD_NETWORKS_SUCCESS:
      return {
        ...state,
        networks: action.networks,
        loadingNetworks: false,
        loadNetworksError: false
      };
    case types.PROVIDER_NETWORK_SUCCESS:
      return {
        ...state,
        searchResults: [],
        searching: false,
        providerNetwork: action.providerNetwork
      };
    case types.LOAD_NETWORKS_FAILED:
      return {
        ...state,
        networks: [],
        loadingNetworks: false,
        loadNetworksError: true
      };
    //Spine Program
    case types.GET_SPINE_INCENTIVES_START:
      return {
        ...state,
        loadingSpineIncentives: true,
        loadSpineIncentivesError: false
      };
    case types.GET_SPINE_INCENTIVES_SUCCESS:
      return {
        ...state,
        loadingSpineIncentives: false,
        spineIncentives: [...action.spineIncentives]
      };
    case types.GET_SPINE_INCENTIVES_FAILED:
      return {
        ...state,
        loadingSpineIncentives: false,
        loadSpineIncentivesError: true
      };
    case types.RESET_SPINE_INCENTIVES:
      return {
        ...state,
        loadingSpineIncentives: false,
        loadSpineIncentivesError: false,
        spineIncentives: []
      };
    case types.GET_SPINE_PROVIDERS_START:
      return {
        ...state,
        loadingSpineProviders: true,
        loadSpineProvidersError: false
      };
    case types.GET_SPINE_PROVIDERS_SUCCESS:
      return {
        ...state,
        loadingSpineProviders: false,
        loadSpineProvidersSuccess: true,
        spineSearchResults: action.spineResults
      };
    case types.GET_SPINE_PROVIDERS_FAILED:
      return {
        ...state,
        loadingSpineProviders: false,
        loadSpineProvidersError: true
      };
    case types.RESET_SPINE_PROVIDERS:
      return {
        ...state,
        loadingSpineProviders: false,
        loadSpineProvidersSuccess: false,
        loadSpineProvidersError: false,
        spineSearchResults: { providers: [] }
      };
    default:
      return state;
  }
};
