import React from 'react';
import { getAppUrl, getAppTarget } from '../../configuration';
import { MdPlace } from 'react-icons/md';
import { FaUserMd } from 'react-icons/fa';
import { Col } from 'react-bootstrap';
import { SearchType } from '../searchType';
import { useSelector } from 'react-redux';
import './profile.scss';
import './results.scss';

export const Profile = ({
  distanceAway,
  providerAddress,
  providerFacilityName,
  providerName,
  providerServiceType,
  providerLatitude,
  providerLongitude
}) => {
  const { providerLookupType } = useSelector((state) => state.healthNavigator);
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${providerLatitude},${providerLongitude}`;
  const providerNameStyle = { fontSize: '0.875rem' };
  return (
    <Col md={4} className="profile0">
      <div className="profile-header">
        {providerFacilityName && <h3 style={{ fontSize: '1rem', fontWeight: '600' }}>{providerFacilityName}</h3>}

        {providerLookupType === SearchType.Facility ? (
          ''
        ) : (
          <h3 style={providerFacilityName && providerNameStyle}>
            {providerFacilityName && providerLookupType === SearchType.Doctor ? (
              <FaUserMd style={{ fontSize: '1rem', color: 'gray', verticalAlign: 'text-top', marginRight: '4px' }} />
            ) : null}
            {providerName}
          </h3>
        )}

        <span className="hosp0">{providerServiceType}</span>
      </div>
      <p className="hosAddress">{providerAddress}</p>
      <h6 className="viewMap">
        <a target={getAppTarget('_blank')} href={getAppUrl(googleMapsUrl)} alt="map">
          <span>
            <MdPlace className="map0" size="1.5em" />
          </span>
          {distanceAway}
        </a>
      </h6>
    </Col>
  );
};
