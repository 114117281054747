import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Row, Column, Button } from 'react-foundation';
import { PhoneNumber } from '../../demographics';
import './signInComponent.scss';

export class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      firstNameError: '',
      lastNameError: '',
      phoneError: '',
      emailError: ''
    };
  }

  submitLogin = event => {
    event.preventDefault();
    event.stopPropagation();
    if (this.validateForm()) {
      const phone = new PhoneNumber(this.state.phoneNumber);
      const user = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        company: this.state.company,
        phone: {
          area: phone.areaCode,
          number: `${phone.prefix}${phone.lineNumber}`
        },
        email: this.state.email
      };
      this.props.setUser(user);
    }
  };

  validateForm() {
    let firstNameValid = this.validateFirstName();
    let lastNameValid = this.validateLastName();
    let phoneValid = this.validatePhone();
    let emailValid = this.validateEmail();
    return firstNameValid && lastNameValid && phoneValid && emailValid;
  }

  validateFirstName() {
    if (!this.state.firstName || !this.state.firstName.trim()) {
      this.setState({ firstNameError: 'First name is required.' });
      return false;
    }
    this.setState({ firstNameError: '' });
    return true;
  }

  validateLastName() {
    if (!this.state.lastName || !this.state.lastName.trim()) {
      this.setState({ lastNameError: 'Last name is required.' });
      return false;
    }
    this.setState({ lastNameError: '' });
    return true;
  }

  validatePhone() {
    if (!this.state.phoneNumber || !this.state.phoneNumber.trim()) {
      this.setState({ phoneError: 'Phone is required.' });
      return false;
    }
    const expression = '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$';
    const regEx = new RegExp(expression);
    if (
      !regEx.test(this.state.phoneNumber) ||
      this.state.phoneNumber.replace(/\D/g, '').length !== 10
    ) {
      this.setState({
        phoneError: 'Please enter valid 10 digit phone number.'
      });
      return false;
    }
    this.setState({ phoneError: '' });
    return true;
  }

  validateEmail() {
    if (this.state.email && this.state.email.length > 0) {
      const expression =
        '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$';
      const regEx = new RegExp(expression);
      if (!regEx.test(this.state.email)) {
        this.setState({ emailError: 'Please enter valid email.' });
        return false;
      }
    }
    this.setState({ emailError: '' });
    return true;
  }

  showFieldError(errorMessage) {
    if (errorMessage) {
      return <div className="error">{errorMessage}</div>;
    }
  }

  render() {
    return (
      <div className="container">
        <form
          noValidate
          validated={this.state.validated}
          onSubmit={e => this.submitLogin(e)}>
          <Row>
            <Column className="description">
              {this.props.description} <span>(* Required Fields)</span>
            </Column>
          </Row>
          <Row className="display">
            <Column small={6} className="form-group">
              <label className="required">First Name</label>
              <input
                required
                type="text"
                onChange={e => this.setState({ firstName: e.target.value })}
                id="firstName"
                className="form-control"
              />
              {this.showFieldError(this.state.firstNameError)}
            </Column>
            <Column small={6} className="form-group">
              <label className="required">Last Name</label>
              <input
                required
                type="text"
                onChange={e => this.setState({ lastName: e.target.value })}
                id="lastName"
                className="form-control"
              />
              {this.showFieldError(this.state.lastNameError)}
            </Column>
          </Row>
          <Row>
            <Column small={6} className="form-group">
              <label>Company</label>
              <p className="company-name">{this.props.company}</p>
            </Column>
            <Column small={6} className="form-group">
              <label className="required">Phone Number</label>
              <input
                type="tel"
                placeholder="digits only"
                maxlength="10"
                onKeyPress={e => {
                  if (
                    e.which !== 8 &&
                    e.which !== 0 &&
                    (e.which < 48 || e.which > 57)
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={e =>
                  this.setState({
                    phoneNumber: e.target.value
                  })
                }
                id="phone"
                className="form-control"
              />
              {this.showFieldError(this.state.phoneError)}
            </Column>
          </Row>
          <Row>
            <Column small={12} className="form-group">
              <label>Email Address</label>
              <input
                required
                type="email"
                onChange={e => this.setState({ email: e.target.value })}
                id="email"
                className="form-control"
              />
              {this.showFieldError(this.state.emailError)}
            </Column>
          </Row>
          <Row>
            <Column className="start-button" small={12}>
              <Button variant="primary" type="submit">
                Start Live Chat
              </Button>
            </Column>
          </Row>
        </form>
      </div>
    );
  }
}

SignInForm.propTypes = {
  company: PropTypes.string.isRequired
};
