export const sortResults = (key, sortOrder = 'asc') => {
  return function startSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    const sortA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const sortB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
    let compareSort = 0;
    if (sortA > sortB) {
      compareSort = 1;
    } else if (sortA < sortB) {
      compareSort = -1;
    }
    return sortOrder === 'desc' ? compareSort * -1 : compareSort;
  };
};

export const searchReferenceNumber = (str) => {
  let searchId =
    str.length === 1
      ? '00' + str
      : str.length === 2
      ? '0' + str
      : str.length === 3
      ? str
      : str.length === 4
      ? '00' + str
      : str.length === 5
      ? '0' + str
      : str.length === 6
      ? str
      : str.length === 7
      ? '00' + str
      : str.length === 8
      ? '0' + str
      : str;
  if (searchId.length === 6 || searchId.length > 8) {
    let resSearchId = [...searchId]
      .map((x, i) => (i % 3 === 0 ? ' ' + x : x))
      .join('')
      .trim();
    return resSearchId;
  } else {
    return searchId;
  }
};
