import React from 'react';
import { AppConfiguration } from '../../configuration';
import { useDispatch, useSelector } from 'react-redux';
import { showMenuChat, hideChat } from '../store';
import './menuChatButton.scss';

export const MenuChatButton = () => {
  const dispatch = useDispatch();
  const { wellnessChatVisible, menuChatVisible } = useSelector((state) => state.chat);
  const chatVisible = wellnessChatVisible || menuChatVisible;
  return (
    <div className="chat-button" title="Chat">
      <button onClick={(e) => handleShowChatClick(e, dispatch, chatVisible)}>
        <img src={AppConfiguration.getImagePath('icon_chatNav1.svg')} alt="Live Chat" height="28" />
      </button>
    </div>
  );
};

const handleShowChatClick = (event, dispatch, chatVisible) => {
  event.preventDefault();
  if (chatVisible) {
    dispatch(hideChat());
  } else {
    dispatch(showMenuChat());
  }
};
