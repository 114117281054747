import React from 'react';
import { ChatMessageType } from './chatMessageType';

export const MessageHeader = props => {
  if (props.type === ChatMessageType.agentMessageType) {
    return (
      <p>
        {props.time + ' '}
        <span style={{ fontWeight: 'bold' }}>{props.name}</span>
      </p>
    );
  }
  return <p>{props.time}</p>;
};
