import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { MdPhone } from 'react-icons/md';
import { updateSelectedProvider } from '../../updateSelectedProvider';
import '../results.scss';
import './spineContact.scss';

export const SpineContact = ({ name, patientCode, travelIncentive, uniqueKeyId, searchResultId, isMobile }) => {
  const client = useSelector((state) => state.clients);
  const [show, setShow] = useState(false);

  const clients = client ? client.client : [];
  const closeHandler = () => setShow(false);
  const showHandler = () => setShow(true);

  return (
    <div className={isMobile ? 'spine-contact-mobile' : 'spine-contact'}>
      <Button
        onClick={(e) => {
          e.preventDefault();
          updateSelectedProvider(searchResultId, uniqueKeyId);
          showHandler();
        }}
        variant="primary"
        size="lg">
        Contact
      </Button>

      <br />

      <span className="pi0">
        <small>{travelIncentive}</small>
      </span>

      <Modal show={show} onHide={closeHandler}>
        <Modal.Header closeButton>
          <Modal.Title>{`Contact ${name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            When you choose a Provider of Distinction for a listed procedure, you will automatically receive a cash
            incentive in the mail. Contact your Personal Health Navigator with any questions about the Providers of
            Distinction program, or for help finding a provider.
          </p>

          <p className="patient-code">
            Keep code <strong>{patientCode}</strong> for your records.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={closeHandler}>
            Cancel
          </Button>
          <Button variant="info" onClick={closeHandler}>
            <MdPhone size="1em" />
            <a href={`tel:${clients && clients.phoneNumber}`}>
              <span> {clients && clients.phoneNumber}</span>
            </a>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
