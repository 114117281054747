import React, { Component } from 'react';
import './errorBoundry.scss';

export class ErrorBoundry extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.error(info);
    //TODO: log right from error boundry?
  }

  render() {
    const defaultMessage =
      'An error occurred, technical support has been notified.';
    const message = this.props.errorMessage
      ? this.props.errorMessage
      : defaultMessage;
    if (this.state.hasError) {
      return (
        <div className="card shadow background-blur with-icon error-boundry-box">
          <span className="errorMSG">{message}</span>
        </div>
      );
    }
    return this.props.children;
  }
}
