import React, { useState, useEffect } from 'react';
import { ErrorMessage, withFormik } from 'formik';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Col, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import { providerSearch, providerReset, providerSelectedNetwork } from '../store';
import { SearchType } from '../searchType';
import { NetworksModal } from './networksModal';
import { zipCodeRegExp } from '../../shared';
import { logError } from '../../logging';
import { MdSearch, MdClose } from 'react-icons/md';
import { FiMapPin } from 'react-icons/fi';
import './search.scss';
import { EpisodeTypeahead } from './episodeTypeahead';
import { ProviderTypeahead } from './providerTypeahead';
import { loadGeocodeAddress } from '../../shared/geocoding';

const searchRadiusList = [5, 10, 20, 30, 50, 75, 100];
let latitude = null;
let longitude = null;
let userZipCode = null;

const SearchForm = (props) => {
  const { values, handleChange, handleBlur, handleSubmit } = props;
  const searching = useSelector((state) => state.healthNavigator.searching);
  const episodes = useSelector((state) => state.healthNavigator.episodes);
  const address = useSelector((state) => state.geocode.address);
  const [searchType, setSearchType] = useState(SearchType.Procedure);
  const [userLatitude, setUserLatitude] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [userLongitude, setUserLongitude] = useState(null);
  const [didFocus, setDidFocus] = React.useState(false);

  const handleFocus = () => setDidFocus(true);

  const resetSearchText = () => {
    values.searchText = '';
    values.episodeId = null;
  };
  const resetZipCode = () => {
    props.setFieldValue('zipCode', '');
  };

  const onGeolocateSuccess = async (coordinates) => {
    latitude = coordinates.coords.latitude;
    longitude = coordinates.coords.longitude;
    const result = await props.dispatch(loadGeocodeAddress(latitude, longitude, logError));
    if (result && result.address && result.address.zipCode) userZipCode = result.address.zipCode;
    props.setFieldValue('zipCode', userZipCode);
    setUserLatitude(latitude);
    setUserLongitude(longitude);
  };

  const onGeolocateError = () => {};

  const geolocate = () => {
    if (window.navigator && window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onGeolocateSuccess, onGeolocateError);
    }
  };
  const searchButtonStyle = { backgroundColor: '#005ead' };
  const zipCodeLabelVisibility = values && values.zipCode && values.zipCode.length ? 'visible' : 'hidden';
  const searchTextLabelVisibility = values && values.searchText && values.searchText.length ? 'visible' : 'hidden';
  const currentLocation = () => {
    const { city, stateAbbreviation } = address;
    if (city !== undefined) {
      return (
        <span className="current-location">
          <span>
            <strong>
              <FiMapPin size="16" className="fa-map-pin" />
              Your location:{' '}
            </strong>
          </span>

          <OverlayTrigger
            placement="bottom"
            overlay={<Popover id="tooltip-location">Enter a new zip code if this is not your location.</Popover>}>
            <span className="cl0">{city + ', ' + stateAbbreviation}</span>
          </OverlayTrigger>
        </span>
      );
    }
  };

  useEffect(() => {
    geolocate();
  }, []);

  const dispatch = useDispatch();

  return (
    <div className="cost-estimator-search">
      <Col className="seach-col">
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <div className="col-sm-12 col-md-12 col-lg-11 rounded-bg">
              <Form.Group className="col-sm-12 col-md-12 col-lg-4 select" controlId="searchSelect">
                <Form.Label className="search-for">Search for</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    dispatch(providerReset(e.target.value));
                    values.searchType = e.target.value;
                    setSearchType(e.target.value);
                    resetSearchText();
                    setDidFocus(false);
                  }}>
                  <option id="procedures" name="searchType" value={SearchType.Procedure}>
                    Procedures & Conditions
                  </option>
                  <option id="providers" name="searchType" value={SearchType.Doctor}>
                    Providers
                  </option>
                  <option id="facilities" name="searchType" value={SearchType.Facility}>
                    Facilities
                  </option>
                </Form.Control>
                <div className="btn-dk">
                  <NetworksModal
                    selectedNetwork={selectedNetwork}
                    handleChange={(e) => {
                      dispatch(providerSelectedNetwork(e.target.value));
                      values.networkId = e.target.value;
                      setSelectedNetwork(e.target.value);
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-md-12 col-lg-6" controlId="formGridProcedures">
                <span className="hep0">
                  <Form.Label
                    className="term-label"
                    style={{ paddingTop: '0px', paddingBottom: '0px', visibility: searchTextLabelVisibility }}>
                    Search Term
                  </Form.Label>
                  {searchType === SearchType.Procedure && (
                    <EpisodeTypeahead
                      values={values}
                      episodes={episodes}
                      handleBlur={handleBlur}
                      handleFocus={handleFocus}
                    />
                  )}
                  {searchType === SearchType.Doctor && (
                    <ProviderTypeahead
                      values={values}
                      placeHolder="Enter Provider First Name Last Name"
                      searchType={searchType}
                      handleBlur={handleBlur}
                      latitude={latitude}
                      longitude={longitude}
                      handleProviderFocus={() => {
                        setDidFocus(true);
                      }}
                    />
                  )}
                  {searchType === SearchType.Facility && (
                    <ProviderTypeahead
                      values={values}
                      placeHolder="Enter Facility Name"
                      searchType={searchType}
                      handleBlur={handleBlur}
                      latitude={latitude}
                      longitude={longitude}
                      handleProviderFocus={() => {
                        setDidFocus(true);
                      }}
                    />
                  )}

                  {!didFocus || !values.searchText || !values.searchText.length ? (
                    <div className="error-text async-error">
                      <ErrorMessage name="searchText" />
                    </div>
                  ) : null}
                </span>
              </Form.Group>
              <Form.Group className="col-md-12 col-lg-2 hep1">
                <div className="col-sm-8 col-md-6 col-lg-12 zipfield">
                  <Form.Label className="zip-label" style={{ visibility: zipCodeLabelVisibility }}>
                    ZIP Code
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    name="zipCode"
                    id="zipcode"
                    type="text"
                    placeholder="Zip Code"
                    className="form-control form-control-lg"
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value !== userZipCode) {
                        latitude = null;
                        longitude = null;
                      } else {
                        latitude = userLatitude;
                        longitude = userLongitude;
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.zipCode}
                  />
                  {values.zipCode ? (
                    <button
                      onClick={() => {
                        dispatch(providerReset());
                        resetZipCode();
                      }}
                      className="clear-zip">
                      <MdClose />
                    </button>
                  ) : null}

                  <div className="zip-error">
                    <div className="btn-mb">
                      <NetworksModal
                        selectedNetwork={selectedNetwork}
                        handleChange={(e) => {
                          dispatch(providerSelectedNetwork(e.target.value));
                          values.networkId = e.target.value;
                          setSelectedNetwork(e.target.value);
                        }}
                      />
                    </div>

                    {searchType === SearchType.Procedure ? <ErrorMessage name="zipCode" /> : null}
                    {searchType === SearchType.Doctor && !didFocus ? <ErrorMessage name="zipCode" /> : null}
                    {searchType === SearchType.Facility && !didFocus ? <ErrorMessage name="zipCode" /> : null}
                    {values.zipCode === address['zipCode'] ? currentLocation() : ''}
                  </div>
                </div>

                <div className="col-sm-4 col-md-6 btn-mb">
                  <Button
                    style={
                      values.searchText && values.searchText.length && values.zipCode && values.zipCode.length
                        ? searchButtonStyle
                        : null
                    }
                    onClick={() => {
                      if (searchType === SearchType.Doctor && !values.zipCode && !values.zipCode.length) {
                        setDidFocus(false);
                      }
                      if (searchType === SearchType.Facility && !values.zipCode && !values.zipCode.length) {
                        setDidFocus(false);
                      }
                    }}
                    variant="primary"
                    className="btn-lg"
                    type="submit">
                    {searching ? (
                      <>
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                      </>
                    ) : (
                      <MdSearch size="1.5em" />
                    )}
                  </Button>
                </div>
              </Form.Group>
            </div>

            <Button
              variant="primary"
              className="btn-lg btn-dk"
              type="submit"
              onClick={() => {
                if (searchType === SearchType.Doctor && !values.zipCode && !values.zipCode.length) {
                  setDidFocus(false);
                }
                if (searchType === SearchType.Facility && !values.zipCode && !values.zipCode.length) {
                  setDidFocus(false);
                }
              }}
              style={
                values.searchText && values.searchText.length && values.zipCode && values.zipCode.length
                  ? searchButtonStyle
                  : null
              }>
              {searching ? (
                <>
                  <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                </>
              ) : (
                <MdSearch size="1.5em" />
              )}
            </Button>
          </Form.Row>
        </Form>
      </Col>
    </div>
  );
};

const schema = yup.object({
  searchText: yup.string().required('Search text is required').max(100, 'Max of 100 characters').nullable(),
  zipCode: yup
    .string()
    .required('Zip code is required')
    .matches(zipCodeRegExp, 'Please enter a valid zip code')
    .nullable()
});

export const ValidatedSearchForm = withFormik({
  enableReinitialize: true,
  initialErrors: false,
  mapPropsToValues: () => ({
    searchText: '',
    episodeId: null,
    zipCode: '',
    searchRadius: searchRadiusList[0],
    searchType: SearchType.Procedure
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const networkId = values.networkId;

    props.dispatch(
      providerSearch(
        values.searchType,
        values.searchText,
        values.episodeId,
        networkId,
        values.zipCode,
        values.searchRadius,
        latitude,
        longitude,
        logError
      )
    );
  }
})(SearchForm);
