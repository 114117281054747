import React, { Component } from 'react';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { getAppUrl, AppConfiguration } from '../configuration';

export class TimeTradeTodoItem extends Component {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();
  }

  render() {
    return (
      <a
        href={getAppUrl(this.props.todo.Href)}
        target="_blank"
        rel="noopener noreferrer"
        ref={this.anchorRef}
        onClick={e => {
          this.launchTimeTradeWorkflow(e);
        }}>
        <p>{this.props.todo.Title}</p>
        <div>{this.getLinkText(this.props.todo.LinkText)}</div>
        {this.props.todo.Points ? (
          <span className="points label">+{this.props.todo.Points} POINTS</span>
        ) : null}
        {this.props.todo.Required ? (
          <span className="small label">REQUIRED</span>
        ) : null}
      </a>
    );
  }

  launchTimeTradeWorkflow(event) {
    if (!AppConfiguration.isNativeApp()) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (window.tt) {
      return window.tt.launchWorkflow(this.anchorRef.current);
    } else {
      console.error('tt library is not defined');
    }
  }

  getLocalAppointmentDateTime(utcDateTime) {
    if (!utcDateTime) return '';

    const timeZoneOffset = new Date().getTimezoneOffset();
    const timeZone = momentTimeZone.tz.guess();
    const timeZoneAbbr = momentTimeZone.tz.zone(timeZone).abbr(timeZoneOffset);
    const localDate = moment
      .utc(utcDateTime)
      .local()
      .format('L');
    const localTime = moment
      .utc(utcDateTime)
      .local()
      .format('LT');
    return ` ${localTime} ${timeZoneAbbr} on ${localDate}`;
  }

  getLinkText() {
    return `${this.props.todo.ShortInfo} ${this.getLocalAppointmentDateTime(
      this.props.todo.LinkText
    )}`;
  }
}
