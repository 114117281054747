import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Callout, Colors } from 'react-foundation';
import { BiometricsSelectionCard } from './biometricsSelectionCard';
import { FaxRecipientsCard } from '../recipients';
import { AuthorizationModal } from './authorizationModal';
import { MaterialSpinnerPanel } from '../../shared';
import './sendFaxView.scss';

export const SendFaxView = () => {
  const [recipientId, setRecipientId] = useState(null);
  const [measurementDates, setMeasurementDates] = useState(new Map());
  const [authorizationModalOpen, setAuthorizationModalOpen] = useState(false);
  const sending = useSelector(state => state.biometricsReport.sending);
  const sendFailed = useSelector(state => state.biometricsReport.sendFailed);
  const sendSucceeded = useSelector(
    state => state.biometricsReport.sendSucceeded
  );
  const cantSend = !recipientId || !measurementDates.size || sending;
  return (
    <div className="send-fax-view">
      <h4>
        Step One: Select the Physician you want to send your biometrics to.
      </h4>
      <FaxRecipientsCard setRecipientId={setRecipientId} />
      <h4>Step Two: Select the Biometrics you wish to send.</h4>
      <BiometricsSelectionCard
        setMeasurementDates={setMeasurementDates}
        measurementDates={measurementDates}
      />
      <div className="submit-section">
        <div className="row">
          {sendFailed ? (
            <Callout color={Colors.WARNING}>
              An error occurred submitting your biometrics report, please try
              again later and if the issue persists contact support.
            </Callout>
          ) : null}
        </div>
        <div className="row">
          {sendSucceeded ? (
            <Callout color={Colors.SUCCESS}>
              Your biometrics have been submitted, you may track the faxing
              status on the 'history' tab of this page.
            </Callout>
          ) : null}
        </div>
        <div className="row submit-row">
          <div
            className="submit-wrapper"
            data-tooltipz={
              cantSend
                ? 'Please select a provider and one or more measurement dates'
                : null
            }
            >
            <div className="submit-wait">
              {sending ? (
                <MaterialSpinnerPanel width={20} height={20} show={true} />
              ) : null}
            </div>
            <Button
              disabled={cantSend}
              onClick={e => {
                e.preventDefault();
                setAuthorizationModalOpen(true);
              }}>
              Submit
            </Button>
          </div>
        </div>
      </div>
      <AuthorizationModal
        authorizationModalOpen={authorizationModalOpen}
        setAuthorizationModalOpen={setAuthorizationModalOpen}
        recipientId={recipientId}
        setRecipientId={setRecipientId}
        measurementDates={measurementDates}
        setMeasurementDates={setMeasurementDates}
      />
    </div>
  );
};
