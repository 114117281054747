import { AppConfiguration } from '../configuration';
import { getSessionToken, updateSessionToken } from '../session';
import { ApiClient } from '../api';
import * as types from './actionTypes';

export function loadContentExclusions(logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return dispatch => {
    return new Promise(async resolve => {
      const client = new ApiClient(apiRootUrl, sessionToken);
      try {
        const apiResult = await client.get('membersite/contentexclusion');
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        resolve(dispatch(loadContentExclusionsSuccess(apiResult.data.result)));
      } catch (error) {
        logError('contentExclusions/actions', 'loadContentExclusions', error);
        resolve(dispatch(loadContentExclusionsFailed()));
      }
    });
  };
}

export const loadContentExclusionsSuccess = contentExclusions => {
  return {
    type: types.LOAD_CONTENTEXCLUSIONS_SUCCESS,
    contentExclusions: contentExclusions
  };
};

export const loadContentExclusionsFailed = () => {
  return { type: types.LOAD_CONTENTEXCLUSIONS_FAILED };
};
