import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { ErrorBoundry } from '../shared';
import { PrescriptionDiscountCard } from './discountCard';

export const loadPrescriptionDiscountCard = store => {
  const prescriptionDiscountCardComponent = document.getElementById('prescriptionDiscountCardComponent');
  if (prescriptionDiscountCardComponent) {
    ReactDom.render(
      <Provider store={store}>
        <ErrorBoundry>
          <PrescriptionDiscountCard />
        </ErrorBoundry>
      </Provider>,
      prescriptionDiscountCardComponent
    );
  }
};
