import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { loadTodos } from '../../todos/actions';
import { loadIncentiveProgramStatus } from './actions';
import SummaryPanel from './summaryPanel';

export const loadIncentiveSummaryComponent = (store, logError) => {
  const incentiveSummaryElement = document.getElementById('incentiveSummaryComponent');
  if (incentiveSummaryElement) {
    store.dispatch(loadTodos(logError));
    store.dispatch(loadIncentiveProgramStatus(logError));
    ReactDOM.render(
      <Provider store={store}>
        <SummaryPanel />
      </Provider>,
      incentiveSummaryElement
    );
  }
};
