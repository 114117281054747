import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';
import * as types from './actionTypes';

const loadLinkedRulesSuccess = (programId, linkedRules) => {
  return {
    type: types.LOAD_LINKEDRULES_SUCCESS,
    data: { programId, linkedRules }
  };
};

const loadLinkedRulesFailed = () => {
  return {
    type: types.LOAD_LINKEDRULES_FAILED
  };
};

export const loadLinkedRules = (programId, logError) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get(`incentiveprogram/${programId}/rulelinks`);
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        resolve(dispatch(loadLinkedRulesSuccess(programId, apiResult.data.result)));
      } catch (error) {
        logError('incentives/LinkedRules/actions', 'loadLinkedRules', error);
        resolve(dispatch(loadLinkedRulesFailed()));
      }
    });
  };
};
