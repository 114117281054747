import { ApiClient } from '../api';
import * as types from './actionTypes.js';
import { AppConfiguration } from '../configuration';
import { getSessionToken } from '../session';

export function loadTodosSuccess(todos) {
  return { type: types.LOAD_TODOS_SUCCESS, todos };
}

export function loadTodosFailed() {
  return { type: types.LOAD_TODOS_FAILED };
}

export function loadTodos(logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return dispatch => {
    return new Promise(async resolve => {
      try {
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get('todo');
        resolve(dispatch(loadTodosSuccess(apiResult.data.ToDoList)));
      } catch (error) {
        logError('todos/actions', 'loadTodos', error);
        resolve(dispatch(loadTodosFailed()));
      }
    });
  };
}
