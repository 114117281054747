import React from 'react';
import { MdStar } from 'react-icons/md';
import { FaTrophy } from 'react-icons/fa';
import './legend.scss';

export const Legend = () => {
  return (
    <div className="row cost-estimator-legend align-middle">
      <div className="col-md-6 card">
        <p>Use the search above to find high-quality low-cost doctors that provide monetary incentives</p>
        <h4>
          <MdStar className="star" /> Center of Excellence
        </h4>
        <p className="desc">
          The doctors, hospitals and medical groups meeting the highest quality standards are designated “Centers of
          Excellence.” Using a Center of Excellence, when available, may earn you a larger cash reward. They are marked
          with a gold star in the Providers of Distinction search.
        </p>
        <h4>
          <FaTrophy className="trophy" /> Providers of Distinction
        </h4>
        <p className="desc">
          The State of Connecticut has identified doctors, hospitals and medical care groups in the state that have
          verified high-quality outcomes for some of the most common medical conditions and procedures. Doctors and
          locations proven to meet these high standards are known as “Providers of Distinction.” These providers will
          appear first in the Providers of Distinction Search, clearly marked by a silver trophy. When you choose a
          Provider of Distinction for a listed procedure, you will automatically receive a cash incentive in the mail.
        </p>
      </div>
    </div>
  );
};
