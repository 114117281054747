import { logError } from '../../logging';
import * as types from './actionTypes';
import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';

const getSpinceIncentivesStart = () => {
  return {
    type: types.GET_SPINE_INCENTIVES_START
  };
};

const getSpineIncentivesSuccess = (spineIncentives) => {
  return {
    type: types.GET_SPINE_INCENTIVES_SUCCESS,
    spineIncentives
  };
};

const getSpineIncentivesFailed = () => {
  return {
    type: types.GET_SPINE_INCENTIVES_FAILED
  };
};

export const resetSpineIncentives = () => {
  return {
    type: types.RESET_SPINE_INCENTIVES
  };
};

export const getSpineIncentives = () => {
  return async (dispatch) => {
    try {
      const baseUrl = AppConfiguration.getApiRootUrl();
      const sessionToken = getSessionToken();
      const client = new ApiClient(baseUrl, sessionToken);
      const requestUrl = 'healthnavigator/spineprogram/incentives';
      dispatch(getSpinceIncentivesStart());
      const response = await client.get(requestUrl);
      if (response.data.sessionToken) {
        updateSessionToken(response.data.sessionToken);
      }
      const spineIncentiveResults = response.data.result;
      dispatch(getSpineIncentivesSuccess(spineIncentiveResults));
    } catch (error) {
      logError('healthnavigator/store/spineIncentiveActions', 'getSpineIncentives', error);
      dispatch(getSpineIncentivesFailed());
    }
  };
};
