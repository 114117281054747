import React from 'react';

export const NoAgentsAvailable = (props) => {
  return (
    <p className="wait-message">
      <span>Chat is currently unavailable.</span>
      <br />
      Please give us a call at {props.contactPhoneNumber}, or leave us a message by clicking the link below.
    </p>
  );
};
