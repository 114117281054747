import { ApiVersion } from './apiVersion';
import { MessageTypes } from './messageTypes';

export class UserIsTypingMessage {
  constructor(isTyping) {
    this.apiVersion = ApiVersion;
    this.type = MessageTypes.request;
    this.body = {
      method: 'isTyping',
      isTyping: isTyping
    };
  }
}
