import { ApiClient } from '../../api';
import { AppConfiguration } from '../../configuration';
import { getSessionToken, updateSessionToken } from '../../session';
import { loadServiceSettings } from '../../services';
import { ChatServiceSettings } from '../chatServiceSettings';
import * as types from './actionTypes.js';

export const loadChat = (logError) => {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return (dispatch) => {
    const topicsPromise = loadTopics(apiRootUrl, sessionToken);
    const queuesPromise = loadQueues(apiRootUrl, sessionToken);
    const settingsPromise = loadServiceSettings('RealTimeChat');
    return new Promise((resolve) => {
      Promise.all([settingsPromise, topicsPromise, queuesPromise])
        .then((results) => {
          const chatResult = getChatResult(results);
          resolve(dispatch(loadChatSuccess(chatResult)));
        })
        .catch((error) => {
          logError('chat/actions', 'loadChat', error);
          resolve(dispatch(loadChatFailed()));
        });
    });
  };
};

const getChatResult = (results) => {
  const serviceSettingsArray = results[0];
  const chatServiceSettings = new ChatServiceSettings(
    serviceSettingsArray.getSettingValue('AVA_CHTURL'),
    serviceSettingsArray.getSettingValue('AVA_RSTURL'),
    serviceSettingsArray.getSettingValue('AVA_CBURL'),
    serviceSettingsArray.getSettingValue('AVA_PINGMS'),
    serviceSettingsArray.getSettingValue('AVA_QUEMS')
  );
  return {
    serviceSettings: chatServiceSettings,
    topics: results[1],
    queues: results[2]
  };
};

const loadTopics = async (apiRootUrl, sessionToken) => {
  const apiClient = new ApiClient(apiRootUrl, sessionToken);
  const apiResult = await apiClient.get('membersite/chattopic');
  if (apiResult.data.SessionToken) {
    updateSessionToken(apiResult.data.SessionToken);
  }
  const results = apiResult.data.Result || [];
  let topics = results.map((result) => {
    return {
      id: result.ChatTopicId,
      queue: result.Queue,
      displayOrder: result.DisplayOrder,
      text: result.Text,
      queueType: result.QueueType
    };
  });
  return topics.sort((a, b) => parseInt(a.displayOrder, 10) - parseInt(b.displayOrder, 10));
};

const loadQueues = async (apiRootUrl, sessionToken) => {
  const apiClient = new ApiClient(apiRootUrl, sessionToken);
  const apiResult = await apiClient.get('chatqueue');
  if (apiResult.data.SessionToken) {
    updateSessionToken(apiResult.data.SessionToken);
  }
  const results = apiResult.data.Result || [];
  return results.map((result) => {
    return {
      queueType: result.QueueType,
      name: result.Name,
      description: result.Description
    };
  });
};

export const loadChatSuccess = (data) => {
  return {
    type: types.LOAD_CHAT_SUCCESS,
    serviceSettings: data.serviceSettings,
    topics: data.topics,
    queues: data.queues
  };
};

export const loadChatFailed = () => {
  return { type: types.LOAD_CHAT_FAILED };
};

export const resetChatSession = () => {
  return {
    type: types.RESET_CHAT_SESSION
  };
};
