import { AppConfiguration } from '../configuration';
import { getSessionToken, updateSessionToken } from '../session';
import * as types from './actionTypes.js';
import { ApiClient } from '../api';

export function loadClient(logError) {
  const apiRootUrl = AppConfiguration.getApiRootUrl();
  const sessionToken = getSessionToken();
  return dispatch => {
    return new Promise(async resolve => {
      try {
        const apiClient = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await apiClient.get('client');
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        resolve(dispatch(loadClientSuccess(apiResult.data.result)));
      } catch (error) {
        logError('client/actions', 'loadClient', error);
        resolve(dispatch(loadClientFailed()));
      }
    });
  };
}

export const loadClientSuccess = client => {
  return {
    type: types.LOAD_CLIENT_SUCCESS,
    client: client
  };
};

export const loadClientFailed = () => {
  return { type: types.LOAD_CLIENT_FAILED };
};
