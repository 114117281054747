import React from 'react';
import { AppConfiguration } from '../configuration';
//import { RxModalPrint } from './rxModalPrint';
import { RxModal } from './rxModal';
import './prescriptionCard.scss';

export const PrescriptionDiscountCard = (props) => {
  const nativeApp = AppConfiguration.isNativeApp();
  // const url = AppConfiguration.getSiteRootUrl();
  const url = AppConfiguration.getBiometricsRootUrl();
  console.log(url);
  var newUrl = url + 'Secure/Forms/PrescriptionDiscountCard.aspx';
  if (nativeApp === true) {
    var newUrl = url + 'Secure/Forms/PrescriptionDiscountCard.aspx?doctype=pdf';
  } else {
    var newUrl = url + 'Secure/Forms/PrescriptionDiscountCard.aspx';
  }
  return (
    <div className="rxDiscountPage">
      <h1>Save money on your prescriptions, compliments of Health Advocate!</h1>

      <p>
        We’re pleased to offer you Rx Shopper to help you find the lowest prices for your prescriptions. &nbsp;
        <strong>
          Download and print your card, then bring it with you when filling your prescription to save up to 80%.
        </strong>
        <br />
        {/* <span className="rxEmphasis">This card is accepted at over 35,000 pharmacies nationwide!</span> */}
      </p>

      <div className="infoSection sectionOne">
        <div className="infoSecIcon"></div>
        <h3>Check with your pharmacy</h3>
        <p>Your Rx Shopper card is accepted at over 35,000 pharmacies nationwide!</p>
      </div>
      <div className="infoSection sectionTwo">
        <div className="infoSecIcon"></div>
        <h3>You can still use your insurance</h3>
        <p>But ask the pharmacist to run your Rx Shopper card to find the lowest price</p>
      </div>
      <div className="infoSection sectionThree">
        <div className="infoSecIcon"></div>
        <h3>It’s a win-win situation</h3>
        <p>You’ll always pay the lower price—the pharmacy’s, your insurance’s or Rx Shopper’s!</p>
      </div>
      <a className="btn btn-primary" href={newUrl} target="_blank">
        Download my card!
      </a>
      <p className="disclaimerTextRx">*Prescription savings vary by prescription and by pharmacy, and may reach up to 80% off of the cash price.</p>
    </div>
  );
};
