import React from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import { ValidatedSearchForm } from './search';
import './hero.scss';

export const Hero = () => {
  const dispatch = useDispatch();
  return (
    <div className="cost-estimator-hero">
      <Container>
        <h3>
          Search here for procedures you need or the condition you have to find providers or facilities in your desired
          zip code. Contact a Health Navigator with any question about the Providers of Distinction program, or for help
          finding a provider.
        </h3>
        <h3 className="note">
          Procedures, providers and facilities will be added to this list as they are approved. To view the current list
          of eligible procedures and incentives,{' '}
          <a href="https://carecompass.ct.gov/networkofdistinction/#incentives" target="_blank">
            click here
          </a>
        </h3>
        <ValidatedSearchForm dispatch={dispatch} />
      </Container>
    </div>
  );
};
