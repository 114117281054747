import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';
import * as types from './actionTypes';

const loadButtonLinksSuccess = (ipoid, buttonLinks) => {
  return {
    type: types.LOAD_BUTTONLINKS_SUCCESS,
    data: { ipoid, buttonLinks }
  };
};

const loadButtonLinksFailed = () => {
  return {
    type: types.LOAD_BUTTONLINKS_FAILED
  };
};

export const loadButtonLinks = (ipoid, logError) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);
        const apiResult = await client.get(
          `member/incentiveprogram/incentiveprogramorganization/${ipoid}/buttonlinkdetail`
        );
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }
        resolve(dispatch(loadButtonLinksSuccess(ipoid, apiResult.data.result)));
      } catch (error) {
        logError('incentives/buttonlinks/actions', 'loadButtonLinks', error);
        resolve(dispatch(loadButtonLinksFailed()));
      }
    });
  };
};
