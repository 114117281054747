import * as types from './actionTypes';

const initialState = {
  trackingParams: null,
  loading: true,
  error: false
};

export const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ANALYTICS_SUCCESS:
      return {
        ...state,
        trackingParams: action.trackingParams,
        loading: false,
        error: false
      };
    case types.LOAD_ANALYTICS_FAILED:
      return {
        ...state,
        trackingParams: null,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
