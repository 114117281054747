import { AppConfiguration } from '../../configuration';
import { ApiClient } from '../../api';
import { updateSessionToken, getSessionToken } from '../../session';
import * as types from './actionTypes';

const loadMemberScoreDetailsSuccess = (ipoid, ruleId, memberScoreDetails) => {
  return {
    type: types.LOAD_MEMBERSCOREDETAILS_SUCCESS,
    data: { ipoid, ruleId, memberScoreDetails }
  };
};

const loadMemberScoreDetailsFailed = () => {
  return {
    type: types.LOAD_MEMBERSCOREDETAILS_FAILED
  };
};

export const loadMemberScoreDetails = (ipoid, ruleId, logError) => {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const apiRootUrl = AppConfiguration.getApiRootUrl();
        const sessionToken = getSessionToken();
        const client = new ApiClient(apiRootUrl, sessionToken);

        const apiResult = await client.get(
          `member/incentiveprogram/incentiveprogramorganization/${ipoid}/rule/${ruleId}/score`
        );
        if (apiResult.data.sessionToken) {
          updateSessionToken(apiResult.data.sessionToken);
        }

        resolve(dispatch(loadMemberScoreDetailsSuccess(ipoid, ruleId, apiResult.data.result)));
      } catch (error) {
        logError('incentives/memberScoreDetails/actions', 'loadMemberScoreDetails', error);
        resolve(dispatch(loadMemberScoreDetailsFailed()));
      }
    });
  };
};
