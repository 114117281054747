import React, { useEffect } from 'react';
import { Hero } from './search';
import { Results } from './results';
import { ErrorBoundry } from '../shared';
import { keepAlive } from '../session';

const keepAliveIntervalMinutes = 1000 * 60 * 5;

export const HealthNavigatorView = () => {
  useEffect(() => {
    setInterval(() => {
      keepAlive();
    }, keepAliveIntervalMinutes);
  }, []);

  return (
    <div style={{ marginTop: '-20px' }}>
      <Hero />
      <ErrorBoundry>
        <Results />
      </ErrorBoundry>
    </div>
  );
};
