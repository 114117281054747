import React from 'react';
import { CardGroup, Card, Dropdown } from 'react-bootstrap';
import { ErrorBoundry } from '../../shared';
import moment from 'moment';
import { AppConfiguration } from '../../configuration';

export const Header = (props) => {
  // const debugUrl = `http://localhost:3000/mypoints`;
  const mypointsUrl = `${AppConfiguration.getSiteRootUrl()}Wellbeing/MyPointsProgram`;

  let otherPrograms = props.visiblePrograms.filter((item) => {
    return item.incentiveProgramId !== props.activeProgram.incentiveProgramId;
  });

  //Create an array consisting of element for each level for the graphic
  let levelArray = [...Array(props.headerDetails.numberOfLevels).keys()];

  const wellnessLogoNew = props.activeProgram.imageURL ? (
    <img src={props.activeProgram.imageURL} alt="logo" className="wellnessLogo" />
  ) : null;

  function LevelInfo() {
    const tierGraphic = (
      <div
        className="levelIndicator row"
        className={
          props.headerDetails.numberOfLevels > 1
            ? 'levelIndicator ' + 'row'
            : 'levelIndicator ' + 'row ' + 'singleLevelProgram'
        }>
        {levelArray.map((eachLevel, index) => (
          <div key={eachLevel} className="col">
            <span className={index === props.headerDetails.currentLevel - 1 ? 'levelIndic ' + 'active' : 'levelIndic'}>
              {index + 1}
            </span>
          </div>
        ))}
      </div>
    );
    const programSumm = props.activeProgram.incentiveSummaryOverride;

    const progSumScript = programSumm.replace(/&nbsp;/g, ' ').replace(/(<([^>]+)>)/gi, '');
    var trimmedprogSumScript = progSumScript.substring(0, 100) + '...';
    return (
      <>
        <Card className="levellCard">
          <Card.Body>
            {/* Check if all levels are complete */}
            {props.headerDetails.allLevelsComplete ? (
              <Card.Title>{props.headerDetails.highestTierAchievedMessage}</Card.Title>
            ) : null}
            {/* Single Level (non-tiered) Program */}
            {props.headerDetails.numberOfLevels === 1 && !props.headerDetails.allLevelsComplete ? (
              <>
                <Card.Text className="singleTierProgram">
                  <span className="leadText">{`Earn ${props.headerDetails.pointsToNextLevel} points to complete the program.`}</span>
                  <br />
                  {props.headerDetails.totalAchievedTiers === 0 && props.headerDetails.currentLevel === 1 ? (
                    <span>{props.headerDetails.currentTierMessage} </span>
                  ) : (
                    <span>{props.headerDetails.highestTierAchievedMessage}</span>
                  )}
                </Card.Text>
              </>
            ) : null}
            {/* // Otherwise, we know it's a multi level program */}
            {props.headerDetails.numberOfLevels > 1 && !props.headerDetails.allLevelsComplete ? (
              <>
                <Card.Title>You are currently on</Card.Title>
                <Card.Text>
                  <span className="topCardHeadline">{props.headerDetails.tierName}</span>
                  {`Earn ${props.headerDetails.pointsToNextLevel} points to achieve this level.`} <br />
                  {props.headerDetails.totalAchievedTiers === 0 && props.headerDetails.currentLevel === 1 ? (
                    <span>{props.headerDetails.currentTierMessage} </span>
                  ) : (
                    <span>{props.headerDetails.highestTierAchievedMessage}</span>
                  )}
                </Card.Text>
                <div className="row">
                  <div className="col">{tierGraphic}</div>
                </div>
              </>
            ) : null}
          </Card.Body>
        </Card>
        <Card className="programInfoCard">
          <Card.Body>
            <fieldset className="border p-2">
              <legend className="w-auto">Program Dates:</legend>
              <span className="topCardHeadline">
                {moment(props.activeProgram.startDate).format('MM/DD/YYYY') +
                  ' - ' +
                  moment(props.activeProgram.endDate).format('MM/DD/YYYY')}
              </span>
            </fieldset>
            {/* <Card.Text id="ProgSummArea">{trimmedprogSumScript}</Card.Text> */}
            {otherPrograms.length > 0 ? (
              <Dropdown className="prevYrButton">
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  View Other Programs
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {otherPrograms.map((item) => (
                    <Dropdown.Item
                      key={item.incentiveProgramOrganizationId}
                      href={`${mypointsUrl}?ipoid=${item.incentiveProgramOrganizationId}`}>
                      View {item.incentiveProgramDisplayName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ''
            )}
          </Card.Body>
        </Card>
      </>
    );
  }

  return (
    <ErrorBoundry>
      <div className="headerContainer">
        <h1>
          {wellnessLogoNew}
          {props.activeProgram.incentiveProgramDisplayName}
        </h1>
        <div className="cardGroupContainer">
          <div className="circleBack"></div>
          {/* Points circle */}
          <div className="linked card shadow incentiveWidgCard">
            <div className="card-divider wellbeing incentiveWidg">
              <h3 className="my-points-title">My Points</h3>
            </div>
            <div className="summary-panel">
              <div className="points-meter">
                <figure>
                  <div className="figure-content">
                    <svg className="progress" width="225px" height="225px" viewBox="0 0 120 120">
                      <circle
                        className="guideCircle"
                        cx="60"
                        cy="60"
                        r="54"
                        fill="none"
                        stroke="#e6e6e6"
                        strokeWidth="8"></circle>
                      <circle
                        className="progressCircle"
                        cx="60"
                        cy="60"
                        r="54"
                        fill="none"
                        stroke="#009ee1"
                        strokeWidth="12"
                        strokeDasharray="339.29200658769764"
                        strokeDashoffset="169.64600329384882"></circle>
                      <circle
                        className="tierIndicator"
                        cx="60"
                        cy="60"
                        r="54"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="12"
                        strokeDasharray="1 338.29200658769764"></circle>
                    </svg>
                  </div>
                  <div className="ring-text">
                    <div className="points-box">
                      <div className="ring-number">{props.headerDetails.pointsEarned}</div>
                      <div className="ring-label">POINTS EARNED</div>
                    </div>
                  </div>
                </figure>
              </div>
            </div>
          </div>
          {/* End Points Circle */}
          <CardGroup>
            <LevelInfo />
          </CardGroup>
        </div>
      </div>
    </ErrorBoundry>
  );
};
