import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HealthNavigatorView } from './healthNavigatorView';
import { loadEpisodes, loadNetworks } from './store';
import { ErrorBoundry } from '../shared';

export const loadHealthNavigator = (store, logError) => {
  const healthNavigatorComponent = document.getElementById('healthNavigatorComponent');
  if (healthNavigatorComponent) {
    store.dispatch(loadEpisodes(logError));
    store.dispatch(loadNetworks(logError));
    ReactDOM.render(
      <Provider store={store}>
        <ErrorBoundry>
          <HealthNavigatorView />
        </ErrorBoundry>
      </Provider>,
      healthNavigatorComponent
    );
  }
};
