export const REQUEST_PROVIDER_SEARCH = 'REQUEST_PROVIDER_SEARCH';
export const PROVIDER_SEARCH_SUCCESS = 'PROVIDER_SEARCH_SUCCESS';
export const PROVIDER_SEARCH_FAILED = 'PROVIDER_SEARCH_FAILED';
export const REQUEST_LOAD_EPISODES = 'REQUEST_LOAD_EPISODES';
export const LOAD_EPISODES_SUCCESS = 'LOAD_EPISODES_SUCCESS';
export const LOAD_EPISODES_FAILED = 'LOAD_EPISODES_FAILED';
export const REQUEST_PROVIDER_LOOKUP = 'REQUEST_PROVIDER_LOOKUP';
export const REQUEST_PROVIDER_LOOKUP_SUCCESS = 'REQUEST_PROVIDER_LOOKUP_SUCCESS';
export const REQUEST_PROVIDER_LOOKUP_FAILED = 'REQUEST_PROVIDER_LOOKUP_FAILED';
export const PROVIDER_RESET_SUCCESS = 'PROVIDER_RESET_SUCCESS';
export const LOAD_NETWORKS_SUCCESS = 'LOAD_NETWORKS_SUCCESS';
export const LOAD_NETWORKS_FAILED = 'LOAD_NETWORKS_FAILED';
export const PROVIDER_NETWORK_SUCCESS = 'PROVIDER_NETWORK_SUCCESS';
export const GET_SPINE_PROVIDERS_START = 'GET_SPINE_PROVIDERS_START';
export const GET_SPINE_PROVIDERS_SUCCESS = 'GET_SPINE_PROVIDERS_SUCCESS';
export const GET_SPINE_PROVIDERS_FAILED = 'GET_SPINE_PROVIDERS_FAILED';
export const GET_SPINE_INCENTIVES_START = 'GET_SPINE_INCENTIVES_START';
export const GET_SPINE_INCENTIVES_SUCCESS = 'GET_SPINE_INCENTIVES_SUCCESS';
export const GET_SPINE_INCENTIVES_FAILED = 'GET_SPINE_INCENTIVES_FAILED';
export const RESET_SPINE_INCENTIVES = 'RESET_SPINCE_INCENTIVES';
export const RESET_SPINE_PROVIDERS = 'RESET_SPINE_PROVIDERS';
