import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';

export const ProgramInfoCardTest = (props) => {
  return (
    <Card className="programInfoCard">
      <Card.Body>
        <fieldset className="border p-2">
          <legend className="w-auto">Program Dates:</legend>
          <span className="topCardHeadline">{props.programDates}</span>
        </fieldset>

        <Card.Text>
          Health Advocate medical-enrolled employees can earn the 2021 Wellness Premium Discount by completing
          well-being activities for points.
          {/* <button onClick={() => props.setScenario('ScenarioThree')}>
            TEST
          </button> */}
        </Card.Text>
        <Dropdown className="prevYrButton">
          <Dropdown.Toggle variant="link" id="dropdown-basic" defaultActiveKey="earnpoints">
            View Other Programs
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => props.setScenario(props.whichScenario)}>
              {props.programDropdownText}
            </Dropdown.Item>
            {/* <Dropdown.Item
                  href="#/action-2"
                  onClick={() => props.setScenario('ScenarioThree')}>
                  Wellness Matters 2019
                </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </Card.Body>
    </Card>
  );
};
